import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import "./styles/App.sass";
import useToken from "./hooks/useToken";
import SideNav from "./components/SideNav";
import { Col, Row } from "react-bootstrap";
import ElderRouter from "./router/ElderRouter";
import { registerLocale } from "react-datepicker";
import { zhTW } from "date-fns/locale";
import TrackListPage from "./pages/TrackListPage";
import StatisticPage from "./pages/StatisticPage";
import ManagerRouter from "./router/ManagerRouter";
import ReportRouter from "./router/ReportRouter";
import EducationRouter from "./router/EducationRouter";

const App = () => {
    const token = useToken();
    registerLocale("zh-TW", zhTW);

    if (!token.get()) return <LoginPage />;

    return (
        <div className="App">
            <BrowserRouter>
                <Row noGutters>
                    <Col lg={ 3 } xl={ 2 } xs={ false }>
                        <Route component={ SideNav } />
                    </Col>
                    <Col lg={ 9 } xl={ 10 } xs={ 12 }>
                        <Switch>
                            <Route component={ ElderRouter } path="/elder" />
                            <Route component={ ManagerRouter } path="/manager" />
                            <Route component={ StatisticPage } path="/statistic" />
                            <Route component={ TrackListPage } path="/track" />
                            <Route component={ ReportRouter } path="/report" />
                            <Route component={ EducationRouter } path="/education" />
                            <Redirect to="/track" />
                        </Switch>
                    </Col>
                </Row>
            </BrowserRouter>
        </div>
    );
};


export default App;
