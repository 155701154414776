import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import useAPI from "../hooks/useAPI";
import AddressOption from "../models/AddressOption";
import { Manager } from "../models/Responses";
import DatePicker from "react-datepicker";

interface ManagerFormProps {
    formData: Manager,
    setFormData: React.Dispatch<React.SetStateAction<Manager>>,
    isLoading?: boolean
}

const ManagerForm = (props: ManagerFormProps) => {
    const API = useAPI();

    const { formData, setFormData } = props;

    const isCreating = window.location.pathname.includes("create");

    const [isUploadingImage, setUploadingImage] = useState(false);

    const [hCountryOptions, setHCountryOptions] = useState<{ code: string, name: string }[]>([]);
    const [hCityOptions, setHCityOptions] = useState<{ code: string, name: string }[]>([]);
    const [hVilOptions, setHVilOptions] = useState<{ code: string, name: string }[]>([]);
    const [isHAddrChanged, setHAddrChanged] = useState(false);

    const [lCountryOptions, setLCountryOptions] = useState<{ code: string, name: string }[]>([]);
    const [lCityOptions, setLCityOptions] = useState<{ code: string, name: string }[]>([]);
    const [lVilOptions, setLVilOptions] = useState<{ code: string, name: string }[]>([]);
    const [isLAddrChanged, setLAddrChanged] = useState(false);

    async function refreshHCountryOption(currCountryCode: string) {
        const countries = (await API.getCityList()).datas;
        setHCountryOptions(countries);
        const newCountryCode = countries.map((c: AddressOption) => c.code).includes(currCountryCode) ? currCountryCode : countries[0].code;
        if (isCreating || formData.h_id.length !== 0) setFormData({ ...formData, h_h_county_code: newCountryCode });
        return newCountryCode;
    }

    async function refreshHCityOption(countryCode: string, currCityCode: string) {
        const cities = (await API.getDistrictList(countryCode)).datas;
        setHCityOptions(cities);
        const newCityCode = cities.map((c: AddressOption) => c.code).includes(currCityCode) ? currCityCode : cities[0].code;
        if (isCreating || formData.h_id.length !== 0) setFormData({ ...formData, h_h_city_code: newCityCode });
        return newCityCode;
    }

    async function refreshHVilOption(cityCode: string, currVilCode: string) {
        const vils = (await API.getVilList(cityCode)).datas;
        setHVilOptions(vils);
        const newVilCode = vils.map((c: AddressOption) => c.code).includes(currVilCode) ? currVilCode : vils[0].code;
        if (isCreating || formData.h_id.length !== 0) setFormData({ ...formData, h_h_vil_code: newVilCode });
        return newVilCode;
    }

    async function refreshHAddressOptions() {
        const newCountryCode = await refreshHCountryOption(formData.h_h_county_code);
        const newCityCode = await refreshHCityOption(newCountryCode, formData.h_h_city_code);
        await refreshHVilOption(newCityCode, formData.h_h_vil_code);
        setHAddrChanged(false);
    }

    async function refreshLCountryOption(currCountryCode: string) {
        const countries = (await API.getCityList()).datas;
        setLCountryOptions(countries);
        const newCountryCode = countries.map((c: AddressOption) => c.code).includes(currCountryCode) ? currCountryCode : countries[0].code;
        if (isCreating || formData.h_id.length !== 0) setFormData({ ...formData, h_l_county_code: newCountryCode });
        return newCountryCode;
    }

    async function refreshLCityOption(countryCode: string, currCityCode: string) {
        const cities = (await API.getDistrictList(countryCode)).datas;
        setLCityOptions(cities);
        const newCityCode = cities.map((c: AddressOption) => c.code).includes(currCityCode) ? currCityCode : cities[0].code;
        if (isCreating || formData.h_id.length !== 0) setFormData({ ...formData, h_l_city_code: newCityCode });
        return newCityCode;
    }

    async function refreshLVilOption(cityCode: string, currVilCode: string) {
        const vils = (await API.getVilList(cityCode)).datas;
        setLVilOptions(vils);
        const newVilCode = vils.map((c: AddressOption) => c.code).includes(currVilCode) ? currVilCode : vils[0].code;
        if (isCreating || formData.h_id.length !== 0) setFormData({ ...formData, h_l_vil_code: newVilCode });
        return newVilCode;
    }

    async function refreshLAddressOptions() {
        const newCountryCode = await refreshLCountryOption(formData.h_l_county_code);
        const newCityCode = await refreshLCityOption(newCountryCode, formData.h_l_city_code);
        await refreshLVilOption(newCityCode, formData.h_l_vil_code);
        setLAddrChanged(false);
    }

    useEffect(() => {
        if (isHAddrChanged) refreshHAddressOptions();
    }, [isHAddrChanged]);

    useEffect(() => {
        if (isLAddrChanged) refreshLAddressOptions();
    }, [isLAddrChanged]);

    useEffect(() => {
        if (!props.isLoading && formData.h_id !== undefined) {
            refreshHAddressOptions();
            refreshLAddressOptions();
        }
    }, [props.isLoading]);

    async function uploadImage(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];
        if (formData !== undefined && file !== null && file !== undefined) {
            const res = await API.uploadImage(file);
            setFormData({ ...formData, h_img: res });
            setUploadingImage(false);
        }
    }

    if (props.isLoading) return <p>讀取資料中 ...</p>;

    return <div>
        <div className="sc-edit-normal">
            <div className="r1">
                <p className="sc1qr-label">登入帳號</p>
                <input
                    disabled={ !window.location.pathname.includes("create") }
                    onChange={ (e) => setFormData({ ...formData, h_id: e.target.value }) }
                    value={ formData.h_id }
                />

                <p className="sc1qr-label">狀態</p>
                <input
                    checked={ formData.h_is_enable }
                    onChange={ () => setFormData({ ...formData, h_is_enable: true }) }
                    type="checkbox"
                />
                <p className="sc1qr-label">開啟</p>
                <input
                    checked={ !formData.h_is_enable }
                    onChange={ () => setFormData({ ...formData, h_is_enable: false }) }
                    type="checkbox"
                />
                <p>關閉</p>
            </div>

            <div className="r1">
                <p className="sc1qr-label">登入密碼</p>
                <input
                    onChange={ (e) => setFormData({ ...formData, h_pwd: e.target.value }) }
                    type="password"
                    value={ formData.h_pwd }
                />
                <p className="sc1qr-label">到職日</p>
                <DatePicker
                    dateFormat="yyyy/MM/dd"
                    locale="zh-TW"
                    onChange={ (date: Date) => {
                        setFormData({ ...formData, h_work_date: date });
                    } }
                    selected={ formData.h_work_date }
                />
                <p className="sc1qr-label">身分類型</p>
                <div style={ { display: "flex" } }>
                    <input checked={ formData.h_type ===0 } onClick={ () => setFormData({ ...formData, h_type: 0 }) } type="radio" />
                    <p className="sc1qr-ct">健康經理人</p>
                    <input checked={ formData.h_type ===1 } onClick={ () => setFormData({ ...formData, h_type: 1 }) } type="radio" />
                    <p className="sc1qr-ct">健康管理員</p>
                </div>
            </div>
        </div>

        <Row className="info-card sc-edit-profile">
            <Col className="sc-edit-left" xs={ 2 }>

                <img alt="" src={ formData.h_img ? formData.h_img : "/assets/man.svg" } />
                <button
                    className="ghost-button"
                    onClick={ () => setFormData({ ...formData, h_img: null }) }
                    style={ { margin: 8, padding: "4px 8px" } }
                >清除
                </button>
                <button
                    className="ghost-button"
                    onClick={ () => setUploadingImage(true) }
                    style={ { margin: 8, padding: "4px 8px" } }
                >上傳
                </button>
                {isUploadingImage ? <input
                    onChange={ uploadImage }
                    type="file"
                /> : <div />}
            </Col>
            <Col className="elder-info sc-edit-right" xs={ 9 }>
                <Row className="info-row">
                    <Col xs={ 2 }><p className="sc1qr-label">姓名</p></Col>
                    <Col xs={ 4 }>
                        <input
                            onChange={ (e) => setFormData({ ...formData, h_name: e.target.value }) }
                            value={ formData.h_name }
                        /></Col>
                </Row>

                <Row className="info-row">
                    <Col xs={ 2 }><p className="sc1qr-label">聯絡電話</p></Col>
                    <Col xs={ 4 }><input
                        onChange={ (e) => setFormData({ ...formData, h_tel: e.target.value }) }
                        value={ formData.h_tel }
                    /></Col>
                </Row>

                <Row className="info-row">
                    <Col xs={ 2 }><p className="sc1qr-label">性別</p></Col>
                    <Col xs={ 4 }>
                        <select
                            onChange={ (e) => setFormData({ ...formData, h_sex: +e.target.value }) }
                            value={ formData.h_sex }
                        >
                            <option value="0">女</option>
                            <option value="1">男</option>
                        </select></Col>
                </Row>

                <Row className="info-row">
                    <Col xs={ 2 }><p className="sc1qr-label">手機號碼</p></Col>
                    <Col xs={ 4 }><input
                        onChange={ (e) => setFormData({ ...formData, h_mobile: e.target.value }) }
                        value={ formData.h_mobile }
                    /></Col>
                </Row>

                <Row className="info-row">
                    <Col xs={ 2 }><p className="sc1qr-label">戶籍地址</p></Col>
                    <Col xs={ 3 }>
                        <select
                            className="sc1qr-input-s"
                            onChange={ (e) => {
                                setHAddrChanged(true);
                                setFormData({ ...formData, h_h_county_code: e.target.value });
                            } }
                            value={ formData.h_h_county_code }
                        >
                            {hCountryOptions.map((co) => <option key={ co.code } value={ co.code }>{co.name}</option>)}
                        </select>
                    </Col>
                    <Col xs={ 3 }>
                        <select
                            className="sc1qr-input-s"
                            onChange={ (e) => {
                                setHAddrChanged(true);
                                setFormData({ ...formData, h_h_city_code: e.target.value });
                            } }
                            value={ formData.h_h_city_code }
                        >
                            {hCityOptions.map((co) => <option key={ co.code } value={ co.code }>{co.name}</option>)}
                        </select>
                    </Col>
                    <Col xs={ 3 }>
                        <select
                            className="sc1qr-input-s"
                            onChange={ (e) => {
                                setHAddrChanged(true);
                                setFormData({ ...formData, h_h_vil_code: e.target.value });
                            } }
                            value={ formData.h_h_vil_code }
                        >
                            {hVilOptions.map((co) => <option key={ co.code } value={ co.code }>{co.name}</option>)}
                        </select>
                    </Col>
                </Row>
                <Row className="info-row">
                    <Col xs={ 2 } />
                    <Col xs={ 10 }>
                        <input
                            className="sc1qr-input-l"
                            onChange={ (e) => setFormData({ ...formData, h_h_address: e.target.value }) }
                            value={ formData.h_h_address }
                        />
                    </Col>
                </Row>

                <Row className="info-row">
                    <Col xs={ 2 }><p className="sc1qr-label">居住地址</p></Col>
                    <Col xs={ 3 }>
                        <select
                            className="sc1qr-input-s"
                            onChange={ (e) => {
                                setLAddrChanged(true);
                                setFormData({ ...formData, h_l_county_code: e.target.value });
                            } }
                            value={ formData.h_l_county_code }
                        >
                            {lCountryOptions.map((co) => <option key={ co.code } value={ co.code }>{co.name}</option>)}
                        </select>
                    </Col>
                    <Col xs={ 3 }>
                        <select
                            className="sc1qr-input-s"
                            onChange={ (e) => {
                                setLAddrChanged(true);
                                setFormData({ ...formData, h_l_city_code: e.target.value });
                            } }
                            value={ formData.h_l_city_code }
                        >
                            {lCityOptions.map((co) => <option key={ co.code } value={ co.code }>{co.name}</option>)}
                        </select>
                    </Col>
                    <Col xs={ 3 }>
                        <select
                            className="sc1qr-input-s"
                            onChange={ (e) => {
                                setLAddrChanged(true);
                                setFormData({ ...formData, h_l_vil_code: e.target.value });
                            } }
                            value={ formData.h_l_vil_code }
                        >
                            {lVilOptions.map((co) => <option key={ co.code } value={ co.code }>{co.name}</option>)}
                        </select>
                    </Col>
                </Row>
                <Row className="info-row">
                    <Col xs={ 2 } />
                    <Col xs={ 10 }>
                        <input
                            className="sc1qr-input-l"
                            onChange={ (e) => setFormData({ ...formData, h_l_address: e.target.value }) }
                            value={ formData.h_l_address }
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>;
};

export default ManagerForm;
