/* eslint-disable max-len */
import React from "react";

const AngryIcon = (props: { color?: string }) => {
    let styleColor: any = props.color;

    switch (styleColor) {
    case "紅燈":
        styleColor = "#FF0000";
        break;

    case "黃燈":
        styleColor = "#FBB03B";
        break;

    case "綠燈":
        styleColor = "#39B54A";
        break;

    case "red":
        styleColor = "#FF0000";
        break;

    case "yellow":
        styleColor = "#FBB03B";
        break;

    case "green":
        styleColor = "#39B54A";
        break;

    default:
        styleColor = "#888888";
        break;
    }

    return <svg className="icon" fill={ styleColor } xmlns="http://www.w3.org/2000/svg">
        <g>
            <path
                d="M40.93,57.06H16.49V56.6c0-1.46,0-2.92,0-4.38a.48.48,0,0,0-.36-.53A28.38,28.38,0,0,1,7,46.32,19.75,19.75,0,0,1,.47,35.8a12.86,12.86,0,0,1-.38-4.74,4.4,4.4,0,0,1,4.57-3.68,4.31,4.31,0,0,1,4.18,4c.08,4.35,2.39,7.3,5.76,9.61a23.31,23.31,0,0,0,10.47,3.8,27.18,27.18,0,0,0,14-1.77,17.39,17.39,0,0,0,7.39-5.39,9.72,9.72,0,0,0,2.09-5.86,4.41,4.41,0,0,1,4.12-4.34,4.36,4.36,0,0,1,4.67,3.77,13.77,13.77,0,0,1-.45,5,20.11,20.11,0,0,1-6.55,10.28,28.47,28.47,0,0,1-8.94,5.25.62.62,0,0,0-.49.68c0,1.41,0,2.82,0,4.23Z"
            />
            <path className="cls-1" d="M38.63,31.87a10,10,0,1,1-9.91-10.06A10,10,0,0,1,38.63,31.87Z" />
            <path className="cls-1" d="M28.33,17.87l.24-7.54L25.18,11.5,28.76,0h.09l-.24,7.55L32,6.39,28.43,17.87Z" />
            <path className="cls-1" d="M17.33,10.61l2-3,3.92,11.43-.08,0-4.28-6.27-2,3L13,4.42l.09,0Z" />
            <path className="cls-1" d="M44.29,4.59l-4.45,6.13,3.46.95-9.37,7.46-.08-.07L38.26,13l-3.45-.94,9.41-7.51Z" />
        </g>
    </svg>;
};

export default AngryIcon;
