import React, { useState } from "react";
import { useHistory } from "react-router";
import "../../styles/ElderEditPage.sass";
import useAPI from "../../hooks/useAPI";
import "../../styles/AddContactModal.sass";
import TitleBar from "../../components/TitleBar";
import ManagerForm from "../../components/ManagerForm";
import { emptyManagerData, Manager } from "../../models/Responses";

const ManagerCreatePage = () => {
    const API = useAPI();
    const history = useHistory();

    const [formData, setFormData] = useState<Manager>(emptyManagerData);

    async function uploadData() {
        try {
            await API.createManager(formData);
            alert("新增成功！");
            history.push("/manager");
        } catch (err) {
            alert(err);
        }
    }

    if (formData === undefined) return <div className="elder-edit"><h1>新增與編輯</h1><p>載入資料中 ...</p></div>;

    return <div className="manager-create elder-edit s-content">
        <TitleBar title="新增人員" />

        <ManagerForm formData={ formData } setFormData={ setFormData } />

        <div style={ { textAlign: "center" } }>
            <button className="ghost-button" onClick={ () => history.push("/manager") }>取消</button>
            <button className="ghost-button" onClick={ uploadData }>儲存</button>
        </div>

    </div>;
};

export default ManagerCreatePage;
