import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import TitleBar from "../../components/TitleBar";
import { defaultHomeFormData, HomeFormData } from "../../models/form/HomeForm";
import useAPI from "../../hooks/useAPI";
import useErrorHandler from "../../hooks/useErrorHandler";
import SignatureCanvas from "react-signature-canvas";

/** 家訪問卷頁面 */
export default function HomeFormPage() {
    const API = useAPI();
    const errorHandler = useErrorHandler();
    const { id } = useParams<{ id: string }>();
    const isCreating = id === "create";
    const elderId = window.location.search.split("elderId=")[1];

    const signCanvasRef = useRef(null);

    const [formData, setFormData] = useState<HomeFormData>(defaultHomeFormData);
    const [signCanvasWidth, setSignCanvasWidth] = useState(560);

    async function submit() {
        try {
            if (isCreating) {
                // @ts-ignore
                await API.createHomeReport({ ...formData,sign: signCanvasRef.current.toDataURL().replace("data:image/png;base64,", "") } , elderId);
                goBack();
            } else {
                // @ts-ignore
                await API.updateHomeReport({ ...formData,sign: signCanvasRef.current.toDataURL().replace("data:image/png;base64,", "") }, id);
                goBack();
            }
        } catch (err) {
            errorHandler.handle(err);
        }
    }

    function goBack() {
        window.location.href = "/elder/" + elderId;
    }

    useEffect(() => {
        try {
            API.getHomeVisitData(id).then((res) => {
                setFormData(res);
                // @ts-ignore
                signCanvasRef.current.fromDataURL("data:image/png;base64," + res.sign) ;
            })
            if(!isCreating) {
                // @ts-ignore
                signCanvasRef.current.off()
            }
        } catch (err) {
            errorHandler.handle(err);
        }
    }, [id]);

    return <div className="elder-detail elder-report-ct report-home">
        <TitleBar title={ isCreating ? "新增家訪紀錄" : "家訪紀錄" } isForm />
        <div>
            <table>
                <tr>
                    <td className='header'>家訪紀錄</td>
                </tr>
                <tr>
                    <td>
                        <p className="sc1qr-label">主聯/電話</p>
                        <input onChange={ (e) => setFormData({ ...formData, q0_1: e.target.value }) } value={ formData.q0_1 } />
                        <p className="sc1qr-label">主聯/電話</p>
                        <input onChange={ (e) => setFormData({ ...formData, q0_2: e.target.value }) } value={ formData.q0_2 } />
                        <p className="sc1qr-label">主付/電話</p>
                        <input onChange={ (e) => setFormData({ ...formData, q0_3: e.target.value }) } value={ formData.q0_3 } />
                        <p className="sc1qr-label">主照/電話</p>
                        <input onChange={ (e) => setFormData({ ...formData, q0_4: e.target.value }) } value={ formData.q0_4 } />
                    </td>
                </tr>
                <tr>
                    <td>受訪者簽名</td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <div
                            style={{ width: "100%" }}
                            ref={ (r) => {
                                if (r !== null && r !== undefined) setSignCanvasWidth(r.clientWidth);
                            } } >
                            <SignatureCanvas
                                canvasProps={ { width:signCanvasWidth, height: 420, className: "sigCanvas" } }
                                penColor='black'
                                ref={ signCanvasRef }
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="sc1qr-label">服務使用者理解能力</td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q1 === 0 }
                            onClick={ () => setFormData({ ...formData, q1: 0 }) }
                            type="checkbox"
                        />
                        <p>良好</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q1 === 1 }
                            onClick={ () => setFormData({ ...formData, q1: 1 }) }
                            type="checkbox"
                        />
                        <p>僅可理解簡單</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q1 === 2 }
                            onClick={ () => setFormData({ ...formData, q1: 2 }) }
                            type="checkbox"
                        />
                        <p>僅可理解關鍵字</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q1 === 3 }
                            onClick={ () => setFormData({ ...formData, q1: 3 }) }
                            type="checkbox"
                        />
                        <p>無法理解</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q1 === 4 }
                            onClick={ () => setFormData({ ...formData, q1: 4 }) }
                            type="checkbox"
                        />
                        <p>無法判斷理解力</p>
                    </td>
                </tr>
                <tr>
                    <p className="sc1qr-label">服務使用者近期3個月內體重是否下降?</p>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q2 }
                            onClick={ () => setFormData({ ...formData, q2: true }) }
                            type="checkbox"
                        />
                        <p>是</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ !formData.q2 }
                            onClick={ () => setFormData({ ...formData, q2: false }) }
                            type="checkbox"
                        />
                        <p>否</p>
                    </td>
                    <p className="sc1qr-label">目前身高</p>
                    <input
                        onChange={ (e) => setFormData({ ...formData, q2_height: +e.target.value }) }
                        value={ formData.q2_height }
                    />

                    <p className="sc1qr-label">體重</p>
                    <input
                        onChange={ (e) => setFormData({ ...formData, q2_width: +e.target.value }) }
                        value={ formData.q2_width }
                    />
                </tr>
                <tr>
                    <td className="sc1qr-label">管路留置</td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q3_1 }
                            onClick={ () => setFormData({ ...formData, q3_1: !formData.q3_1 }) }
                            type="checkbox"
                        />
                        <p>鼻胃管</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q3_2 }
                            onClick={ () => setFormData({ ...formData, q3_2: !formData.q3_2 }) }
                            type="checkbox"
                        />
                        <p>氣切管</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q3_3 }
                            onClick={ () => setFormData({ ...formData, q3_3: !formData.q3_3 }) }
                            type="checkbox"
                        />
                        <p>尿管</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q3_4 }
                            onClick={ () => setFormData({ ...formData, q3_4: !formData.q3_4 }) }
                            type="checkbox"
                        />
                        <p>無</p>
                    </td>
                </tr>
                <tr>
                    <td className="sc1qr-label">餐食狀況:</td>
                    <input onChange={ (e) => setFormData({ ...formData, q4: e.target.value }) } value={ formData.q4 } />
                </tr>
                <tr>
                    <td className="sc1qr-label">環境狀況(內/外在):</td>
                    <input onChange={ (e) => setFormData({ ...formData, q5: e.target.value }) } value={ formData.q5 } />
                </tr>
                <tr>
                    <td className="sc1qr-label">皮膚狀況</td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q6_1 }
                            onClick={ () => setFormData({ ...formData, q6_1: !formData.q6_1 }) }
                            type="checkbox"
                        />
                        <p>完整</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q6_2 }
                            onClick={ () => setFormData({ ...formData, q6_2: !formData.q6_2 }) }
                            type="checkbox"
                        />
                        <p>壓瘡</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q6_3 }
                            onClick={ () => setFormData({ ...formData, q6_3: !formData.q6_3 }) }
                            type="checkbox"
                        />
                        <p>一般受傷</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q6_4 }
                            onClick={ () => setFormData({ ...formData, q6_4: !formData.q6_4 }) }
                            type="checkbox"
                        />
                        <p>紅濕疹</p>
                    </td>
                </tr>
                <tr>
                    <td className="sc1qr-label">習慣就醫診所</td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q7_1 }
                            onClick={ () => setFormData({ ...formData, q7_1: !formData.q7_1 }) }
                            type="checkbox"
                        />
                        <p>慈濟</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q7_2 }
                            onClick={ () => setFormData({ ...formData, q7_2: !formData.q7_2 }) }
                            type="checkbox"
                        />
                        <p>門諾</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q7_3 }
                            onClick={ () => setFormData({ ...formData, q7_3: !formData.q7_3 }) }
                            type="checkbox"
                        />
                        <p>805</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q7_4 }
                            onClick={ () => setFormData({ ...formData, q7_4: !formData.q7_4 }) }
                            type="checkbox"
                        />
                        <p>部花</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q7_5 }
                            onClick={ () => setFormData({ ...formData, q7_5: !formData.q7_5 }) }
                            type="checkbox"
                        />
                        <p>其他</p>
                        <input value={formData.q7_5_msg} onChange={e => setFormData({ ...formData, q7_5_msg: e.target.value })}/>
                    </td>
                </tr>
                <tr>
                    <td className="sc1qr-label">就醫科別</td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q8_1 }
                            onClick={ () => setFormData({ ...formData, q8_1: !formData.q8_1 }) }
                            type="checkbox"
                        />
                        <p>心內</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q8_2 }
                            onClick={ () => setFormData({ ...formData, q8_2: !formData.q8_2 }) }
                            type="checkbox"
                        />
                        <p>神內</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q8_3 }
                            onClick={ () => setFormData({ ...formData, q8_3: !formData.q8_3 }) }
                            type="checkbox"
                        />
                        <p>泌尿</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q8_4 }
                            onClick={ () => setFormData({ ...formData, q8_4: !formData.q8_4 }) }
                            type="checkbox"
                        />
                        <p>復健</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q8_5 }
                            onClick={ () => setFormData({ ...formData, q8_5: !formData.q8_5 }) }
                            type="checkbox"
                        />
                        <p>身心</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q8_6 }
                            onClick={ () => setFormData({ ...formData, q8_6: !formData.q8_6 }) }
                            type="checkbox"
                        />
                        <p>中醫</p>
                    </td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q8_7 }
                            onClick={ () => setFormData({ ...formData, q8_7: !formData.q8_7 }) }
                            type="checkbox"
                        />
                        <p>其他</p>
                        <input value={formData.q8_7_msg} onChange={e => setFormData({ ...formData, q8_7_msg: e.target.value })}/>
                    </td>
                </tr>
                <tr>
                    <td className="sc1qr-label">服用藥物(檢視藥袋)</td>
                    <input onChange={ (e) => setFormData({ ...formData, q9: e.target.value }) } value={ formData.q9 } />
                </tr>
                <tr>
                    <td className="sc1qr-label">長期處方簽</td>
                    <td className="ckbox" >
                        <input
                            checked={ formData.q10 === false }
                            onClick={ () => setFormData({ ...formData, q10: false }) }
                            type="checkbox"
                        />
                        <p>無</p>
                    </td>

                    <td className="ckbox" >
                        <input
                            checked={ formData.q10 === true }
                            onClick={ () => setFormData({ ...formData, q10: true }) }
                            type="checkbox"
                        />
                        <p>有</p>
                    </td>
                </tr>
                <tr>
                    <td className="sc1qr-label">疾病史:</td>
                    <input onChange={ (e) => setFormData({ ...formData, q11: e.target.value }) } value={ formData.q11 } />
                </tr>
                <tr>
                    <td className="sc1qr-label">家中輔具狀況</td>
                </tr>
                <tr>
                    <td>
                        <p>無</p>
                        <input type="checkbox" checked={formData.q12 === false} onClick={() => setFormData({ ...formData, q12: false })}/>
                    </td>
                    <td>
                        <p>有</p>
                        <input type="checkbox" checked={formData.q12 === true} onClick={() => setFormData({ ...formData, q12: true })}/>
                    </td>
                </tr>
                {formData.q12 ?
                    <tr>
                        <td className="ckbox" >
                            <input
                                checked={ formData.q12_1 }
                                onClick={ () => setFormData({ ...formData, q12_1: !formData.q12_1 }) }
                                type="checkbox"
                            />
                            <p>輪椅</p>
                        </td>

                        <td className="ckbox" >
                            <input
                                checked={ formData.q12_2 }
                                onClick={ () => setFormData({ ...formData, q12_2: !formData.q12_2 }) }
                                type="checkbox"
                            />
                            <p>電動床</p>
                        </td>

                        <td className="ckbox" >
                            <input
                                checked={ formData.q12_3 }
                                onClick={ () => setFormData({ ...formData, q12_3: !formData.q12_3 }) }
                                type="checkbox"
                            />
                            <p>氣墊床 </p>
                        </td>

                        <td className="ckbox" >
                            <input
                                checked={ formData.q12_4 }
                                onClick={ () => setFormData({ ...formData, q12_4: !formData.q12_4 }) }
                                type="checkbox"
                            />
                            <p>便盆椅</p>
                        </td>

                        <td className="ckbox" >
                            <input
                                checked={ formData.q12_5 }
                                onClick={ () => setFormData({ ...formData, q12_5: !formData.q12_5 }) }
                                type="checkbox"
                            />
                            <p>助行器</p>
                        </td>

                        <td className="ckbox" >
                            <input
                                checked={ formData.q12_6 }
                                onClick={ () => setFormData({ ...formData, q12_6: !formData.q12_6 }) }
                                type="checkbox"
                            />
                            <p>單拐</p>
                        </td>
                    </tr> : <div />}
                <tr>
                    <td className="sc1qr-label">手環監測/長照服務品質狀況:</td>
                    <input onChange={ (e) => setFormData({ ...formData, q13: e.target.value }) } value={ formData.q13 } />
                </tr>
                <tr>
                    <td className="sc1qr-label">特殊事件:</td>
                    <input onChange={ (e) => setFormData({ ...formData, q14: e.target.value }) } value={ formData.q14 } />
                </tr>
            </table>
        </div>
        <button className="ghost-button" onClick={ goBack }>取消</button>
        <button className="ghost-button" onClick={ submit }>送出</button>
    </div>;
}
