/* eslint-disable max-len */
import React from "react";

const RunningIcon = (props: { color?: string }) => {
    let styleColor: any = props.color;

    switch (styleColor) {
    case "紅燈":
        styleColor = "#FF0000";
        break;

    case "黃燈":
        styleColor = "#FBB03B";
        break;

    case "綠燈":
        styleColor = "#39B54A";
        break;

    case "red":
        styleColor = "#FF0000";
        break;

    case "yellow":
        styleColor = "#FBB03B";
        break;

    case "green":
        styleColor = "#39B54A";
        break;

    default:
        styleColor = "#888888";
        break;
    }

    return <svg className="icon" fill={ styleColor } xmlns="http://www.w3.org/2000/svg">
        <g>
            <path
                d="M28,29.07c2.07,1.84,3.94,3.56,5.87,5.2a3.52,3.52,0,0,1,1.36,3.82C34.4,42,33.77,45.94,33,49.85c-.42,2.11-1.79,3.09-3.61,2.74s-2.66-1.78-2.29-3.92c.53-3,1-6,1.73-9a1.69,1.69,0,0,0-1-2.23c-2.67-1.58-5.3-3.24-7.95-4.87-2.84-1.76-3.51-4.5-1.79-7.33,1.94-3.19,3.91-6.37,6-9.79-1.72-.56-3.3-1.15-4.92-1.55a2.21,2.21,0,0,0-1.48.53c-2,1.41-4,2.88-6,4.31-1.54,1.08-3,1-3.87-.22s-.53-2.55,1-3.69c2.46-1.79,4.88-3.67,7.45-5.29a5.32,5.32,0,0,1,3.34-.7,50.8,50.8,0,0,1,7.6,1.68A26.53,26.53,0,0,1,32.7,13c1.65,1,1.92,3,.88,5.1C31.78,21.74,29.92,25.34,28,29.07Z"
            />
            <path
                d="M19.93,35.88a22.21,22.21,0,0,1-2.94,4,7,7,0,0,1-3.82,1.69c-3.22.24-6.47.1-9.71.07C1.2,41.6-.06,40.43,0,38.49s1.26-2.84,3.52-2.86,4.58-.11,6.85,0a2.86,2.86,0,0,0,3.19-1.73A19.19,19.19,0,0,1,15,31.64Z"
            />
            <path
                d="M36.18,11a5.54,5.54,0,0,1-5.59-5.5,5.51,5.51,0,1,1,11,0A5.6,5.6,0,0,1,36.18,11Z"
            />
            <path
                d="M35.65,19.52c.18.21.25.26.26.31.79,4.43,3.71,5.37,7.65,4.74a3.69,3.69,0,0,1,2.4.21c.77.54,1.68,1.6,1.62,2.37a2.93,2.93,0,0,1-1.91,2.09,80.14,80.14,0,0,1-9.3.07c-1.69-.07-3.65-3.8-2.92-5.4C34.09,22.48,34.86,21.1,35.65,19.52Z"
            />
        </g>
    </svg>;
};

export default RunningIcon;
