/* eslint-disable max-len */
import React from "react";

const HoldIcon = (props: { color?: string }) => {
    let styleColor: any = props.color;

    switch (styleColor) {
    case "紅燈":
        styleColor = "#FF0000";
        break;

    case "黃燈":
        styleColor = "#FBB03B";
        break;

    case "綠燈":
        styleColor = "#39B54A";
        break;

    case "red":
        styleColor = "#FF0000";
        break;

    case "yellow":
        styleColor = "#FBB03B";
        break;

    case "green":
        styleColor = "#39B54A";
        break;

    default:
        styleColor = "#888888";
        break;
    }

    return <svg className="icon" fill={ styleColor } viewBox="0 0 51.05 53" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath id="clip-path">
                <rect
                    className="cls-1"
                    height="806"
                    transform="translate(-386.89 -971.4) rotate(-89.94)"
                    width="81.03"
                    x="-720.2"
                    y="-695.04"
                />
            </clipPath>
        </defs>
        <title>icon</title>
        <g className="cls-2">
            <g id="上下層">
                <g className="cls-4">
                    <g className="cls-5">
                        <path className="cls-6" d="M27.06,38.07H25.84V36.86C26.89,36.63,27.29,37,27.06,38.07Z" />
                        <path className="cls-6" d="M5.58,38.05H4.38V36.84C5.42,36.61,5.82,37,5.58,38.05Z" />
                        <path
                            className="cls-6"
                            d="M3.19,16.38a2.29,2.29,0,0,1,2.12-2.1,7.21,7.21,0,0,1,1.51,1.51A1.85,1.85,0,0,1,3.19,16.38Z"
                        />
                    </g>
                </g>
            </g>
            <g data-name="圖層 9" id="圖層_9">
                <g id="US0DeM">
                    <path
                        className="cls-7"
                        d="M24,12.09a3.9,3.9,0,0,0-1.49,3.51c0,.22.08.43.12.65.31,1.8,3.66,4.14,5.82,3.37a4.12,4.12,0,0,0,2.59-2.42,1.23,1.23,0,0,1,.6-.59c.46-.23,1-.34,1.44-.54a2.26,2.26,0,0,1,2.33.28c.57.39,1.16.77,1.71,1.18a2.14,2.14,0,0,1,.55,3.06c-1,1.56-2.11,3.12-3.2,4.65a2.15,2.15,0,0,1-3,.63,2.19,2.19,0,0,1-.74-2.93c.47-.91,1-1.81,1.45-2.71s.44-1.33-.07-1.63-.95-.05-1.41.8L29.37,22a3.92,3.92,0,0,0,3.85,5.93s.06,0,.14.06c-.53,1.19-1.25,2.33-1,3.73a4.1,4.1,0,0,0,2.37,2.95l-.49.42a3.88,3.88,0,0,0,4.43,6.34c.91-.53,1.77-1.14,2.7-1.74l4.86,7.68-4,1.26c-3.1,1-6.2,2-9.29,3a.57.57,0,0,1-.79-.29c-3.09-4.9-6.2-9.8-9.29-14.69a1.37,1.37,0,0,1-.22-.6,17,17,0,0,0-5.33-12.29l-.16-.19c1.1-2.42,2.21-4.84,3.3-7.27a6.55,6.55,0,0,0-1.83-7.83,2.15,2.15,0,0,0-3.36.47c-1.59,2.33-3.15,4.67-4.73,7l-.43.62L0,0H15.74a1,1,0,0,1,.92.5q3.49,5.55,7,11.07C23.79,11.72,23.88,11.88,24,12.09Z"
                    />
                    <path
                        className="cls-7"
                        d="M22.46,39.06c.2.29.35.53.51.78C25,43,27,46.2,29,49.37a.74.74,0,0,1,0,.87A3,3,0,0,1,25.7,52c-3.52-.14-7.06,0-10.59,0h-.79v-.62c0-1.06,0-2.12,0-3.18a5,5,0,0,0-.94-2.93c-1.56-2.22-3.15-4.44-4.65-6.71a3.73,3.73,0,0,1-.63-1.87c-.13-4-.17-8-.26-12a4.82,4.82,0,0,1,.87-2.94L16.62,10c.39-.59.59-.61,1.12-.13a4.89,4.89,0,0,1,1.2,5.7c-1.12,2.5-2.27,5-3.4,7.48-.38.83-.33,1,.33,1.61A15.08,15.08,0,0,1,21,36.68a29.79,29.79,0,0,1-.76,4.14c0,.19-.13.38-.18.57a.87.87,0,0,0,.52,1.2c.47.17.92-.09,1.11-.69C21.93,41,22.17,40.08,22.46,39.06Z"
                    />
                    <path
                        className="cls-7"
                        d="M41.31,26.73A8.05,8.05,0,0,1,40.77,28c-.85,1.37-1.73,2.71-2.62,4.05a2.19,2.19,0,0,1-3.83-2.13q1.14-2.43,2.32-4.83a1.88,1.88,0,0,1,2-1A3.22,3.22,0,0,1,41.31,26.73Z"
                    />
                    <path
                        className="cls-7"
                        d="M43.08,35.06a2.41,2.41,0,0,1-1,2c-1.4,1-2.8,1.92-4.25,2.81a2.16,2.16,0,0,1-2.53-3.47c.88-.73,1.88-1.33,2.77-2a10.59,10.59,0,0,0,1.58-1.56,1,1,0,0,1,.85-.43A2.6,2.6,0,0,1,43.08,35.06Z"
                    />
                    <path
                        className="cls-7"
                        d="M29.67,15.82a2.27,2.27,0,0,1-3.25,2,8.26,8.26,0,0,1-1.49-1,2.13,2.13,0,0,1-.45-2.68,2,2,0,0,1,2.52-1,7.74,7.74,0,0,1,2.06,1.4C29.38,14.83,29.47,15.39,29.67,15.82Z"
                    />
                </g>
            </g>
        </g>
    </svg>;
};
export default HoldIcon;
