/* eslint-disable max-len */
import React from "react";

const NoSmokingIcon = (props: { color?: string }) => {
    let styleColor: any = props.color;

    switch (styleColor) {
    case "紅燈":
        styleColor = "#FF0000";
        break;

    case "黃燈":
        styleColor = "#FBB03B";
        break;

    case "綠燈":
        styleColor = "#39B54A";
        break;

    case "red":
        styleColor = "#FF0000";
        break;

    case "yellow":
        styleColor = "#FBB03B";
        break;

    case "green":
        styleColor = "#39B54A";
        break;
    }

    return <svg className="icon" fill={ styleColor } xmlns="http://www.w3.org/2000/svg">
        <g>
            <path
                d="M28.83,56.26a28.94,28.94,0,0,1-18.31-6.42,27.71,27.71,0,0,1-9.7-15,26.79,26.79,0,0,1-.76-5,27.55,27.55,0,0,1,.1-4.72A27.23,27.23,0,0,1,5.45,11.68,28.62,28.62,0,0,1,21.22,1,28.34,28.34,0,0,1,30,0a28.66,28.66,0,0,1,17.16,6.4A27.51,27.51,0,0,1,57,21.83a25.92,25.92,0,0,1,.71,7.39,27.41,27.41,0,0,1-9,19.29A28.4,28.4,0,0,1,35,55.62,29.31,29.31,0,0,1,28.83,56.26Zm-18-41.08,0,0-.08.1A21.22,21.22,0,0,0,6.61,30.85a20.68,20.68,0,0,0,1.69,6A22.43,22.43,0,0,0,27.52,50a23.34,23.34,0,0,0,6.07-.46,22.46,22.46,0,0,0,8.46-3.71l.08-.07Zm4.71-4.66L46.87,41.08l0,0L47,41A21.35,21.35,0,0,0,51.2,29.62a21,21,0,0,0-.45-6.12,21.46,21.46,0,0,0-7.16-11.82A22.22,22.22,0,0,0,30.4,6.33a22.69,22.69,0,0,0-5.58.29,22.42,22.42,0,0,0-7.76,2.92C16.55,9.85,16.05,10.19,15.53,10.52Z"
            />
            <path className="cls-1" d="M9.87,33.93v-5H23.64a.35.35,0,0,1,.27.11l4.85,4.74.2.18Z" />
            <path
                d="M31.7,18.13c-.4-.1-.79-.16-1.16-.28A4.88,4.88,0,0,1,27,14.11a5,5,0,0,1,1.79-5.24,4.6,4.6,0,0,1,3-.93.82.82,0,0,1,.85.85.85.85,0,0,1-.87.83,3.27,3.27,0,0,0-1.83.49,3,3,0,0,0-1.27,1.94,3.32,3.32,0,0,0,.71,3.15,3.59,3.59,0,0,0,2.3,1.19,8.25,8.25,0,0,0,1.11.05.87.87,0,0,1,.83.42.82.82,0,0,1,0,.91A2.75,2.75,0,0,0,34.07,21a1.9,1.9,0,0,0,1.34.58c.66,0,1.33,0,2,0,1.13,0,2.25,0,3.38,0a4.72,4.72,0,0,1,2,.53,3.64,3.64,0,0,1,2,3.16c0,.88,0,1.76,0,2.64,0,0,0,.05,0,.08H43.15c0-.07,0-.13,0-.19,0-.77,0-1.54,0-2.31a2.12,2.12,0,0,0-1.87-2.14,6.7,6.7,0,0,0-1.09-.1c-1.52,0-3,0-4.57,0A3.8,3.8,0,0,1,33,22.33a4.08,4.08,0,0,1-1.38-2.65A4.25,4.25,0,0,1,31.7,18.13Z"
            />
            <path
                d="M39.07,17.54h1.31a7.09,7.09,0,0,1,7,5.5,7.37,7.37,0,0,1,.23,1.87V28H45.84v-.21c0-1.1,0-2.21,0-3.32a5.44,5.44,0,0,0-4.22-5.1,7.29,7.29,0,0,0-1.32-.13c-.91,0-1.81,0-2.72,0a.8.8,0,0,1-.74-.45.81.81,0,0,1,0-.87l.1-.14a4.75,4.75,0,0,0,.89-2.29A3.65,3.65,0,0,0,35,11.4a9.65,9.65,0,0,0-1.08-.13c-.12,0-.26,0-.38,0a.84.84,0,0,1,.22-1.65A5.5,5.5,0,0,1,39.51,14a5.76,5.76,0,0,1-.38,3.33A1.07,1.07,0,0,0,39.07,17.54Z"
            />
            <path
                d="M42.14,33.93H40.71a.32.32,0,0,1-.18-.11Q38.24,31.6,36,29.36c-.15-.14-.29-.3-.43-.45h6.61Z"
            />
            <path className="cls-1" d="M47.57,28.83v5.08H45.86V28.83Z" />
            <path className="cls-1" d="M44.86,33.92H43.15V28.83h1.71Z" />
        </g>
    </svg>;
};

export default NoSmokingIcon;
