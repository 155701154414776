import { convertToApiDate } from "../../utils/apiDate";

export interface CallFormData {
    svc_date: string,
    svc_e_id: string,
    svc_e_name: string,
    sign: string,
    "q1": string,
    "q2": string,
    "q3": string,
    "q4": string,
    "q5": string,
    "q6": string,
    "q7": string,
}

export const defaultCallFormData: CallFormData = {
    svc_date: convertToApiDate(new Date()),
    svc_e_id: "",
    svc_e_name: "",
    sign: "",
    q1: "", q2: "", q3: "", q4: "", q5: "", q6: "", q7: ""
};
