export default interface ElderFormContent {
    "e_name": string,
    "e_iden": string,
    "e_sex": number,
    "e_tel": string,
    "e_mobile": string,
    "h_country": string,
    "h_city": string,
    "h_vil": string,
    "h_addr": string,
    "h_country_name": string,
    "h_city_name": string,
    "h_vil_name": string,
    "l_country": string,
    "l_city": string,
    "l_vil": string,
    "l_addr": string,
    "imei": string,
    "guard": 0 | 1 | 2,
    "clan": string,
    "h_id": string,
    "h_name": string,
    "e_id": string,
    "e_pwd": string,
    "e_img": string | null,
    "is_chg": boolean | undefined,
    "chg_date": Date,
    "chg_unit": string,
    "c_datas": {
        "c_name": string,
        "c_tel": string,
        "c_mobile": string,
        "c_type": number,
        "c_id": string
    }[],
    "f_datas": {
        "f_name": string,
        "f_tel": string,
        "f_mobile": string,
        "f_relat": string,
        "f_id": string,
        "f_pwd": string,
        "f_img": string,
        "f_sms": boolean
    }[],
    nhi1: string,
    nhi2: string,
    nhi3: string
};;;;;;;;;;

export const emptyElderFormContent: ElderFormContent = {
    "e_name": "",
    "e_iden": "",
    "nhi1": "",
    "nhi2": "",
    "nhi3": "",
    "e_sex": 0,
    "e_tel": "",
    "e_mobile": "",
    "h_country": "10015",
    "h_city": "1001506",
    "h_vil": "10015060007",
    "h_addr": "",
    "h_country_name": "",
    "h_city_name": "",
    "h_vil_name": "",
    "l_country": "10015",
    "l_city": "1001506",
    "l_vil": "10015060007",
    "l_addr": "",
    "imei": "",
    "guard": 0,
    "clan": "",
    "h_id": "",
    "h_name": "",
    "e_id": "",
    "e_pwd": "",
    "e_img": "",
    "is_chg": undefined,
    "chg_date": new Date(),
    "chg_unit": "",
    "c_datas": [],
    "f_datas": [],
};
