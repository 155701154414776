import React from "react";

export default function Bar(props: { color: "red" | "yellow" | "green" | "紅燈" | "黃燈" | "綠燈" }) {
    let styleColor: string = props.color;
    let className = "";

    switch (styleColor) {
    case "紅燈":
        styleColor = "#FF0000";
        className = "red";
        break;

    case "黃燈":
        styleColor = "#FBB03B";
        className = "yellow";
        break;

    case "綠燈":
        styleColor = "#39B54A";
        className = "green";
        break;

    case "red":
        styleColor = "#FF0000";
        className = "red";
        break;

    case "yellow":
        styleColor = "#FBB03B";
        className = "yellow";
        break;

    case "green":
        styleColor = "#39B54A";
        className = "green";
        break;
    }

    return <div
        className={ "color-bar color-bar-" + className }
        style={ {
            width: "calc(100% - 8px)",
            margin: 4,
            backgroundColor: styleColor,
            height: 10,
            borderRadius: 8,
        } }
    />;
}
