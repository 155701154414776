import { Route, Switch } from "react-router-dom";
import React from "react";
import ManagerListPage from "../pages/Manager/ManagerListPage";
import ManagerCreatePage from "../pages/Manager/ManagerCreatePage";
import ManagerEditPage from "../pages/Manager/ManagerEditPage";

const ManagerRouter = () => {
    return <Switch>
        <Route component={ ManagerCreatePage } path="/manager/create" />
        <Route component={ ManagerEditPage } path="/manager/:managerId/edit" />
        <Route component={ ManagerListPage } path="/manager" />
    </Switch>;
};

export default ManagerRouter;
