import React, { useEffect, useState } from "react";
import useAPI from "../../hooks/useAPI";
import "../../styles/ElderListPage.sass";
import { useHistory } from "react-router";
import { GetEldersResponse } from "../../models/Responses";
import useErrorHandler from "../../hooks/useErrorHandler";
import { Col, Row } from "react-bootstrap";
import { guardName } from "../../utils/GuardName";
import TitleBar from "../../components/TitleBar";

export default function ElderListPage() {
    const API = useAPI();
    const history = useHistory();
    const errorHandler = useErrorHandler();

    const [data, setData] = useState<GetEldersResponse | undefined>();
    const [keyword, setKeyword] = useState("");
    const [sort, setSort] = useState("none");

    const displayElders = () => {
        if (data === undefined) return [];

        const filletedData = data.datas.filter((elder) => elder.e_name.includes(keyword));

        switch (sort) {
        case "name":
            return filletedData.sort((a, b) => a.e_name.localeCompare(b.e_name));
        case "gender":
            return filletedData.sort((a, b) => a.e_sex - b.e_sex);
        case "tel":
            return filletedData.sort((a, b) => a.e_tel.localeCompare(b.e_tel));
        case "manager":
            return filletedData.sort((a, b) => a.h_name.localeCompare(b.h_name));
        case "guard":
            return filletedData.sort((a, b) => b.guard - a.guard);
        default:
            return filletedData;
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0 });
        API.getElders().then((res) => setData((res))).catch(errorHandler.handle);
    }, []);

    return <div className="elder-list sc1qr-list-ct">
        <TitleBar title="長者管理" />
        <div className="row-search">
            <input onChange={ (e) => setKeyword(e.target.value) } placeholder="查詢姓名" value={ keyword } />
            <select onChange={ (e) => setSort(e.target.value) } value={ sort }>
                <option value="none">無排序</option>
                <option value="name">長者姓名</option>
                <option value="gender">長者性別</option>
                <option value="tel">聯絡電話</option>
                <option value="manager">健康管理員</option>
                <option value="guard">守護圈</option>
            </select>
            <button
                className="primary-button"
                onClick={ () => {
                    window.location.href = "/elder/create";
                } }
            >新增長者
            </button>
        </div>

        <Row style={ { margin: 0 } }>
            {data === undefined ? <p className="info">載入資料中 ...</p> :
                <>{displayElders().length === 0 ?
                    <p className="info">找不到姓名包含 {keyword} 的長者</p> : <>{displayElders().map((elder) =>
                        <Col
                            key={ elder.e_id }
                            md={ 6 }
                            xl={ 4 }
                        >
                            <div className="elder-card sc-Pcard">
                                <Row className="r1" noGutters>
                                    <Col className="c1" xs={ 9 }>
                                        <img
                                            alt=""
                                            src={ elder.e_sex === 0 ? "/assets/female.svg" : "/assets/male.svg" }
                                        />
                                        <p>{elder.e_name}</p></Col>
                                    <Col className="c2" xs={ 3 }>
                                        <button
                                            className="primary-button"
                                            onClick={ () => history.push("/elder/" + elder.e_id) }
                                        >進入管理
                                        </button>
                                    </Col>
                                </Row>

                                <Row className="r2">
                                    <Col className="c1" xs={ 4 }>
                                        <p className="tel">{elder.e_tel}</p>
                                        <p className="mobile">{elder.e_mobile}</p>
                                    </Col>
                                    <Col className="c2" xs={ 4 }>
                                        <p>健康管理員</p>
                                        <p>守護圈</p>
                                    </Col>
                                    <Col className="c3" xs={ 4 }>
                                        <p>{elder.h_name}</p>
                                        <p>{guardName(elder.guard)}</p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>)}</>}</>}
        </Row>
    </div>;
}
