import React, { useEffect, useState } from "react";
import { defaultSOFFormData, SOFFormData } from "../../models/form/SOFForm";
import TitleBar from "../../components/TitleBar";
import { useParams } from "react-router";
import useAPI from "../../hooks/useAPI";
import useErrorHandler from "../../hooks/useErrorHandler";

/** 衰弱評估問卷頁面 */
export default function SOFFormPage() {
    const API = useAPI();
    const errorHandler = useErrorHandler();
    const { id } = useParams<{ id: string }>();
    const isCreating = id === "create";
    const elderId = window.location.search.split("elderId=")[1];

    const [formData, setFormData] = useState<SOFFormData>(defaultSOFFormData);

    function goBack() {
        window.location.href = "/elder/" + (elderId ? elderId : "");
    }

    async function submit() {
        try {
            if (isCreating) {
                const res = await API.createSOFReport(formData, elderId);
                alert("測定結果：" + res.fraction + "，" + res.description);
                goBack();
            } else {
                const res = await API.updateSOFReport(formData, id);
                alert("測定結果：" + res.fraction + "，" + res.description);
                goBack();
            }
        } catch (err) {
            errorHandler.handle(err);
        }
    }

    useEffect(() => {
        if(!isCreating)
            API.getSOFData(id).
                then((res) => setFormData({ q1: false, q2: false, q3: false, ...res }))
                .catch((err) => {
                    errorHandler.handle(err);
                    window.location.href = "/elder/" + elderId;
                });
    }, [id]);

    return <div className="elder-report-ct elder-detail report-SOF">
        <TitleBar title={ isCreating ? "新增衰弱評估問卷" : "衰弱評估問卷" } isForm />

        <table>
            <thead>
                <tr>
                    <th>指標</th>
                    <th>衰弱評估詢問內容</th>
                    <th>評分</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>體重減輕</td>
                    <td>
                        <p className="qa-info">1. 未刻意減重狀況下，過去一年體重減少了3公斤或5%以上?</p>
                        <p className="qa-comment">(先問個案體重和一年相較差不多還是減少?如果減少再問大約減少幾公斤?)</p>
                    </td>
                    <td className="ckbox">
                        <input
                            checked={ formData.q1 === true }
                            onChange={ () => setFormData({ ...formData, q1: true }) }
                            type="checkbox"
                        />
                        <p>是(1分)</p>
                    </td>
                    <td className="ckbox">
                        <input
                            checked={ formData.q1 === false }
                            onChange={ () => setFormData({ ...formData, q1: false }) }
                            type="checkbox"
                        />
                        <p>否(0分)</p>

                    </td>
                </tr>
                <tr>
                    <td>下肢功能</td>
                    <td>
                        <p className="qa-info">2. 無法在不用手支撐的情況下，從椅子上站起來五次</p>
                    </td>
                    <td className="ckbox">
                        <input
                            checked={ formData.q2 === true }
                            onChange={ () => setFormData({ ...formData, q2: true }) }
                            type="checkbox"
                        />
                        <p>是(1分)</p>
                    </td>

                    <td className="ckbox">
                        <input
                            checked={ formData.q2 === false }
                            onChange={ () => setFormData({ ...formData, q2: false }) }
                            type="checkbox"
                        />
                        <p>否(0分)</p>
                    </td>
                </tr>
                <tr>
                    <td>精力降低</td>
                    <td>
                        <p className="qa-info">3. 過去一週內，是否覺得提不起勁來做事?</p>
                        <p className="qa-comment">（一個禮拜三天以上有這個感覺）</p>
                    </td>
                    <td className="ckbox">
                        <input
                            checked={ formData.q3 === true }
                            onChange={ () => setFormData({ ...formData, q3: true }) }
                            type="checkbox"
                        />
                        <p>是(1分)</p>
                    </td>
                    <td className="ckbox">
                        <input
                            checked={ formData.q3 === false }
                            onChange={ () => setFormData({ ...formData, q3: false }) }
                            type="checkbox"
                        />
                        <p>否(0分)</p>

                    </td>
                </tr>
            </tbody>
        </table>

        <div className="rp-faq-ct">
            <div className="elder-Title">
                <p>*SOF (Study of Osteoporotic Fractures)</p>
            </div>
            <div className="rp-faq-box">
                <div className="elder-sTitle">
                    <p>一、 說明評分與轉介:</p>
                </div>
                <ul>
                    <li>任1項「是」者為衰弱前期(Pre-Frailty)，若第2及第3部分評估為否，則轉介預防長者衰弱前期健康促進服務計畫。</li>
                    <li>任2項以上「是」者為衰弱期(Frailty)，請轉介至地方政府之長期照顧管理中心，進一步評估與安排至特約單位接受衛生福利部長期照顧十年計畫2.0之「預防及延緩失能照護服務」。</li>
                </ul>

                <div className="elder-sTitle">
                    <p>二、 衰弱評估(SOF 法)第2題之注意事項:</p>
                </div>
                <ul>
                    <li>設施:約40公分高之直靠背椅子，並建議將椅子靠牆擺放。</li>
                    <li>施測者指引:先詢問受試者對於進行此題是否有困難，若有困難者則該題由施測者直接選「是」，另，讓受測者靠著椅背坐下，並請受測者站起，施測期間站起算一次，並請數出聲音來。當受試者於第5次起立時結束測試。</li>
                    <li>受測者指引:請盡你所能連續、不間斷的五次起立並站直、坐下，期間請保持兩手抱胸之姿式。</li>
                    <li>施測過程應隨時注意受試者之狀況。</li>
                    <li>建議5次起坐時間15秒內完成，如超過15秒未完成者，則該題為異常，請勾選「是」。</li>
                </ul>
            </div>
        </div>
        <button className="ghost-button" onClick={ goBack }>取消</button>
        <button className="ghost-button" onClick={ submit }>送出</button>
    </div>;
}
