export interface LoginResponse {
    "ticket": string,
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}

export interface GetEldersResponse {
    "datas": {
        "e_id": string,
        "e_img": string | null,
        "e_name": string,
        "e_sex": 0 | 1,
        "e_tel": string,
        "e_mobile": string,
        "h_name": string,
        "guard": 0 | 1 | 2
    }[],
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}

export interface GetElderResponse {
    "e_name": string,
    "e_iden": string,
    "e_nhi_card": string,
    "e_sex": number,
    "e_tel": string,
    "e_mobile": string,
    "h_country": string,
    "h_city": string,
    "h_vil": string,
    "h_addr": string,
    "h_country_name": string,
    "h_city_name": string,
    "h_vil_name": string,
    "l_country": string,
    "l_city": string,
    "l_vil": string,
    "l_addr": string,
    "imei": string,
    "guard": 0 | 1 | 2,
    "clan": string,
    "h_id": string,
    "h_name": string,
    "e_id": string,
    "e_pwd": string,
    "e_img": string,
    "is_chg": boolean,
    "chg_date": Date,
    "chg_unit": string,
    "c_datas": {
        "c_name": string,
        "c_tel": string,
        "c_mobile": string,
        "c_type": number,
        "c_id": string
    }[],
    "f_datas": {
        "f_name": string,
        "f_tel": string,
        "f_mobile": string,
        "f_relat": string,
        "f_id": string,
        "f_pwd": string,
        "f_img": string,
        "f_sms": boolean
    }[],
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}

export const emptyManagerData: Manager = {
    h_is_enable: true,
    h_h_address: "",
    h_h_city_code: "1001506",
    h_h_county_code: "10015",
    h_h_vil_code: "10015060007",
    h_id: "",
    h_img: null,
    h_l_address: "",
    h_l_city_code: "1001506",
    h_l_county_code: "10015",
    h_l_vil_code: "10015060007",
    h_mobile: "",
    h_name: "",
    h_pwd: "",
    h_sex: 0,
    h_tel: "",
    h_type: 1,
    h_work_date: new Date(),
};

export interface GetHomeVisitDatasResponse {
    "datas": {
        "id": string,
        "home_date": string,
        "c_date": string,
        "c_name": string
    }[],
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}

export interface GetHomeVisitDataResponse {
    "id": string,
    "svc_date": string,
    "svc_e_id": string,
    "svc_e_name": string,
    "sign": string,
    "q0_1": string,
    "q0_2": string,
    "q0_3": string,
    "q0_4": string,
    "q1": 0,
    "q2": boolean,
    "q2_height": 0.0,
    "q2_width": 0.0,
    "q3_1": boolean,
    "q3_2": boolean,
    "q3_3": boolean,
    "q3_4": boolean,
    "q4": string,
    "q5": string,
    "q6_1": boolean,
    "q6_2": boolean,
    "q6_3": boolean,
    "q6_4": boolean,
    "q7_1": boolean,
    "q7_2": boolean,
    "q7_3": boolean,
    "q7_4": boolean,
    "q7_5": boolean,
    "q7_5_msg": string,
    "q8_1": boolean,
    "q8_2": boolean,
    "q8_3": boolean,
    "q8_4": boolean,
    "q8_5": boolean,
    "q8_6": boolean,
    "q8_7": boolean,
    "q8_7_msg": string,
    "q9": string,
    "q10": boolean,
    "q11": string,
    "q12": boolean,
    "q12_msg": string,
    "q12_1": boolean,
    "q12_2": boolean,
    "q12_3": boolean,
    "q12_4": boolean,
    "q12_5": boolean,
    "q12_6": boolean,
    "q13": string,
    "q14": string,
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}

export interface GetCallVisitDatasResponse {
    "datas": {
        "id": string,
        "call_date": string,
        "c_date": string,
        "c_name": string
    }[],
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}

export interface GetCallVisitDataResponse {
    "id": string,
    "sign": string,
    "svc_date": string,
    "svc_e_id": string,
    "svc_e_name": string,
    "q1": string,
    "q2": string,
    "q3": string,
    "q4": string,
    "q5": string,
    "q6": string,
    "q7": string,
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}

export interface GetEightFacetDataListResponse {
    "datas": {
        "c_name": string,
        "id": string,
        "ev_date": string,
        "c_date": string
    }[],
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}

export interface GetEightFacetDataResponse {
    "datas": {
        "le_type": "健康體位" | "肺結核" | "身體活動" | "網路成癮" | "視力健康" | "睡眠健康" | "情緒健康" | "遠離菸害",
        "le_type_i": number,
        "know_how": string,
        "le_datas": {
            "le_title": string,
            "le_result": "綠燈" | "黃燈" | "紅燈",
            "le_msg": string
        }[]
    }[],
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}

export interface GetAppDatasResponse {
    "datas": {
        "step": string,
        "heart": string,
        "spdp": string,
        "sleep": string,
        "body": string,
        "step_date": string,
        "heart_date": string,
        "spdp_date": string,
        "sleep_date": string,
        "body_date": string
    }[],
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}

export interface GetGPMergeDataResponse {
    datas: { details: any[], charts: { dt: string, sp: number, dp: number }[] },
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}

export interface GetReportMergeDataResponse<T> {
    datas: T[],
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}

export interface GetClanListResponse {
    "datas": { code: string, name: string }[]
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}

export interface GetTrackListResponse {
    "datas":
        {
            "e_id": string,
            "e_img": string,
            "e_name": string,
            "e_sex": 1 | 0,
            "e_tel": string,
            "e_mobile": string,
            "home": boolean,
            "change": boolean,
            "change_unit": string
        }[],
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}

export interface Manager {
    "h_id": string,
    "h_pwd": string,
    "h_img": null | string,
    "h_name": string,
    "h_sex": number,
    "h_tel": string,
    "h_mobile": string,
    "h_h_county_code": string,
    "h_h_city_code": string,
    "h_h_vil_code": string,
    "h_h_address": string,
    "h_l_county_code": string,
    "h_l_city_code": string,
    "h_l_vil_code": string,
    "h_l_address": string,
    "h_work_date": Date,
    "h_type": number
    "h_is_enable": boolean,
}


export interface GetManagerListResponse {
    "datas": Manager[],
    "is_ok": boolean,
    "err_title": string,
    "err_msg": string
}

export interface GetManagerResponse {
    "h_id": string,
    "h_pwd": string,
    "h_img": null | string,
    "h_name": string,
    "h_sex": number,
    "h_tel": string,
    "h_mobile": string,
    "h_h_county_code": string,
    "h_h_city_code": string,
    "h_h_vil_code": string,
    "h_h_address": string,
    "h_l_county_code": string,
    "h_l_city_code": string,
    "h_l_vil_code": string,
    "h_l_address": string,
    "h_work_date": Date,
    "h_type": 1,
    "h_is_enable": boolean,
    "is_ok": boolean,
    "err_title": string,
    "err_msg": string
}
