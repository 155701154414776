import useAPI from "../../hooks/useAPI";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import AddContactFormData from "../../models/form/AddContactFormData";

interface AddContactModalProps {
    formData: AddContactFormData,
    setFormData: React.Dispatch<React.SetStateAction<AddContactFormData>>
    type: number | undefined,
    onSave: () => void,
    onCancel: () => void,
    onDelete: () => void
}

const AddContactModal = (props: AddContactModalProps) => {
    const API = useAPI();
    const [relats, setRelats] = useState<{ code: string, name: string }[]>([]);

    const { formData, setFormData } = props;

    useEffect(() => {
        API.getRelatList().then((res) => {
            setRelats(res.datas)
            setFormData({ ...formData, relat: res.datas[0].code })
        });
    }, []);

    const getModalTitle = () => {
        switch (props.type) {
        case 0:
            return "新增日間聯絡人";
        case 1:
            return "新增夜間聯絡人";
        case 2:
            return "新建全天聯絡人";
        }
    };

    async function uploadImage(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];
        if (file !== null && file !== undefined) {
            const res = await API.uploadImage(file);
            setFormData({ ...formData, image: res });
        }
    }

    return <Modal
        className="add-contact-modal"
        contentClassName="modal-content"
        dialogClassName="modal-dialog"
        onHide={ props.onCancel }
        show={ props.type !== undefined }
    >
        <Modal.Header closeButton>
            <h1>{getModalTitle()}</h1>
        </Modal.Header>
        <Modal.Body className="body">
            <div className="inner">
                <Row>
                    <Col>
                        <p>姓名</p>
                    </Col>
                    <Col>
                        <input onChange={ (e) => setFormData({ ...formData, name: e.target.value }) } value={ formData.name } />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p>聯絡電話</p>
                    </Col>
                    <Col>
                        <input onChange={ (e) => setFormData({ ...formData, tel: e.target.value }) } value={ formData.tel } />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p>手機號碼</p>
                    </Col>
                    <Col>
                        <input
                            onChange={ (e) => setFormData({ ...formData, mobile: e.target.value }) }
                            value={ formData.mobile }
                        />
                    </Col>
                </Row>

                {props.type === 2 ? <>
                    <Row>
                        <Col>
                            <p>同意書</p>
                        </Col>
                        <Col>
                            <img alt="" src={ formData.image } style={ { width: "50%" } } />
                            <input
                                onChange={ uploadImage }
                                type="file"
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <p>關係</p>
                        </Col>
                        <Col>
                            <select
                                onChange={ (e) => setFormData({ ...formData, relat: e.target.value }) }
                                value={ formData.relat }
                            >
                                {relats.map((r) => <option key={ r.code } value={ r.code }>{r.name}</option>)}
                            </select>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <p>App帳號</p>
                        </Col>
                        <Col>
                            <input
                                onChange={ (e) => setFormData({ ...formData, id: e.target.value }) }
                                value={ formData.id }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <p>App密碼</p>
                        </Col>
                        <Col>
                            <input
                                onChange={ (e) => setFormData({ ...formData, pwd: e.target.value }) }
                                type="password"
                                value={ formData.pwd }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <p>簡訊告知</p>
                            <input
                                checked={ formData.sms }
                                onClick={ () => setFormData({ ...formData, sms: true }) }
                                type="checkbox"
                            />
                        </Col>
                        <Col style={ { display: "flex" } }>
                            <p>開啟</p>
                            <input
                                checked={ !formData.sms }
                                onClick={ () => setFormData({ ...formData, sms: false }) }
                                type="checkbox"
                            />
                            <p>關閉</p>
                        </Col>
                    </Row>
                </> : <div />}

            </div>
        </Modal.Body>

        <Modal.Footer>
            <button className="ghost-button" onClick={ () => props.onSave() }>保存</button>
            <button className="ghost-button" onClick={ props.onCancel }>關閉</button>
            <button className="ghost-button" onClick={ props.onDelete }>刪除</button>
        </Modal.Footer>
    </Modal>;
};

export default AddContactModal;
