import React, { useEffect, useState } from "react";
import useAPI from "../../hooks/useAPI";
import useErrorHandler from "../../hooks/useErrorHandler";
import { useParams } from "react-router";
import EducationPost, { defaultEducationPost } from "../../models/EducationPost";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import TitleBar from "../../components/TitleBar";
import { imgUrl } from "../../config";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../styles/EditPostPage.sass"

export default function EditPostPage() {
    const API = useAPI();
    const errorHandler = useErrorHandler();
    const { postId } = useParams<{ postId: string }>();
    const isCreating = postId === "create";

    const [formData, setFormData] = useState<EducationPost>(defaultEducationPost);

    async function submit() {
        if (isCreating) {
            try {
                await API.createEducationPost(formData);
                goBack();
            } catch (err) {
                errorHandler.handle(err);
            }
        } else {
            try {
                await API.updateEducationPost(formData);
                goBack();
            } catch (err) {
                errorHandler.handle(err);
            }
        }
    }

    function goBack() {
        window.location.href = "/education";
    }

    useEffect(() => {
        if (!isCreating) {
            try {
                API.getEducationPostData(postId).then((res) => setFormData({ ... res, id: postId }));
            } catch (err) {
                errorHandler.handle(err);
            }
        }
    }, [postId]);

    async function uploadImage(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];
        if (formData !== undefined && file !== null && file !== undefined) {
            const res = await API.uploadImage(file);
            setFormData({ ...formData, img: imgUrl + "/" + res + ".jpg" });
        }
    }

    async function deletePost() {
        try {
            await API.delteEducationPost(postId);
            goBack();
        } catch (err) {
            errorHandler.handle(err);
        }
    }

    return <div className="edu-detail">

        <TitleBar title={ isCreating ? "新增文章" : "編輯文章" } />

        <Row>
            <Col xs={ 6 }>
                <Row>
                    <Col xs={ 2 }>建立日期</Col>
                    <Col xs={ 10 }>
                        <DatePicker
                            dateFormat="yyyy/MM/dd"
                            locale="zh-TW"
                            onChange={ (date: Date) => setFormData({ ...formData, on_date: date }) }
                            selected={ formData.on_date }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={ 2 }>上架日期</Col>
                    <Col xs={ 10 }>
                        <DatePicker
                            dateFormat="yyyy/MM/dd"
                            locale="zh-TW"
                            onChange={ (date: Date) => setFormData({ ...formData, on_date: date }) }
                            selected={ formData.on_date }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={ 2 }>下架日期</Col>
                    <Col xs={ 10 }>
                        <DatePicker
                            dateFormat="yyyy/MM/dd"
                            locale="zh-TW"
                            onChange={ (date: Date) => setFormData({ ...formData, under_date: date }) }
                            selected={ formData.under_date }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={ 2 }>是否置頂</Col>
                    <Col xs={ 10 }>
                        <input
                            checked={ formData.is_top }
                            onClick={ () => setFormData({ ...formData, is_top: !formData.is_top }) }
                            type="checkbox"
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={ 6 }>
                <img
                    alt=""
                    src={ formData.img }
                    style={ { width: "100%" } }
                />
                <input
                    onChange={ uploadImage }
                    type="file"
                />
            </Col>
        </Row>
        <Row>
            <Col xs={ 1 }>文章標題</Col>
            <Col xs={ 11 }>
                <input
                    onChange={ (e) => setFormData({ ...formData, title: e.target.value }) }
                    value={ formData.title }
                />
            </Col>
        </Row>
        <Row>
            <Col xs={ 1 }>發布內容</Col>
            <Col xs={ 11 }>
                <div>
                    <Editor
                        editorClassName="editor-inner"
                        editorState={ formData.content }
                        onEditorStateChange={ (v) => setFormData({ ...formData, content: v }) }
                        toolbarClassName="editor-toolbar"
                        wrapperClassName="editor-wrapper"
                    />
                </div>
            </Col>
        </Row>
        <div className="action-buttons">
            <button className="ghost-button" onClick={ deletePost }>刪除</button>
            <button className="ghost-button" onClick={ goBack }>取消</button>
            <button className="ghost-button" onClick={ submit }>送出</button>
        </div>
    </div>;
}
