import { useCookies } from "react-cookie";

const useToken = () => {
    const [cookies, setCookie, deleteCookie] = useCookies();

    const update = (newToken: string) => setCookie("token", newToken);
    const reset = () => deleteCookie("token");
    const get = () => cookies.token;

    return { get, update, reset };
};

export default useToken;
