/* eslint-disable max-len */
import React from "react";

const HoldCellphoneIcon = (props: { color?: string }) => {
    let styleColor: any = props.color;

    switch (styleColor) {
    case "紅燈":
        styleColor = "#FF0000";
        break;

    case "黃燈":
        styleColor = "#FBB03B";
        break;

    case "綠燈":
        styleColor = "#39B54A";
        break;

    case "red":
        styleColor = "#FF0000";
        break;

    case "yellow":
        styleColor = "#FBB03B";
        break;

    case "green":
        styleColor = "#39B54A";
        break;

    default:
        styleColor = "#888888";
        break;
    }

    return <svg className="icon" fill={ styleColor } viewBox="0 0 51.05 53" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path
                d="M34,43.69c-1.36-.62-2.61-1.23-3.91-1.75a2.58,2.58,0,0,0-1.4-.17,12.58,12.58,0,0,1-9.94-2.36,3.87,3.87,0,0,0-.43-.25l6.06-3.4c1.87-1,3.76-2.07,5.62-3.15,1.53-.88,1.89-2,1.09-3.53-2.56-5-5.16-9.9-7.74-14.84L19.83,7.55a3,3,0,0,1,.25-3.24,1.52,1.52,0,0,1,2.61-.2c2.48,2.76,4.93,5.55,7.4,8.33a18.43,18.43,0,0,0,1.56,1.74,11.11,11.11,0,0,1,3.18,5.94c.35,1.38.6,2.79,1,4.17a16.94,16.94,0,0,0,1,2.94,1.84,1.84,0,0,0,1.19.7c.62.13.78.4.76,1A26,26,0,0,1,36.16,39.1C35.45,40.62,34.71,42.13,34,43.69Z"
            />
            <path className="cls-1" d="M33.87,46.86c2.93-6.23,6.25-12.21,6-19.44l14.45,5.33-7.21,20.1Z" />
            <path
                d="M10.05,22.35c-.76-.2-1.39-.34-2-.55a1.24,1.24,0,0,1-.61-.52C5,16.86,2.65,12.43.26,8A1.24,1.24,0,0,1,.89,6Q7.09,3.08,13.29.19a1.24,1.24,0,0,1,1.86.68q5.66,10.79,11.3,21.59c1.17,2.25,2.32,4.52,3.47,6.78.69,1.34.47,2-.83,2.74L18.23,38.05a1.55,1.55,0,0,1-2.4-1.23.54.54,0,0,1,.09-.35,2.94,2.94,0,0,0,.36-2.68l11.6-6.24C23.81,19.67,19.76,11.8,15.67,3.87L3.24,9.46Z"
            />
            <path
                d="M9.74,32.58c-1.12-.07-1.79-.7-2.06-2s.07-1.88,1.18-2.25a21.52,21.52,0,0,1,2.7-.72A2.18,2.18,0,0,1,14.1,29a2,2,0,0,1-1.28,2.78A26.22,26.22,0,0,1,9.74,32.58Z"
            />
            <path
                d="M7.78,27.16c-.62-.2-1.81-.54-2.95-1a1.19,1.19,0,0,1-.64-.88,6.47,6.47,0,0,1,.28-2.19A1.27,1.27,0,0,1,6,22.22a27.37,27.37,0,0,1,3.26.7,1.85,1.85,0,0,1,1.36,2.3C10.38,26.53,9.67,27.15,7.78,27.16Z"
            />
            <path
                d="M13.45,32.8a2.06,2.06,0,0,1,2.15,2.07A2.26,2.26,0,0,1,13.26,37a2.08,2.08,0,0,1-2.14-2.09A2.21,2.21,0,0,1,13.45,32.8Z"
            />
            <path
                d="M5.12,19.39a32.33,32.33,0,0,1-3.53-1A2.07,2.07,0,0,1,.34,16.06a2,2,0,0,1,1.78-1.77c.2,0,.53,0,.6.16.86,1.55,1.69,3.12,2.53,4.68Z"
            />
            <path className="cls-1" d="M11.51,25.12l.89,1.65-1.53.15Z" />
            <path className="cls-1" d="M14.76,31.25l.65,1.25L14.08,32Z" />
        </g>
    </svg>;
};

export default HoldCellphoneIcon;
