import { EditorState } from "draft-js";

export default interface EducationPost {
    id: string;
    title: string;
    is_top: boolean;
    content: EditorState;
    on_date: Date;
    under_date: Date;
    c_date: Date;
    c_user: string;
    img: string
};;;;;;;;;;

export const defaultEducationPost: EducationPost = {
    c_date: new Date(),
    c_user: "",
    content: EditorState.createEmpty(),
    id: "",
    img: "",
    is_top: false,
    on_date: new Date(),
    title: "",
    under_date: new Date(),
};
