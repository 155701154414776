import { Route, Switch } from "react-router-dom";
import React from "react";
import GDSFormPage from "../pages/ReportForm/GDSFormPage";
import SOFFormPage from "../pages/ReportForm/SOFFormPage";
import ScreeningFormPage from "../pages/ReportForm/ScreeningFormPage";
import CallFormPage from "../pages/ReportForm/CallFormPage";
import HomeFormPage from "../pages/ReportForm/HomeFormPage";

const ReportRouter = () => {
    return <Switch>
        <Route component={ GDSFormPage } path="/report/GDS/:id" />
        <Route component={ SOFFormPage } path="/report/SOF/:id" />
        <Route component={ ScreeningFormPage } path="/report/NEED/:id" />
        <Route component={ HomeFormPage } path="/report/Home/:id" />
        <Route component={ CallFormPage } path="/report/Call/:id" />
    </Switch>;
};

export default ReportRouter;
