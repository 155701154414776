import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useAPI from "../hooks/useAPI";
import "../styles/LoginPage.sass";

const LoginPage = () => {
    const [id, setId] = useState("");
    const [pwd, setPwd] = useState("");

    const [isLoading, setLoading] = useState(false);

    const API = useAPI();
    const history = useHistory();

    async function login() {
        try {
            setLoading(true);
            await API.login(id, pwd);
            window.scrollTo(0, 0);
            history.push("/track/");
        } catch (err) {
            alert(err);
        } finally {
            setLoading(false);
        }
    }

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            login();
        }
    };

    return <div className="login-page">
        <div className="login-form">
            <img alt="" className="home-logo" src={ "/assets/home-logo.png" } />
            <h1>社區健康管理平台</h1>
            <div className="id-and-pwd">
                <input onChange={ (e) => setId(e.target.value) } value={ id } />
                <input onChange={ (e) => setPwd(e.target.value) } onKeyDown={ handleKeyDown } type="password" value={ pwd } />
            </div>
            <div className="keep-login-and-forget-pwd">
                <div className="keep-login">
                    <input type="checkbox" />
                    <p>保持登入</p>
                </div>
            </div>
            <button className="primary-button" disabled={ isLoading } onClick={ login }>
                {isLoading ? "請稍後 ..." : "登入"}
            </button>
        </div>
    </div>;
};

export default LoginPage;
