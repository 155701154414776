export interface HomeFormData {
    "sign": string,
    "q0_1": string,
    "q0_2": string,
    "q0_3": string,
    "q0_4": string,
    "q1": number,
    "q2": boolean | undefined,
    "q2_height": number,
    "q2_width": number,
    "q3_1": boolean | undefined,
    "q3_2": boolean | undefined,
    "q3_3": boolean | undefined,
    "q3_4": boolean | undefined,
    "q4": string,
    "q5": string,
    "q6_1": boolean | undefined,
    "q6_2": boolean | undefined,
    "q6_3": boolean | undefined,
    "q6_4": boolean | undefined,
    "q7_1": boolean | undefined,
    "q7_2": boolean | undefined,
    "q7_3": boolean | undefined,
    "q7_4": boolean | undefined,
    "q7_5": boolean | undefined,
    "q7_5_msg": string,
    "q8_1": boolean | undefined,
    "q8_2": boolean | undefined,
    "q8_3": boolean | undefined,
    "q8_4": boolean | undefined,
    "q8_5": boolean | undefined,
    "q8_6": boolean | undefined,
    "q8_7": boolean | undefined,
    "q8_7_msg": string,
    "q9": string,
    "q10": boolean | undefined,
    "q11": string,
    "q12": boolean | undefined,
    "q12_msg": string,
    "q12_1": boolean | undefined,
    "q12_2": boolean | undefined,
    "q12_3": boolean | undefined,
    "q12_4": boolean | undefined,
    "q12_5": boolean | undefined,
    "q12_6": boolean | undefined,
    "q13": string,
    "q14": string,
}


export const defaultHomeFormData: HomeFormData = {
    sign: "",
    q0_1: "",
    q0_2: "",
    q0_3: "",
    q0_4: "",
    q1: 0,
    q10: undefined,
    q11: "",
    q12: undefined,
    q12_1: false,
    q12_2: false,
    q12_3: false,
    q12_4: false,
    q12_5: false,
    q12_6: false,
    q12_msg: "",
    q13: "",
    q14: "",
    q2: false,
    q2_height: 0,
    q2_width: 0,
    q3_1: false,
    q3_2: false,
    q3_3: false,
    q3_4: false,
    q4: "",
    q5: "",
    q6_1: false,
    q6_2: false,
    q6_3: false,
    q6_4: false,
    q7_1: false,
    q7_2: false,
    q7_3: false,
    q7_4: false,
    q7_5: false,
    q7_5_msg: "",
    q8_1: false,
    q8_2: false,
    q8_3: false,
    q8_4: false,
    q8_5: false,
    q8_6: false,
    q8_7: false,
    q8_7_msg: "",
    q9: "",
};
