import React, { useEffect, useState } from "react";
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Modal, Table } from "react-bootstrap";
import GPDataType from "../../models/GPDataType";
import useAPI from "../../hooks/useAPI";
import { GetGPMergeDataResponse } from "../../models/Responses";
import useErrorHandler from "../../hooks/useErrorHandler";
import "../../styles/GPModal.sass";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface GPModalProps {
    onClose: () => void;
    dataType?: GPDataType;
    elderID: string
}

/** 生理量測數據統計窗口 */
const GPModal = (props: GPModalProps) => {
    const initStartDate = new Date();
    const initEndDate = new Date();

    const API = useAPI();
    const [data, setData] = useState<GetGPMergeDataResponse>();
    const errorHandler = useErrorHandler();
    const [dataStartDate, setDataStartDate] = useState(initStartDate);
    const [dataEndDate, setDataEndDate] = useState(initEndDate);
    const [days, setDays] = useState(0);

    async function refresh() {
        if (props.dataType === undefined) return;
        setData(undefined);
        try {
            let endDate = new Date(dataEndDate);
            const startDate = new Date(dataStartDate);

            if (endDate.getTime() > new Date().getTime()) endDate = new Date();

            setData(await API.getGPData(props.elderID, startDate, endDate, props.dataType));
        } catch (err) {
            errorHandler.handle(err);
        }
    }

    async function handleDaysChange(option: number) {
        const startDate = new Date(dataEndDate);
        startDate.setDate(startDate.getDate() - option);
        setDataStartDate(startDate);
        setDays(option);
    }

    useEffect(() => {
        refresh();
    }, [props.dataType, dataStartDate, dataEndDate]);

    function getTable(data: GetGPMergeDataResponse) {
        switch (props.dataType) {
        case GPDataType.SPDP:
            return <Table>
                <thead style={ { textAlign: "center" } }>
                    <tr>
                        <th>日期</th>
                        <th>時間</th>
                        <th>高壓</th>
                        <th>低壓</th>
                    </tr>
                </thead>
                <tbody style={ { textAlign: "center" } }>
                    {data.datas.details.map((d) =>
                        <tr key={ d.id }>
                            <td><p>{d.dt.split(" ")[0]}</p></td>
                            <td><p>{d.dt.split(" ")[1]}</p></td>
                            <td><p>{d.sp}</p></td>
                            <td><p>{d.dp}</p></td>
                        </tr>,
                    )}
                </tbody>
            </Table>;

        case GPDataType.HEART:
            return <Table>
                <thead style={ { textAlign: "center" } }>
                    <tr>
                        <th>日期</th>
                        <th>時間</th>
                        <th>心率</th>
                    </tr>
                </thead>
                <tbody style={ { textAlign: "center" } }>
                    {data.datas.details.map((d) =>
                        <tr key={ d.id }>
                            <td><p>{d.dt.split(" ")[0]}</p></td>
                            <td><p>{d.dt.split(" ")[1]}</p></td>
                            <td><p>{d.heart} 次/分</p></td>
                        </tr>,
                    )}
                </tbody>
            </Table>;
        case GPDataType.BODY:
            return <Table>

                <thead style={ { textAlign: "center" } }>
                    <tr>
                        <th>日期</th>
                        <th>時間</th>
                        <th>體表溫度</th>
                    </tr>
                </thead>
                <tbody style={ { textAlign: "center" } }>
                    {data.datas.details.map((d) =>
                        <tr key={ d.id }>
                            <td><p>{d.dt.split(" ")[0]}</p></td>
                            <td><p>{d.dt.split(" ")[1]}</p></td>
                            <td><p>{d.body} °C</p></td>
                        </tr>,
                    )}
                </tbody>
            </Table>;
        case GPDataType.SLEEP:
            return <Table>
                <thead style={ { textAlign: "center" } }>
                    <tr>
                        <th>日期</th>
                        <th>總睡眠時間</th>
                        <th>淺層睡眠時間</th>
                        <th>深層睡眠時間</th>
                        <th>其他</th>
                    </tr>
                </thead>
                <tbody style={ { textAlign: "center" } }>
                    {data.datas.details.map((d) =>
                        <tr key={ d.id }>
                            <td><p>{d.dt}</p></td>
                            <td><p>{d.sleep_0}</p></td>
                            <td><p>{d.sleep_1}</p></td>
                            <td><p>{d.sleep_2}</p></td>
                            <td><p>{d.sleep_3}</p></td>
                        </tr>,
                    )}
                </tbody>
            </Table>;
        case GPDataType.STEP:
            return <Table>
                <thead style={ { textAlign: "center" } }>
                    <tr>
                        <th>日期</th>
                        <th>時間</th>
                        <th>總步數</th>
                    </tr>
                </thead>
                <tbody style={ { textAlign: "center" } }>
                    {data.datas.details.map((d) => <tr key={ d.id }>
                        <td><p>{d.dt.split(" ")[0]}</p></td>
                        <td><p>{d.dt.split(" ")[1]}</p></td>
                        <td><p>{d.step} 步</p></td>
                    </tr>,
                    )}
                </tbody>
            </Table>;
        }
    }

    function getGraph(data: GetGPMergeDataResponse) {
        switch (props.dataType) {
        case GPDataType.SPDP:
            return <ResponsiveContainer minHeight={ 360 } width="100%">
                <LineChart data={ data.datas.charts }>
                    <Line dataKey="sp" dot={ false } name="高壓" stroke="rgb(231,144,108)" />
                    <Line dataKey="dp" dot={ false } name="低壓" stroke="rgb(57,181,74)" />
                    <XAxis dataKey="dt" name="測量日期" reversed />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                </LineChart></ResponsiveContainer>;
        case GPDataType.HEART:
            return <ResponsiveContainer minHeight={ 360 } width="100%"><LineChart data={ data.datas.charts }>
                <Line dataKey="heart" dot={ false } name="心率" stroke="#6798CF" />
                <XAxis dataKey="dt" name="測量日期" reversed />
                <YAxis />
                <Tooltip />
                <Legend />
            </LineChart>
            </ResponsiveContainer>;
        case GPDataType.BODY:
            return <ResponsiveContainer minHeight={ 360 } width="100%"><LineChart data={ data.datas.charts }>
                <Line dataKey="body" dot={ false } name="體表溫度" stroke="#E88C65" />
                <XAxis dataKey="dt" name="測量日期" reversed />
                <YAxis domain={ ["dataMin-1", "dataMax+1"] } />
                <Tooltip />
                <Legend />
            </LineChart>
            </ResponsiveContainer>;
        case GPDataType.SLEEP:
            return <ResponsiveContainer minHeight={ 360 } width="100%"><LineChart data={ data.datas.charts }>
                <Line dataKey="sleep_0" dot={ false } name="總睡眠時間" stroke="#E88C65" />
                <XAxis dataKey="dt" name="測量日期" reversed />
                <YAxis />
                <Tooltip />
                <Legend />
            </LineChart>
            </ResponsiveContainer>;
        case GPDataType.STEP:
            return <ResponsiveContainer minHeight={ 360 } width="100%"><LineChart data={ data.datas.charts }>
                <Line dataKey="step" dot={ false } name="總步數" stroke="rgb(57,181,74)" />
                <XAxis dataKey="dt" name="測量日期" reversed />
                <YAxis />
                <Tooltip />
                <Legend />
            </LineChart>
            </ResponsiveContainer>;
        }
    }

    return (
        <Modal
            className="gp-modal"
            contentClassName="modal-content"
            dialogClassName="modal-dialog"
            onHide={ props.onClose }
            show={ props.dataType !== undefined }
        >
            <Modal.Header closeButton>
                <h1>{props.dataType}</h1>
            </Modal.Header>
            <Modal.Body>

                <div
                    className="gp-modal-options"
                    style={ {} }
                >
                    <p style={ { marginRight: 16 } }>日期 起</p>
                    <DatePicker
                        dateFormat="yyyy/MM/dd"
                        locale="zh-TW"
                        onChange={ (date: Date) => {
                            setDataStartDate(date);
                        } }
                        selected={ dataStartDate }
                    />
                    <p style={ { marginRight: 16 } }>迄</p>
                    <DatePicker
                        dateFormat="yyyy/MM/dd"
                        locale="zh-TW"
                        onChange={ (date: Date) => {
                            setDataEndDate(date);
                        } }
                        selected={ dataEndDate }
                    />
                    <p style={ { marginLeft: 16 } }>查詢天數</p>
                    <select onChange={ (e) => handleDaysChange(+e.target.value) } value={ days }>
                        <option value={ 0 }>今天</option>
                        <option value={ 7 }>7天</option>
                        <option value={ 14 }>14天</option>
                        <option value={ 30 }>30天</option>
                        <option value={ 60 }>60天</option>
                        <option value={ 90 }>90天</option>
                    </select>
                </div>

                <div
                    className={ "graph" }
                    style={ { display: "flex", justifyContent: "center", width: "100%", overflow: "hidden" } }
                >
                    {data === undefined ? <p>資料讀取中 ...</p> : getGraph(data)}
                </div>
                <div className="list">
                    {data === undefined ? <p>資料載入中 ...</p> : getTable(data)}
                </div>

            </Modal.Body>
        </Modal>
    );
};

export default GPModal;
