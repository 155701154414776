/* eslint-disable max-len */
import React from "react";

const SleepIcon = (props: { color?: string }) => {
    let styleColor: any = props.color;

    switch (styleColor) {
    case "紅燈":
        styleColor = "#FF0000";
        break;

    case "黃燈":
        styleColor = "#FBB03B";
        break;

    case "綠燈":
        styleColor = "#39B54A";
        break;

    case "red":
        styleColor = "#FF0000";
        break;

    case "yellow":
        styleColor = "#FBB03B";
        break;

    case "green":
        styleColor = "#39B54A";
        break;

    default:
        styleColor = "#888888";
        break;
    }

    return <svg className="icon" fill={ styleColor } xmlns="http://www.w3.org/2000/svg">
        <g>
            <path
                d="M53.18,35.88c0-.82,0-1.57,0-2.31a2.19,2.19,0,0,1,2.25-2.4c2.1,0,3,.65,3,2.36,0,5,0,9.95,0,14.93a.81.81,0,0,1-.06.22H53.2V43.5h-47v5.18H0V42.79Q0,31.86,0,20.92a5.25,5.25,0,0,1,.2-1.43,2.65,2.65,0,0,1,2.76-2,2.67,2.67,0,0,1,2.92,2,6,6,0,0,1,.23,1.74c0,4.55,0,9.1,0,13.65v1Z"
            />
            <path
                d="M18.47,34.22V25.06c.24,0,.46-.06.69-.06q12.63,0,25.27,0a5.64,5.64,0,0,1,4.46,2.25,8.7,8.7,0,0,1,1.82,6.43c0,.49-.29.54-.69.54H18.47Z"
            />
            <path
                d="M39.89,10.65l8.6.9-.89,2.92L34.17,13.09c0-1.19,0-2.17,1.14-3,2.45-1.79,4.79-3.74,7.17-5.63l1-.78-8.05-.85c.34-.91.62-1.74,1-2.56A.64.64,0,0,1,36.91,0C41,.4,45,.83,49.1,1.26c-.06,1.16,0,2.2-1.13,3C45.48,6.09,43.13,8.06,40.72,10,40.49,10.14,40.28,10.33,39.89,10.65Z"
            />
            <path className="cls-1" d="M13,34.18a4.82,4.82,0,1,1,.09-9.64A4.82,4.82,0,1,1,13,34.18Z" />
            <path
                d="M22.65,11.07c.06-.71.08-1.33.18-1.93a.7.7,0,0,1,.42-.45c3.08-.5,6.15-1,9.24-1.46a2.38,2.38,0,0,1-.33,2.63c-1.58,1.92-3,3.94-4.7,6.1L34,15c0,.66,0,1.29,0,1.91a.65.65,0,0,1-.42.45c-3.19.51-6.37,1-9.56,1.49a2.64,2.64,0,0,1-.29,0,2.27,2.27,0,0,1,.41-2.53c1.45-1.73,2.75-3.58,4.11-5.38.16-.21.31-.43.53-.75Z"
            />
            <path
                d="M17.43,19.22h4l-.26,1.27H14.57a1,1,0,0,1,.34-1.3c1.17-1,2.31-2,3.63-3.15H14.68c.17-1.37.17-1.37,1.34-1.36H21.1a1.14,1.14,0,0,1-.41,1.49c-1.14.93-2.23,1.91-3.34,2.86Z"
            />
        </g>
    </svg>;
};

export default SleepIcon;
