export const guardName = (status: 0 | 1 | 2) => {
    switch (status) {
    case 0:
        return "關閉";
    case 1:
        return "開啟";
    case 2:
        return "暫時關閉";
    }
};
