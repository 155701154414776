import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import "../../styles/EightModal.sass";
import useAPI from "../../hooks/useAPI";
import { GetEightFacetDataResponse } from "../../models/Responses";
import HoldIcon from "../Icons/HoldIcon";
import NoSmokingIcon from "../Icons/NoSmokingIcon";
import AngryIcon from "../Icons/AngryIcon";
import SleepIcon from "../Icons/SleepIcon";
import EyeIcon from "../Icons/EyeIcon";
import HoldCellphoneIcon from "../Icons/HoldCellphone";
import RunningIcon from "../Icons/RunningIcon";
import LungIcon from "../Icons/LungIcon";
import WeightIcon from "../Icons/WeightIcon";
import Bar from "../Bar";

interface EightModalProps {
    onClose: () => void;
    eightId: string | undefined
}

const EightFacet = [
    { name: "健康體位", le_type: "健康體位", icon: WeightIcon },
    { name: "肺結核防治", le_type: "肺結核", icon: LungIcon },
    { name: "身體活動", le_type: "身體活動", icon: RunningIcon },
    { name: "網路成癮", le_type: "網路成癮", icon: HoldCellphoneIcon },
    { name: "視力健康", le_type: "視力健康", icon: EyeIcon },
    { name: "睡眠健康", le_type: "睡眠健康", icon: SleepIcon },
    { name: "情緒健康", le_type: "情緒健康", icon: AngryIcon },
    { name: "遠離菸害", le_type: "遠離菸害", icon: NoSmokingIcon },
    { name: "握力健康", le_type: "握力健康", icon: HoldIcon },
];

interface LeData {
    "le_type": "健康體位" | "肺結核" | "身體活動" | "網路成癮" | "視力健康" | "睡眠健康" | "情緒健康" | "遠離菸害",
    "le_type_i": number,
    "know_how": string,
    "le_datas": {
        "le_title": string,
        "le_result": "綠燈" | "黃燈" | "紅燈",
        "le_msg": string
    }[]
}

const EightModal = (props: EightModalProps) => {
    const API = useAPI();

    const { eightId } = props;

    const [eightFacetData, setEightFacetData] = useState<GetEightFacetDataResponse | undefined>();
    const [selectedEightData, setSelectedEightData] = useState<LeData>();

    const refresh = async () => {
        if (!eightId) return;

        const res = await API.getEightFacetData(eightId);

        setEightFacetData(res);

        if (res.datas[0] && res.datas[0].le_datas[0]) {
            setSelectedEightData(res.datas[0]);
        }
    };

    function handleSelect(type: string) {
        if (eightFacetData &&
            eightFacetData.datas.filter((f) => f.le_type === type)[0]) {
            setSelectedEightData(eightFacetData.datas.filter((f) => f.le_type === type)[0]);
            return;
        }

        setSelectedEightData(undefined);
    }

    useEffect(() => {
        refresh();
    }, [props.eightId]);


    return (
        <Modal
            className="eight-modal"
            contentClassName="modal-content"
            dialogClassName="modal-dialog"
            onHide={ props.onClose }
            show={ props.eightId !== undefined }
        >
            <Modal.Header closeButton>
                <h1>八大面向測量數據</h1>
            </Modal.Header>
            <Modal.Body>
                {eightFacetData === undefined ? <p>載入資料中</p> : <>
                    <Row style={ { width: "100%" } }>
                        {EightFacet.map((ef) =>
                            <Col
                                className="eight-option"
                                key={ ef.name }
                                onClick={ () => handleSelect(ef.le_type) }
                                style={ { textAlign: "center" } }
                            >
                                <ef.icon color="gray" />
                                <p>{ef.name}</p>
                            </Col>)}
                    </Row></>}
                <div className="eight-info">

                    <a href={ selectedEightData ? selectedEightData.know_how : "" } rel="noreferrer" target="_blank">
                        <button
                            className="ghost-button know-how"
                            style={ { float: "right" } }
                        >
                            小知識
                        </button>
                    </a>

                    {selectedEightData !== undefined ? selectedEightData.le_datas.map((sd, i) =>
                        <Row key={ i }>
                            <Col xs={ 1 }><Bar color={ sd.le_result } /></Col>
                            <Col className="detail" xs={ 11 }>
                                <h2>{sd.le_title}</h2>
                                <p>{sd.le_msg}</p>
                            </Col>
                        </Row>) : <p>無資料</p>}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default EightModal;
