import React, { useEffect, useState } from "react";
import useAPI from "../../hooks/useAPI";
import "../../styles/ElderListPage.sass";
import { useHistory } from "react-router";
import { Manager } from "../../models/Responses";
import useErrorHandler from "../../hooks/useErrorHandler";
import { Col, Row } from "react-bootstrap";
import TitleBar from "../../components/TitleBar";
import { guardName } from "../../utils/GuardName";

export default function ManagerListPage() {
    const API = useAPI();
    const history = useHistory();
    const errorHandler = useErrorHandler();

    const [data, setData] = useState<Manager[]>([]);
    const [keyword, setKeyword] = useState("");

    async function refresh() {
        try {
            const data = await API.getManagerList();
            setData(data);
        } catch (err) {
            errorHandler.handle(err);
        }
    }

    useEffect(() => {
        refresh();
    }, []);

    const showManagers = data.filter((m) => m.h_name.includes(keyword));

    return <div className="manager-list sc1qr-list-ct">
        <TitleBar title="人員管理" />

        <div className="row-search">
            <input onChange={ (e) => setKeyword(e.target.value) } placeholder="查詢姓名" value={ keyword } />
            <button
                className="primary-button"
                onClick={ () => {
                    window.location.href = "/manager/create";
                } }
            >新增人員
            </button>
        </div>

        <Row style={ { margin: 0 } }>
            {showManagers === undefined ? <p className="info">載入資料中 ...</p> :
                <>{showManagers.length === 0 ?
                    <p className="info">找不到姓名包含 {keyword} 的人員</p> : <>{showManagers.map((m) =>
                        <Col
                            key={ m.h_name }
                            md={ 6 }
                            xl={ 4 }
                        >
                            <div className="elder-card sc-Pcard">
                                <Row className="r1" noGutters>
                                    <Col className="c1" xs={ 9 }>
                                        <img
                                            alt=""
                                            src={ m.h_sex === 0 ? "/assets/female.svg" : "/assets/male.svg" }
                                        />
                                        <p>{m.h_name}</p></Col>
                                    <Col className="c2" xs={ 3 }>
                                        <button
                                            className="primary-button"
                                            onClick={ () => history.push("/manager/" + m.h_id + "/edit") }
                                        >進入管理
                                        </button>
                                    </Col>
                                </Row>

                                <Row className="r2">
                                    <Col className="c1" xs={ 4 }>
                                        <p className="tel">{m.h_tel}</p>
                                        <p className="mobile">{m.h_mobile}</p>
                                    </Col>
                                    <Col className="c2" xs={ 4 }>
                                        <p>身分</p>
                                    </Col>
                                    <Col className="c3" xs={ 4 }>
                                        <p>{m.h_type === 0 ? "健康經理人" : "健康管理員"}</p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>)}
                    </>}
                </>}
        </Row>
    </div>;
}
