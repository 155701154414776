import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import "../../styles/ElderEditPage.sass";
import useAPI from "../../hooks/useAPI";
import "../../styles/AddContactModal.sass";
import TitleBar from "../../components/TitleBar";
import ElderForm from "../../components/ElderForm";
import ElderFormContent, { emptyElderFormContent } from "../../models/form/ElderFormContent";

const ElderEditPage = () => {
    const API = useAPI();
    const history = useHistory();
    const { elderID } = useParams<{ elderID: string }>();
    const [formData, setFormData] = useState<ElderFormContent>(emptyElderFormContent);
    const [isLoading, setLoading] = useState(false);

    async function refresh() {
        try {
            setLoading(true);
            const res = await API.getElder(elderID);
            setFormData({
                ...res,
                nhi1: res.e_nhi_card?.substr(0, 4),
                nhi2: res.e_nhi_card?.substr(4, 4),
                nhi3: res.e_nhi_card?.substr(8, 4),
            });
            setLoading(false);
        } catch (err) {
            alert(err);
        }
    }

    async function uploadData() {
        try {
            if (formData.is_chg === undefined) {
                alert("請選擇是否轉介");
                return;
            }
            const req = { ...formData, e_nhi_card: formData.nhi1 + formData.nhi2 + formData.nhi3 };
            await API.updateElderData(req);
            alert("更新成功！");
            refresh();
        } catch (err) {
            alert(err);
        }
    }

    useEffect(() => {
        refresh();
    }, []);

    return <div className="elder-edit s-content">
        <TitleBar title="編輯長者資料" />

        <ElderForm formData={ formData } isLoading={ isLoading } setFormData={ setFormData } />

        <div style={ { textAlign: "center" } }>
            <button className="ghost-button" onClick={ () => history.push("/elder/" + elderID) }>取消</button>
            <button className="ghost-button" onClick={ uploadData }>儲存</button>
        </div>

    </div>;
};

export default ElderEditPage;
