import useToken from "./useToken";

const useErrorHandler = () => {
    const token = useToken();

    const handle = (err: any) => {
        if (err === undefined) {
            alert("發生意外錯誤！");
            return;
        }

        if (err.toString !== undefined) {
            err = err.toString();
        }

        switch (err) {
        case "AUTH_FAILED":
            token.reset();
            return;
        default:
            alert(err);
        }
    };

    return { handle };
};

export default useErrorHandler;
