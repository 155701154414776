import { Route, Switch } from "react-router-dom";
import ElderListPage from "../pages/Elder/ElderListPage";
import ElderDetailPage from "../pages/Elder/ElderDetailPage";
import React from "react";
import ElderEditPage from "../pages/Elder/ElderEditPage";
import ElderCreatePage from "../pages/Elder/ElderCreatePage";

const ElderRouter = () => {
    return <Switch>
        <Route component={ ElderCreatePage } path="/elder/create" />
        <Route component={ ElderEditPage } path="/elder/:elderID/edit" />
        <Route component={ ElderDetailPage } path="/elder/:elderID" />
        <Route component={ ElderListPage } path="/elder" />
    </Switch>;
};

export default ElderRouter;
