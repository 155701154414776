import { Route, Switch } from "react-router-dom";
import React from "react";
import PostListPage from "../pages/Education/PostListPage";
import EditPostPage from "../pages/Education/EditPostPage";

const EducationRouter = () => {
    return <Switch>
        <Route component={ EditPostPage } path="/education/:postId" />
        <Route component={ PostListPage } path="/education" />
    </Switch>;
};

export default EducationRouter;
