import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const noBackPages = ["/elder"];

const TitleBar = (props: { title: string, isForm?: boolean }) => {
    const history = useHistory();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setScreenWidth(window.innerWidth);
        });
    }, []);

    if (screenWidth <= 832) {
        return <Row className={"title-bar" +(props.isForm ? " elder-title" : "")}>
            {!noBackPages.includes(history.location.pathname) ? <Col xs={ 2 }>
                <img
                    alt=""
                    onClick={ () => history.goBack() }
                    src={ "/assets/back.svg" }
                    style={ { width: 35, height: 35 } }
                />
            </Col> : <div />}

            <Col xs={ 10 }>
                <h1>{props.title}</h1>
            </Col>
        </Row>;
    }

    return <h1>{props.title}</h1>;
};

export default TitleBar;
