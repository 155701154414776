import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import EducationPost from "../../models/EducationPost";
import TitleBar from "../../components/TitleBar";
import useAPI from "../../hooks/useAPI";

export default function PostListPage() {
    const API = useAPI();
    const [posts, setPosts] = useState<EducationPost[]>([]);
    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        API.getEducationList().then((res) => setPosts(res));
    }, []);

    const showPost = posts.filter((p) => p.title.includes(keyword));

    return <div className="edu-list sc1qr-list-ct">
        <TitleBar title="衛教天地" />

        <div className="row-search">
            <input onChange={ (e) => setKeyword(e.target.value) } placeholder="查詢" value={ keyword } />
            <button
                className="primary-button"
                onClick={ () => {
                    window.location.href = "/education/create";
                } }
            >新增文章
            </button>
        </div>
        <Row>
            {keyword === "" ? posts.filter((p) => p.is_top).map((p) =>
                <Col className="top-post edu-post" key={ p.id } xs={ 12 }>
                    <PostCardContent post={ p } />
                </Col>,
            ) : <div />}
            {keyword === "" ? posts.filter((p) => !p.is_top).map((p) =>
                <Col className="normal-post edu-post" key={ p.id } xs={ 6 }>
                    <PostCardContent post={ p } />
                </Col>,
            ) : showPost.map((p) =>
                <Col className="normal-post edu-post" key={ p.id } xs={ 6 }>
                    <PostCardContent post={ p } />
                </Col>,
            )}
        </Row>
    </div>;
}

function PostCardContent(props: { post: EducationPost }) {
    return <div
        onClick={ () => {
            window.location.href = "/education/" + props.post.id;
        } }
    >
        <Row>
            <Col xs={ 6 }><img alt="" src={ props.post.img } style={ { width: "100%" } } /></Col>
            <Col xs={ 6 }>
                <h1>{props.post.title}</h1>
                <p>{props.post.content}</p>
            </Col>
        </Row>
    </div>;
}
