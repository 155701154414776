import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import TitleBar from "../../components/TitleBar";
import { CallFormData, defaultCallFormData } from "../../models/form/CallForm";
import useAPI from "../../hooks/useAPI";
import useErrorHandler from "../../hooks/useErrorHandler";

/** 電訪問卷頁面 */
export default function CallFormPage() {
    const API = useAPI();
    const errorHandler = useErrorHandler();
    const { id } = useParams<{ id: string }>();
    const isCreating = id === "create";
    const elderId = window.location.search.split("elderId=")[1];

    const [formData, setFormData] = useState<CallFormData>(defaultCallFormData);

    async function submit() {
        if (isCreating) {
            try {
                await API.createCallReport({ ...formData }, elderId);
                goBack();
            } catch (err) {
                errorHandler.handle(err);
            }
        } else {
            try {
                await API.updateCallReport(formData, id);
                goBack();
            } catch (err) {
                errorHandler.handle(err);
            }
        }
    }

    function goBack() {
        window.location.href = "/elder/" + (elderId ? elderId : "");
    }

    useEffect(() => {
        if (!isCreating) {
            try {
                API.getCallVisitData(id).then((res) => setFormData({  ...res }));
            } catch (err) {
                errorHandler.handle(err);
            }
        } else {
            API.getElder(elderId).then(res => setFormData({ ...formData, svc_e_name: res.e_name }))
        }
    }, [id]);

    return <div className="elder-detail elder-report-ct report-call">
        <TitleBar title={ isCreating ? "新增電訪紀錄" : "電訪紀錄" } isForm />
        <div>
            <table>
                <tr>
                    <td className="sc1qr-label">服務日期:</td>
                    <td><input
                        readOnly={!isCreating}
                        onChange={ (e) => setFormData({ ...formData, svc_date: e.target.value }) }
                        value={ formData.svc_date } /></td>
                </tr>
                <tr>
                    <td className="sc1qr-label">服務對象:</td>
                    <input
                        readOnly={!isCreating}
                        onChange={ (e) => setFormData({ ...formData, svc_e_name: e.target.value }) }
                        value={ formData.svc_e_name } />
                </tr>
                <tr>
                    <td className="sc1qr-label">用藥狀況:</td>
                    <input onChange={ (e) => setFormData({ ...formData, q1: e.target.value }) } value={ formData.q1 } />
                </tr>
                <tr>
                    <td className="sc1qr-label">近期是否有跌倒或就醫:</td>
                    <input onChange={ (e) => setFormData({ ...formData, q2: e.target.value }) } value={ formData.q2 } />
                </tr>
                <tr>
                    <td className="sc1qr-label">用餐狀況:</td>
                    <input onChange={ (e) => setFormData({ ...formData, q3: e.target.value }) } value={ formData.q3 } />
                </tr>
                <tr>
                    <td className="sc1qr-label">生活型態概況:</td>
                    <input onChange={ (e) => setFormData({ ...formData, q4: e.target.value }) } value={ formData.q4 } />
                </tr>
                <tr>
                    <td className="sc1qr-label">長照服務品質追蹤:</td>
                    <input onChange={ (e) => setFormData({ ...formData, q5: e.target.value }) } value={ formData.q5 } />
                </tr>
                <tr>
                    <td className="sc1qr-label">特殊事件:</td>
                    <input onChange={ (e) => setFormData({ ...formData, q6: e.target.value }) } value={ formData.q6 } />
                </tr>
                <tr>
                    <td className="sc1qr-label">下次追蹤事項:</td>
                    <input onChange={ (e) => setFormData({ ...formData, q7: e.target.value }) } value={ formData.q7 } />
                </tr>
            </table>
        </div>

        <button className="ghost-button" onClick={ goBack }>取消</button>
        <button className="ghost-button" onClick={ submit }>送出</button>
    </div>;
}
