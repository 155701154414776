import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import "../../styles/ElderEditPage.sass";
import useAPI from "../../hooks/useAPI";
import "../../styles/AddContactModal.sass";
import TitleBar from "../../components/TitleBar";
import { emptyManagerData, Manager } from "../../models/Responses";
import ManagerForm from "../../components/ManagerForm";

const ManagerEditPage = () => {
    const API = useAPI();
    const history = useHistory();
    const { managerId } = useParams<{ managerId: string }>();
    const [formData, setFormData] = useState<Manager>(emptyManagerData);
    const [isLoading, setLoading] = useState(false);

    async function refresh() {
        try {
            setLoading(true);
            const res = await API.getManagerData(managerId);
            setFormData({ ...res });
            setLoading(false);
        } catch (err) {
            alert(err);
        }
    }

    async function uploadData() {
        try {
            await API.updateManagerData(formData);
            alert("更新成功！");
            refresh();
        } catch (err) {
            alert(err);
        }
    }

    useEffect(() => {
        refresh();
    }, []);

    return <div className="manager-edit elder-edit s-content">
        <TitleBar title="編輯人員資料" />

        <ManagerForm formData={ formData } isLoading={ isLoading } setFormData={ setFormData } />

        <div style={ { textAlign: "center" } }>
            <button className="ghost-button" onClick={ () => history.push("/manager") }>取消</button>
            <button className="ghost-button" onClick={ uploadData }>儲存</button>
        </div>

    </div>;
};

export default ManagerEditPage;
