import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Row } from "react-bootstrap";
import { Cell, Pie, PieChart, ResponsiveContainer, } from "recharts";
import TitleBar from "../components/TitleBar";
import useAPI from "../hooks/useAPI";
import useErrorHandler from "../hooks/useErrorHandler";

const COLORS = ["rgb(111,164,96)", "rgb(207,141,138)"];

const StatisticPage = () => {
    const API = useAPI();
    const errorHandler = useErrorHandler();

    const initStartDate = new Date();
    initStartDate.setDate(initStartDate.getDate() - 7);

    const initEndDate = new Date();

    const [dataStartDate, setDataStartDate] = useState(initStartDate);
    const [dataEndDate, setDataEndDate] = useState(initEndDate);

    const [change, setChange] = useState(0);
    const [nonChange, setNonChange] = useState(0);

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        refresh();
    }, [dataStartDate, dataEndDate]);

    async function refresh() {
        setLoading(true);
        try {
            const res = await API.getChartChange(dataStartDate, dataEndDate);
            setChange(res.change);
            setNonChange(res.non_change);
        } catch (err) {
            errorHandler.handle(err);
        }
        setLoading(false);
    }

    const data = [
        { name: "已轉介", value: change },
        { name: "尚未轉介", value: nonChange },
    ];

    return (
        <div className="statistic-page sc1qr-list-ct">
            <TitleBar title="統計數據" />
            <Row>
                <p style={{ marginRight: 16 }}>日期</p>
                <DatePicker
                    dateFormat="yyyy/MM/dd"
                    locale="zh-TW"
                    onChange={(date: Date) => {
                        setDataStartDate(date);
                    }}
                    selected={dataStartDate}
                />
                <p style={{ marginRight: 16 }}> - </p>
                <DatePicker
                    dateFormat="yyyy/MM/dd"
                    locale="zh-TW"
                    onChange={(date: Date) => {
                        setDataEndDate(date);
                    }}
                    selected={dataEndDate}
                />
                <p>（預設7天）</p>
            </Row>

            <Row>
                <p>總長者數</p>
                <input disabled value={change + nonChange} />
                <p>轉介數</p>
                <input disabled value={change} />
                <p>轉介比例</p>
                <input disabled value={`${(change / (nonChange + change)) * 100}%`} />
            </Row>

            <ResponsiveContainer minHeight={360} width="100%">
                {!isLoading ?
                    (
                        <PieChart>
                            <Pie
                                data={data}
                                dataKey="value"
                                innerRadius={60}
                                outerRadius={120}
                                paddingAngle={5}
                            >
                                {data.map((entry, index) => (
                                    <Cell
                                        fill={COLORS[index % COLORS.length]}
                                        key={`cell-${index}`}
                                    >
                                        {entry.name}
                                    </Cell>
                                ))}

                            </Pie>
                        </PieChart>
                    ) : <div />}
            </ResponsiveContainer>
            <Row>
                <div
                    style={{ backgroundColor: "rgb(207,141,138)", width: 40, height: 8, borderRadius: 16, }}
                />
                <p>未轉介</p>
                <div
                    style={{ backgroundColor: "rgb(111,164,96)", width: 40, height: 8, borderRadius: 16, }}
                />
                <p>已轉介</p>
            </Row>
        </div>
    );
};

export default StatisticPage;
