import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import ReportDataType from "../../models/ReportDataType";
import useAPI from "../../hooks/useAPI";
import { GetReportMergeDataResponse } from "../../models/Responses";
import useErrorHandler from "../../hooks/useErrorHandler";
import Bar from "../Bar";

interface ReportModalProps {
    onClose: () => void;
    dataType?: ReportDataType
    elderID: string
}

const ReportModal = (props: ReportModalProps) => {
    const API = useAPI();
    const [data, setData] = useState<GetReportMergeDataResponse<any>>();
    const errorHandler = useErrorHandler();

    async function refresh() {
        if (props.dataType === undefined) return;
        setData(undefined);
        try {
            setData(await API.getReportData(props.elderID, props.dataType));
        } catch (err) {
            errorHandler.handle(err);
        }
    }

    useEffect(() => {
        refresh();
    }, [props.dataType]);

    const getModalTitle = () => {
        switch (props.dataType) {
        case "GDS":
            return "長者憂鬱症篩檢調查問卷";
        case "SOF":
            return "長者衰弱評估調查問卷";
        case "NEED":
            return "長照需求初篩表";
        case "CALL":
            return "電訪紀錄";
        case "HOME":
            return "家訪紀錄";
        }
    };

    return (
        <Modal
            contentClassName="modal-content"
            onHide={ props.onClose }
            show={ props.dataType !== undefined }
            size="lg"
        >
            <Modal.Header closeButton>
                <h1>{getModalTitle()}</h1>
            </Modal.Header>
            <Modal.Body>
                {data === undefined ? <p>資料載入中 ...</p> :
                    <Table>
                        <thead style={ { textAlign: "center" } }>
                            <tr>
                                {props.dataType !== "GDS" && props.dataType !== "SOF" ? <></> :
                                    <>
                                        <th>評估結果</th>
                                        <th>評估日期</th>
                                    </>
                                }
                                <th>建立日期</th>
                                <th>建立人</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody style={ { textAlign: "center" } }>
                            {data.datas.map((d) =>
                                <tr key={ d.id }>
                                    {props.dataType !== "GDS" && props.dataType !== "SOF" ? <></> :
                                        <>
                                            <td><Bar color={ d.ev_light } /></td>
                                            <td><p>{d.ev_date}</p></td>
                                        </>
                                    }
                                    <td><p>{d.c_date}</p></td>
                                    <td><p>{d.c_name}</p></td>
                                    <td>
                                        <button
                                            className="ghost-button"
                                            onClick={ () => {
                                                // eslint-disable-next-line max-len
                                                window.location.href = "/report/" + props.dataType + "/" + d.id + "/?elderId=" + props.elderID;
                                            } }
                                        >檢視
                                        </button>
                                    </td>
                                </tr>,
                            )}
                        </tbody>
                    </Table>}
                <Modal.Footer style={ { textAlign: "center" } }>
                    <button
                        className="ghost-button"
                        onClick={ () => {
                            window.location.href = "/report/" + props.dataType + "/create?elderId=" + props.elderID;
                        } }
                    >新增
                    </button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    );
};

export default ReportModal;
