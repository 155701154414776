import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../styles/Sidenav.sass";
import useToken from "../hooks/useToken";
import { Col, Row } from "react-bootstrap";

const SideNav = () => {
    const [width, setWidth] = useState<number | undefined>(300);

    const history = useHistory();
    const token = useToken();

    const updateDimensions = () => {
        setWidth(undefined);
    };

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setScreenWidth(window.innerWidth);
        });
    }, []);

    const listItems = [
        { key: "track", text: "追蹤清單", onClick: () => history.push("/track"), icon: "/assets/track.svg" },
        { key: "elder", text: "長者管理", onClick: () => history.push("/elder"), icon: "/assets/elder.svg" },
        { key: "manager", text: "人員管理", onClick: () => history.push("/manager"), icon: "/assets/manage.svg" },
        { key: "education", text: "衛教天地", onClick: () => history.push("/education"), icon: "/assets/education.svg" },
        { key: "statistic", text: "統計數據", onClick: () => history.push("/statistic"), icon: "/assets/statistic.svg" },
        { key: "logout", text: "登出", onClick: () => token.reset(), icon: "/assets/track.svg" },
    ];

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        updateDimensions();
    }, []);

    const current = history.location.pathname.split("/")[1];

    if (screenWidth <= 832) {
        return <div className="bottom-nav s-bottom-nav">
            <Row noGutters>
                {listItems.map((item) => (
                    <Col
                        className={ current === item.key ? "nav-item active" : "nav-item" }
                        key={ item.key }
                        onClick={ item.onClick }
                    >
                        <img alt="" src={ item.icon } style={ { fill: "white", width: 32 } } />
                        <p>{item.text}</p>
                    </Col>
                ))}
            </Row>
        </div>;
    }

    return (
        <div
            ref={ (ref) => {
                if (ref && width === undefined) setWidth(ref.clientWidth);
            } }
        >
            <div className="sidenav" style={ { width: width } }>
                {listItems.map((item) => (
                    <div
                        className={ current === item.key ? "sidenav-item active" : "sidenav-item" }
                        key={ item.key }
                        onClick={ item.onClick }
                    >
                        <img alt="" src={ item.icon } style={ { fill: "white", width: 32 } } />
                        <p>{item.text}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SideNav;
