import { apiUrl } from "../config";
import useToken from "./useToken";
import {
    GetAppDatasResponse,
    GetCallVisitDataResponse,
    GetCallVisitDatasResponse,
    GetClanListResponse,
    GetEightFacetDataListResponse,
    GetEightFacetDataResponse,
    GetElderResponse,
    GetEldersResponse,
    GetGPMergeDataResponse,
    GetHomeVisitDataResponse,
    GetHomeVisitDatasResponse,
    GetManagerListResponse,
    GetManagerResponse,
    GetReportMergeDataResponse,
    GetTrackListResponse,
    LoginResponse,
    Manager,
} from "../models/Responses";
import GPDataType from "../models/GPDataType";
import ReportDataType from "../models/ReportDataType";
import { GDSFormData, GetGDSDataListResponse, GetGDSDataResponse } from "../models/form/GDSForm";
import { GetSOFDataListResponse, SOFFormData } from "../models/form/SOFForm";
import { GetScreeningDataListResponse, ScreeningFormData } from "../models/form/ScreeningForm";
import { CallFormData } from "../models/form/CallForm";
import { HomeFormData } from "../models/form/HomeForm";
import EducationPost from "../models/EducationPost";
import draftToHtml from "draftjs-to-html";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import { convertFromApiDate, convertToApiDate } from "../utils/apiDate";

const useAPI = () => {
    const token = useToken();

    const authedFetch = (url: string, method: "GET" | "POST" | "PUT" | "DELETE", body?: string | URLSearchParams) => {
        return fetch(apiUrl + url, {
            method: method,
            body: body,
            mode: "cors",
            headers: {
                "Authorization": "basic " + (token.get() ? token.get() : "empty"),
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    };

    const authedUploadImage = (url: string, file: File) => {
        const req = new FormData();
        req.append("image1", file);

        return fetch(apiUrl + url, {
            method: "POST",
            body: req,
            mode: "cors",
            headers: { "Authorization": "basic " + (token.get() ? token.get() : "empty") },
        });
    };

    return {
        login: (id: string, pwd: string) => {
            return new Promise<void>((resolve, reject) => {
                const req = new URLSearchParams({
                    "id": id,
                    "pwd": pwd,
                    "app_ver": "1.0",
                    "os_ver": "1.0",
                });

                authedFetch("/Account/WebLogin", "POST", req)
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        const resData: LoginResponse = res;
                        if (resData.is_ok) token.update(resData.ticket);
                        else reject(resData.err_msg);
                    })
                    .catch((err) => reject(err));
            });
        },

        getElders: () => {
            return new Promise<GetEldersResponse>((resolve, reject) => {
                authedFetch("/Elder/GetList", "POST")
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        const resData: GetEldersResponse = res;
                        if (resData.is_ok) resolve(resData);
                        else reject(resData.err_msg);
                    })
                    .catch((err) => reject(err));
            },
            );
        },

        getElder: (elderID: string) => {
            return new Promise<GetElderResponse>((resolve, reject) => {
                const req = new URLSearchParams({ "e_id": elderID });

                authedFetch("/Elder/Get", "POST", req)
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        const resData: GetElderResponse = res;
                        if (resData.is_ok) {
                            resData.chg_date = new Date(+(resData.chg_date + "000"));
                            resolve(resData);
                        } else reject(resData.err_msg);
                    })
                    .catch((err) => reject(err));
            });
        },

        getClanList: async () => {
            const res = await authedFetch("/Publish/Group/Get", "POST");
            const parsedRes: GetClanListResponse = await res.json();
            return parsedRes;
        },

        /** 家訪紀錄-查詢清單 */
        getHomeVisitList: (elderID: string) => {
            const req = new URLSearchParams({ "e_id": elderID });

            return new Promise<GetHomeVisitDatasResponse>((resolve, reject) => {
                authedFetch("/Report/Home/GetList", "POST", req)
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        const resData: GetHomeVisitDatasResponse = res;
                        if (resData.is_ok) resolve(resData);
                        else reject(resData.err_msg);
                    })
                    .catch((err) => reject(err));
            });
        },

        /** 家訪紀錄-查詢 */
        getHomeVisitData: (dataID: string) => {
            const req = new URLSearchParams({ "id": dataID });

            return new Promise<GetHomeVisitDataResponse>((resolve, reject) => {
                authedFetch("/Report/Home/Get", "POST", req)
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        const resData: GetHomeVisitDataResponse = res;
                        if (resData.is_ok) resolve(resData);
                        else reject(resData.err_msg);
                    })
                    .catch((err) => reject(err));
            });
        },

        /** 電訪紀錄-查詢清單 */
        getCallVisitList: (elderID: string) => {
            const req = new URLSearchParams({ "e_id": elderID });

            return new Promise<GetCallVisitDatasResponse>((resolve, reject) => {
                authedFetch("/Report/Call/GetList", "POST", req)
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        const resData: GetCallVisitDatasResponse = res;
                        if (resData.is_ok) resolve(resData);
                        else reject(resData.err_msg);
                    })
                    .catch((err) => reject(err));
            });
        },

        /** 電訪紀錄-查詢 */
        getCallVisitData: (dataID: string) => {
            const req = new URLSearchParams({ "id": dataID });

            return new Promise<GetCallVisitDataResponse>((resolve, reject) => {
                authedFetch("/Report/Call/Get", "POST", req)
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        const resData: GetCallVisitDataResponse = res;
                        if (resData.is_ok) resolve(resData);
                        else reject(resData.err_msg);
                    })
                    .catch((err) => reject(err));
            });
        },

        /** 長者管理-長照需求初篩表取得清單 */
        getScreeningList: (elderID: string) => {
            const req = new URLSearchParams({ "e_id": elderID });

            return new Promise<GetScreeningDataListResponse>((resolve, reject) => {
                authedFetch("/Report/Screening/GetList", "POST", req)
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        const resData: GetScreeningDataListResponse = res;
                        if (resData.is_ok) resolve(resData);
                        else reject(resData.err_msg);
                    })
                    .catch((err) => reject(err));
            });
        },

        /** 長者管理-長照需求初篩表查詢 */
        getScreeningData: (dataID: string) => {
            const req = new URLSearchParams({ "id": dataID });

            return new Promise<ScreeningFormData>((resolve, reject) => {
                authedFetch("/Report/Screening/Get", "POST", req)
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        if (!res.is_ok) reject(res.err_msg);
                        const resData: ScreeningFormData = res;
                        resData.q1_birthday = new Date(resData.q1_birthday);
                        resData.q2_birthday = new Date(resData.q2_birthday);
                        resData.q1_birthday.setFullYear(1911 + resData.q1_birthday.getFullYear());
                        resData.q2_birthday.setFullYear(1911 + resData.q2_birthday.getFullYear());
                        resolve(resData);
                    })
                    .catch((err) => reject(err));
            });
        },

        getSOFData: (dataID: string) => {
            const req = new URLSearchParams({ "id": dataID });

            return new Promise<SOFFormData>((resolve, reject) => {
                authedFetch("/Report/SOF/Get", "POST", req)
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        if (!res.is_ok) reject(res.err_msg);
                        resolve(res);
                    })
                    .catch((err) => reject(err));
            });
        },

        getScreeningBasicData: (elderId: string) => {
            const req = new URLSearchParams({ "e_id": elderId });
            return new Promise<ScreeningFormData>((resolve, reject) => {
                authedFetch("/Report/Screening/GetBasic", "POST", req)
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        if (!res.is_ok) reject(res.err_msg);
                        const resData: ScreeningFormData = res;
                        resData.q1_birthday = new Date();
                        resData.q2_birthday = new Date();
                        resolve(resData);
                    })
                    .catch((err) => reject(err));
            });
        },
        /** 長者管理-八大面向檢測取得清單 */
        getEightFacetList: (elderID: string) => {
            const req = new URLSearchParams({ "e_id": elderID });

            return new Promise<GetEightFacetDataListResponse>((resolve, reject) => {
                authedFetch("/Eight/GetList", "POST", req)
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        const resData: GetEightFacetDataListResponse = res;
                        if (resData.is_ok) resolve(resData);
                        else reject(resData.err_msg);
                    })
                    .catch((err) => reject(err));
            });
        },

        /** 長者管理-八大面向檢測內容 */
        getEightFacetData: (dataID: string) => {
            const req = new URLSearchParams({ "id": dataID });

            return new Promise<GetEightFacetDataResponse>((resolve, reject) => {
                authedFetch("/Eight/Get", "POST", req)
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        const resData: GetEightFacetDataResponse = res;
                        if (resData.is_ok) resolve(resData);
                        else reject(resData.err_msg);
                    })
                    .catch((err) => reject(err));
            });
        },

        /** 長者管理-長者衰弱評估調查問卷取得清單 */
        getSOFDataList: (elderID: string) => {
            const req = new URLSearchParams({ "e_id": elderID });

            return new Promise<GetSOFDataListResponse>((resolve, reject) => {
                authedFetch("/Report/SOF/GetList", "POST", req)
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        const resData: GetSOFDataListResponse = res;
                        if (resData.is_ok) resolve(resData);
                        else reject(resData.err_msg);
                    })
                    .catch((err) => reject(err));
            });
        },

        /** 長者管理-長者憂鬱症篩檢調查問卷取得清單 */
        getGDSDataList: (elderID: string) => {
            const req = new URLSearchParams({ "e_id": elderID });

            return new Promise<GetGDSDataListResponse>((resolve, reject) => {
                authedFetch("/Report/GDS/GetList", "POST", req)
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        const resData: GetGDSDataListResponse = res;
                        if (resData.is_ok) resolve(resData);
                        else reject(resData.err_msg);
                    })
                    .catch((err) => reject(err));
            });
        },

        /** 長者管理-長者憂鬱症篩檢調查問卷查詢 */
        getGDSData: (dataID: string) => {
            const req = new URLSearchParams({ "id": dataID });

            return new Promise<GetGDSDataResponse>((resolve, reject) => {
                authedFetch("/Report/GDS/Get", "POST", req)
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        const resData: GetGDSDataResponse = res;
                        if (resData.is_ok) resolve(resData);
                        else reject(resData.err_msg);
                    })
                    .catch((err) => reject(err));
            });
        },


        getAppDatas: (elderID: string) => {
            const req = new URLSearchParams({ "e_id": elderID });

            return new Promise<GetAppDatasResponse>((resolve, reject) => {
                authedFetch("/App/GetAdmin", "POST", req)
                    .then((res) => {
                        if (res.status === 401) reject("AUTH_FAILED");
                        return res.json();
                    })
                    .then((res) => {
                        const resData: GetAppDatasResponse = res;
                        if (resData.is_ok) resolve(resData);
                        else reject(resData.err_msg);
                    })
                    .catch((err) => reject(err));
            });
        },

        getGPData: async (elderID: string, start: Date, end: Date, type: GPDataType) => {
            let apiType: string = type;
            if (apiType === "體表溫度") apiType = "體溫";

            const req = new URLSearchParams({
                "e_id": elderID,
                "s_date": (start.getFullYear() - 1911) + "/" + (start.getMonth() + 1) + "/" + start.getDate(),
                "e_date": (end.getFullYear() - 1911) + "/" + (end.getMonth() + 1) + "/" + end.getDate(),
                "type": apiType,
            });

            const res = await authedFetch("/GP/Marge/Get", "POST", req);

            if (res.status === 401) throw "AUTH_FAILED";

            const parsedRes = (await res.json()) as any as GetGPMergeDataResponse;

            if (parsedRes.is_ok) return parsedRes;

            else throw parsedRes.err_msg;
        },

        getReportData: async (elderID: string, type: ReportDataType) => {
            const req = new URLSearchParams({
                "e_id": elderID,
                "type": type,
            });

            const res = await authedFetch("/Report/GetListMarge", "POST", req);

            if (res.status === 401) throw "AUTH_FAILED";

            const parsedRes = (await res.json()) as any as GetReportMergeDataResponse<any>;

            if (parsedRes.is_ok) return parsedRes;

            else throw parsedRes.err_msg;
        },

        updateElderData: async (data: any) => {
            console.log(data.chg_date);
            data.chg_date = Math.floor(data.chg_date.getTime() / 1000);
            data.f_datas = JSON.stringify(data.f_datas);
            data.c_datas = JSON.stringify(data.c_datas);
            const req = new URLSearchParams(data);
            const res = await authedFetch("/Elder/Up", "POST", req);
            const parsedRes = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
        },

        uploadImage: async (file: File) => {
            const res = await authedUploadImage("/Publish/PostData", file);
            const parsedRes = await res.text();
            return parsedRes.replaceAll("\"", "");
        },

        getCityList: async () => {
            const data: any = { layer: 1 };
            const req = new URLSearchParams(data);
            const res = await authedFetch("/Publish/Country/Get", "POST", req);
            return await res.json();
        },

        getDistrictList: async (cityCode: string) => {
            const data: any = { layer: 2, code: cityCode };
            const req = new URLSearchParams(data);
            const res = await authedFetch("/Publish/Country/Get", "POST", req);
            return await res.json();
        },

        getVilList: async (cityCode: string) => {
            const data: any = { layer: 3, code: cityCode };
            const req = new URLSearchParams(data);
            const res = await authedFetch("/Publish/Country/Get", "POST", req);
            return await res.json();
        },

        getRelatList: async () => {
            const res = await authedFetch("/Publish/Relat/Get", "POST");
            return await res.json();
        },

        getHealthsList: async (elderId?: string) => {
            const data: any = { e_id: elderId === undefined ? "null" : elderId };
            const req = new URLSearchParams(data);
            const res = await authedFetch("/Publish/Healths/Get", "POST", req);
            return await res.json();
        },

        getTrackList: async () => {
            const res = await authedFetch("/Track/GetList", "POST");
            const parsedRes: GetTrackListResponse = await res.json();

            if (!parsedRes.is_ok) throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生例外錯誤");

            return parsedRes.datas;
        },

        getChartChange: async (begin: Date, end: Date) => {
            const data: any = {
                b_date: (begin.getFullYear() - 1911) + "/" + (begin.getMonth() + 1) + begin.getDate(),
                e_date: (end.getFullYear() - 1911) + "/" + (end.getMonth() + 1) + end.getDate(),
            };
            const req = new URLSearchParams(data);
            const res = await authedFetch("/Chart/Change", "POST", req);
            return await res.json();
        },

        getManagerList: async () => {
            const res = await authedFetch("/Manager/GetList", "POST");
            const parsedRes: GetManagerListResponse = await res.json();
            if (!parsedRes.is_ok) throw new Error(parsedRes.err_msg);
            return parsedRes.datas;
        },

        getManagerData: async (managerId: string) => {
            const req = new URLSearchParams({ "h_id": managerId });

            const res = await authedFetch("/Manager/Get", "POST", req);

            if (res.status === 401) throw "AUTH_FAILED";

            const parsedRes = (await res.json()) as any as GetManagerResponse;

            if (parsedRes.is_ok) {
                parsedRes.h_work_date = new Date(parsedRes.h_work_date);
                parsedRes.h_work_date.setFullYear(parsedRes.h_work_date.getFullYear() + 1911);
                return parsedRes;
            }

            throw parsedRes.err_msg;
        },

        updateManagerData: async (data: Manager) => {
            const reqd: any = data;
            reqd.h_work_date = convertToApiDate(data.h_work_date);
            const req = new URLSearchParams(reqd);
            const res = await authedFetch("/Manager/Up", "POST", req);
            const parsedRes = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
        },

        createManager: async (data: Manager) => {
            const reqd: any = { ...data };
            reqd.h_work_date = convertToApiDate(reqd.h_work_date);
            const req = new URLSearchParams(reqd);
            const res = await authedFetch("/Manager/Add", "POST", req);
            const parsedRes = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
        },

        createCallReport: async (data: CallFormData, elderId: string) => {
            const reqd: any = { ...data, e_id: elderId };
            const req = new URLSearchParams(reqd);
            const res = await authedFetch("/Report/Call/Add", "POST", req);
            const parsedRes = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
        },

        /** 修改電訪紀錄問卷 */
        updateCallReport: async (data: CallFormData, id: string) => {
            const reqd: any = { ...data, id: id };
            const req = new URLSearchParams(reqd);
            const res = await authedFetch("/Report/Call/Up", "POST", req);
            const parsedRes = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
        },

        createHomeReport: async (data: HomeFormData, elderId: string) => {
            const reqd: any = { ...data, e_id: elderId };
            const req = new URLSearchParams(reqd);
            const res = await authedFetch("/Report/Home/Add", "POST", req);
            const parsedRes = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
        },

        updateHomeReport: async (data: HomeFormData, id: string) => {
            const reqd: any = { ...data, id };
            const req = new URLSearchParams(reqd);
            const res = await authedFetch("/Report/Home/Up", "POST", req);
            const parsedRes = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
        },

        createGDSReport: async (data: GDSFormData, elderId: string) => {
            const reqd: any = { ...data, e_id: elderId };
            const req = new URLSearchParams(reqd);
            const res = await authedFetch("/Report/GDS/Add", "POST", req);
            const parsedRes: {
                "fraction": number,
                "description": string,
                "is_ok": boolean,
                "err_title": string,
                "err_msg": string
            } = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
            return parsedRes;
        },

        updateGDSReport: async (data: GDSFormData, id: string) => {
            const reqd: any = { ...data, id };
            const req = new URLSearchParams(reqd);
            const res = await authedFetch("/Report/GDS/Up", "POST", req);
            const parsedRes: {
                "fraction": number,
                "description": string,
                "is_ok": boolean,
                "err_title": string,
                "err_msg": string
            } = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
            return parsedRes;
        },
        createSOFReport: async (data: SOFFormData, elderId: string) => {
            const reqd: any = { ...data, e_id: elderId };
            const req = new URLSearchParams(reqd);
            const res = await authedFetch("/Report/SOF/Add", "POST", req);
            const parsedRes = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
            return parsedRes;
        },
        updateSOFReport: async (data: SOFFormData, id: string) => {
            const reqd: any = { ...data, id };
            const req = new URLSearchParams(reqd);
            const res = await authedFetch("/Report/SOF/Up", "POST", req);
            const parsedRes = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
            return parsedRes;
        },
        createScreeningReport: async (data: ScreeningFormData, elderId: string) => {
            const reqd: any = {
                ...data,
                e_id: elderId,
                q1_clan_1: data.q1_clan_1 ? 1 : 0,
                q1_birthday: convertToApiDate(data.q1_birthday),
                q2_birthday: convertToApiDate(data.q2_birthday),
            };
            const req = new URLSearchParams(reqd);
            const res = await authedFetch("/Report/Screening/Add", "POST", req);
            const parsedRes = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
        },

        updateScreeningReport: async (data: ScreeningFormData, id: string) => {
            const reqd: any = { ...data, id };
            const req = new URLSearchParams(reqd);
            const res = await authedFetch("/Report/Screening/Up", "POST", req);
            const parsedRes = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
        },

        getEducationPostData: async (postId: string): Promise<EducationPost> => {
            const reqd: any = { id: postId };
            const req = new URLSearchParams(reqd);
            const res = await authedFetch("/EDU/Get", "POST", req);
            const parsedRes = await res.json();
            if (res.status !== 200) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
            const blocksFromHTML = convertFromHTML(parsedRes.data);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            parsedRes.on_date = convertFromApiDate(parsedRes.on_date);
            parsedRes.under_date = convertFromApiDate(parsedRes.under_date);
            parsedRes.c_date = convertFromApiDate(parsedRes.c_date);
            parsedRes.content = EditorState.createWithContent(state);
            return parsedRes;
        },

        createEducationPost: async (data: EducationPost): Promise<void> => {
            const reqd: any = {
                title: data.title,
                content: draftToHtml(convertToRaw(data.content.getCurrentContent())),
                is_top: data.is_top,
                img: data.img,
                on_date: convertToApiDate(data.on_date),
                under_date: convertToApiDate(data.under_date),
            };
            const req = new URLSearchParams(reqd);
            const res = await authedFetch("/EDU/Add", "POST", req);
            const parsedRes = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
        },

        updateEducationPost: async (data: EducationPost): Promise<void> => {
            const reqd: any = {
                id: data.id,
                title: data.title,
                content: draftToHtml(convertToRaw(data.content.getCurrentContent())),
                is_top: data.is_top,
                img: data.img,
                on_date: convertToApiDate(data.on_date),
                under_date: convertToApiDate(data.under_date),
            };
            const req = new URLSearchParams(reqd);
            const res = await authedFetch("/EDU/Up", "POST", req);
            const parsedRes = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
        },

        delteEducationPost: async (postId: string): Promise<void> => {
            const reqd: any = { id: postId };
            const req = new URLSearchParams(reqd);
            const res = await authedFetch("/EDU/Del", "POST", req);
            const parsedRes = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
        },

        getEducationList: async (): Promise<EducationPost[]> => {
            const res = await authedFetch("/EDU/GetList", "POST");
            const parsedRes = await res.json();
            if (res.status !== 200 || !parsedRes.is_ok) {
                throw new Error(parsedRes.err_msg ? parsedRes.err_msg : "發生意外錯誤！");
            }
            return parsedRes.datas;
        },
    };
};

export default useAPI;
