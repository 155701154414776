export function convertToApiDate(date: Date) {
    const y = date.getFullYear() - 1911;

    let d = date.getDate().toString();
    d = d.length === 2 ? d : "0" + d;

    let m = (date.getMonth() + 1).toString();
    m = m.length === 2 ? m : "0" + m;

    return y + "/" + m + "/" + d;
}

export function convertFromApiDate(apiDate: string) {
    const d = new Date();
    d.setFullYear(+apiDate.split("/")[0] + 1911);
    d.setMonth(+apiDate.split("/")[1] - 1);
    d.setDate(+apiDate.split("/")[2]);
    return d;
}
