/* eslint-disable max-len */
import React from "react";

const EyeIcon = (props: { color?: string }) => {
    let styleColor: any = props.color;

    switch (styleColor) {
    case "紅燈":
        styleColor = "#FF0000";
        break;

    case "黃燈":
        styleColor = "#FBB03B";
        break;

    case "綠燈":
        styleColor = "#39B54A";
        break;

    case "red":
        styleColor = "#FF0000";
        break;

    case "yellow":
        styleColor = "#FBB03B";
        break;

    case "green":
        styleColor = "#39B54A";
        break;

    default:
        styleColor = "#888888";
        break;
    }

    return <svg className="icon" fill={ styleColor } xmlns="http://www.w3.org/2000/svg">
        <g>
            <path
                d="M0,17.66A33,33,0,0,1,39.34,5.24L42.18,0l2.76,1.49-2.71,5L45,8.12,47.78,3l2.77,1.5L47.62,9.94l2.48,2.15,3.27-6,2.79,1.5c-.5.92-1,1.79-1.43,2.66-.7,1.27-1.38,2.56-2.09,3.82a.7.7,0,0,0,.08.93c.62.75,1.18,1.54,1.79,2.29a.52.52,0,0,1,0,.75c-7.26,9.86-17,14.82-29.23,14.14-10-.56-18-5.11-24.19-13C.71,18.64.36,18.14,0,17.66Zm5.57,0a28,28,0,0,0,12.37,8.5A12.79,12.79,0,0,1,15.53,13c.94.94,1.77,1.76,2.57,2.6a1,1,0,0,1,.12.6,18.07,18.07,0,0,0,0,2.55c.53,3.62,2.3,6.3,6,7.41s7.07.4,9.71-2.5A9,9,0,0,0,25.31,8.76a1.1,1.1,0,0,1-1.26-.36c-.24-.28-.65-.68-.91-.63-1.54.25-3.05.62-4.57,1l0,.22,2.35,2.15-.75.83c-.75-.76-1.45-1.46-2.12-2.19a1.56,1.56,0,0,1-.2-.62C13.93,10.16,7.89,14.36,5.57,17.66Zm43.6,0C46.9,14.33,38.81,8.89,36.44,9c4.44,5.77,4.47,11.54-.15,17.37A28.67,28.67,0,0,0,49.17,17.66Z"
            />
            <path
                d="M22.22,19.78l2.87-3L22.56,14.5l.75-.64,2.2,2.5,3-3-1.08-1.12c2.29-.14,4.72,2,5.12,4.42a5.49,5.49,0,0,1-3.88,6.24A5.42,5.42,0,0,1,22.22,19.78Z"
            />
        </g>
    </svg>;
};

export default EyeIcon;
