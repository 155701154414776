import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Col, Row, Table } from "react-bootstrap";
import useAPI from "../hooks/useAPI";
import { GetClanListResponse } from "../models/Responses";
import AddContactModal from "./modals/AddContactModal";
import ElderFormContent from "../models/form/ElderFormContent";
import AddressOption from "../models/AddressOption";
import AddContactFormData from "../models/form/AddContactFormData";

interface ElderFormProps {
    formData: ElderFormContent,
    setFormData: React.Dispatch<React.SetStateAction<ElderFormContent>>,
    isLoading?: boolean
}

const ElderForm = (props: ElderFormProps) => {
    const API = useAPI();

    const nhiInputs = new Array<HTMLInputElement | null>(3);

    const isCreating = window.location.pathname.includes("create");

    const { formData, setFormData } = props;

    const [watchingList, setWatchingList] = useState(0);
    const [creatingType, setCreatingType] = useState<number | undefined>();
    const [clanList, setClanList] = useState<GetClanListResponse | undefined>();
    const [healthList, setHealthList] = useState<{ h_id: string, h_name: string, h_select: boolean }[]>([]);
    const [isUploadingImage, setUploadingImage] = useState(false);

    const [hCountryOptions, setHCountryOptions] = useState<{ code: string, name: string }[]>([]);
    const [hCityOptions, setHCityOptions] = useState<{ code: string, name: string }[]>([]);
    const [hVilOptions, setHVilOptions] = useState<{ code: string, name: string }[]>([]);
    const [isHAddrChanged, setHAddrChanged] = useState(false);

    const [lCountryOptions, setLCountryOptions] = useState<{ code: string, name: string }[]>([]);
    const [lCityOptions, setLCityOptions] = useState<{ code: string, name: string }[]>([]);
    const [lVilOptions, setLVilOptions] = useState<{ code: string, name: string }[]>([]);
    const [isLAddrChanged, setLAddrChanged] = useState(false);

    function handleInputNHI(value: string, part: 1 | 2 | 3) {
        switch (part) {
        case 1:
            if (value.length > 4) return;
            if (value.length === 4) nhiInputs[1]?.focus();
            setFormData({ ...formData, nhi1: value });
            return;
        case 2:
            if (value.length > 4) return;
            if (value.length === 4) nhiInputs[2]?.focus();
            if (value.length === 0) nhiInputs[0]?.focus();
            setFormData({ ...formData, nhi2: value });
            return;
        case 3:
            if (value.length > 4) return;
            if (value.length === 0) nhiInputs[1]?.focus();
            setFormData({ ...formData, nhi3: value });
            return;
        }
    }

    const [addContactFormData, setAddContactFormData] = useState<AddContactFormData>({
        name: "",
        tel: "",
        mobile: "",
        pwd: "",
        id: "",
        relat: "",
        image: "",
        sms: true,
    });

    async function refreshHCountryOption(currCountryCode: string) {
        const countries = (await API.getCityList()).datas;
        setHCountryOptions(countries);
        const newCountryCode = countries.map((c: AddressOption) => c.code).includes(currCountryCode) ? currCountryCode : countries[0].code;
        if (isCreating || formData.e_id.length !== 0) setFormData({ ...formData, h_country: newCountryCode });
        return newCountryCode;
    }

    async function refreshHCityOption(countryCode: string, currCityCode: string) {
        const cities = (await API.getDistrictList(countryCode)).datas;
        setHCityOptions(cities);
        const newCityCode = cities.map((c: AddressOption) => c.code).includes(currCityCode) ? currCityCode : cities[0].code;
        if (isCreating || formData.e_id.length !== 0)setFormData({ ...formData, h_city: newCityCode });
        return newCityCode;
    }

    async function refreshHVilOption(cityCode: string, currVilCode: string) {
        const vils = (await API.getVilList(cityCode)).datas;
        setHVilOptions(vils);
        const newVilCode = vils.map((c: AddressOption) => c.code).includes(currVilCode) ? currVilCode : vils[0].code;
        if (isCreating || formData.e_id.length !== 0)setFormData({ ...formData, h_vil: newVilCode });
        return newVilCode;
    }

    async function refreshHAddressOptions() {
        const newCountryCode = await refreshHCountryOption(formData.h_country);
        const newCityCode = await refreshHCityOption(newCountryCode, formData.h_city);
        await refreshHVilOption(newCityCode, formData.h_vil);
        setHAddrChanged(false);
    }

    async function refreshLCountryOption(currCountryCode: string) {
        const countries = (await API.getCityList()).datas;
        setLCountryOptions(countries);
        const newCountryCode = countries.map((c: AddressOption) => c.code).includes(currCountryCode) ? currCountryCode : countries[0].code;
        if (isCreating || formData.e_id.length !== 0) setFormData({ ...formData, l_country: newCountryCode });
        return newCountryCode;
    }

    async function refreshLCityOption(countryCode: string, currCityCode: string) {
        const cities = (await API.getDistrictList(countryCode)).datas;
        setLCityOptions(cities);
        const newCityCode = cities.map((c: AddressOption) => c.code).includes(currCityCode) ? currCityCode : cities[0].code;
        if (isCreating || formData.e_id.length !== 0) setFormData({ ...formData, l_city: newCityCode });
        return newCityCode;
    }

    async function refreshLVilOption(cityCode: string, currVilCode: string) {
        const vils = (await API.getVilList(cityCode)).datas;
        setLVilOptions(vils);
        const newVilCode = vils.map((c: AddressOption) => c.code).includes(currVilCode) ? currVilCode : vils[0].code;
        if (isCreating || formData.e_id.length !== 0) setFormData({ ...formData, l_vil: newVilCode });
        return newVilCode;
    }

    async function refreshLAddressOptions() {
        const newCountryCode = await refreshLCountryOption(formData.l_country);
        const newCityCode = await refreshLCityOption(newCountryCode, formData.l_city);
        await refreshLVilOption(newCityCode, formData.l_vil);
        setLAddrChanged(false);
    }

    useEffect(() => {
        if (isHAddrChanged) refreshHAddressOptions();
    }, [isHAddrChanged]);

    useEffect(() => {
        if (isLAddrChanged) refreshLAddressOptions();
    }, [isLAddrChanged]);

    useEffect(() => {
        refreshHealthOptions();
    }, [formData.e_id]);

    useEffect(() => {
        if (!props.isLoading && formData.e_id !== undefined) {
            refreshHealthOptions();
            refreshClanOptions();
            refreshHAddressOptions();
            refreshLAddressOptions();
        }
    }, [props.isLoading]);

    async function refreshClanOptions() {
        const res = await API.getClanList();
        setClanList(res);
        if (!res.datas.map((c) => c.code).includes(formData.clan)) {
            setFormData({ ...formData, clan: res.datas[0].code });
        }
    }

    async function refreshHealthOptions() {
        try {
            const res = await API.getHealthsList(formData.e_id);
            setHealthList(res.datas);
        } catch (err) {
            alert(err);
        }
    }

    function handleAddContact() {
        const data = addContactFormData;
        if (watchingList === 2) {
            // eslint-disable-next-line max-len
            if (data.image !== undefined && data.name !== undefined && data.sms !== undefined && data.relat !== undefined && data.pwd !== undefined && data.id !== undefined) {
                setFormData({
                    ...formData, f_datas: [
                        ...formData.f_datas.filter((f) => f.f_id !== data.id), {
                            "f_name": data.name,
                            "f_pwd": data.pwd,
                            "f_tel": data.tel,
                            "f_mobile": data.mobile,
                            "f_relat": data.relat,
                            "f_id": data.id,
                            "f_img": data.image,
                            "f_sms": data.sms,
                        },
                    ],
                });
            }
        } else {
            if (data.id === undefined) return;

            setFormData({
                ...formData, c_datas: [
                    ...formData.c_datas.filter((c) => c.c_id !== data.id), {
                        "c_name": data.name,
                        "c_tel": data.tel,
                        "c_mobile": data.mobile,
                        "c_type": watchingList,
                        "c_id": data.id,
                    },
                ],
            });
        }
    }

    async function handleRemoveContact() {
        if (watchingList === 2) {
            setFormData({ ...formData, f_datas: [...formData.f_datas.filter((fd) => fd.f_id !== addContactFormData.id)] });
        } else {
            setFormData({ ...formData, c_datas: [...formData.c_datas.filter((cd) => cd.c_id !== addContactFormData.id)] });
        }
    }

    async function uploadImage(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];
        if (formData !== undefined && file !== null && file !== undefined) {
            const res = await API.uploadImage(file);
            setFormData({ ...formData, e_img: res });
            setUploadingImage(false);
        }
    }

    if (props.isLoading) return <p>讀取資料中 ...</p>;

    return <div>
        <div className="sc-edit-normal">
            <div className="r1">
                <p className="sc1qr-label">豐濱長者守護圈</p>

                <input
                    checked={ formData.guard === 1 }
                    onChange={ () => setFormData({ ...formData, guard: 1 }) }
                    type="checkbox"
                />
                <p>開啟</p>

                <input
                    checked={ formData.guard === 0 }
                    onChange={ () => setFormData({ ...formData, guard: 0 }) }
                    type="checkbox"
                />
                <p>關閉</p>

                <input
                    checked={ formData.guard === 2 }
                    onChange={ () => setFormData({ ...formData, guard: 2 }) }
                    type="checkbox"
                />
                <p>暫時關閉</p>

                <a href={ "http://www.gpwear.com.tw/twMap/?imei=" + formData?.imei } rel="noreferrer" target="_blank">
                    <button className="ghost-button">設定</button>
                </a>

            </div>

            <div className="r1">
                <p className="sc1qr-label">手環設備號碼</p>
                <input
                    onChange={ (e) => setFormData({ ...formData, imei: e.target.value }) }
                    value={ formData.imei }
                />

                <button
                    className="ghost-button"
                    onClick={ () => {
                        window.location.href = "https://line.oneqr.app/scanqr.html";
                    } }
                >掃描
                </button>

                <p>健保卡卡號</p>
                <input
                    className="sc1qr-input-s"
                    onChange={ (e) => handleInputNHI(e.target.value, 1) }
                    ref={ (ref) => nhiInputs[0] = ref }
                    value={ formData.nhi1 }
                />
                <input
                    className="sc1qr-input-s"
                    onChange={ (e) => handleInputNHI(e.target.value, 2) }
                    ref={ (ref) => nhiInputs[1] = ref }
                    value={ formData.nhi2 }
                />
                <input
                    className="sc1qr-input-s"
                    onChange={ (e) => handleInputNHI(e.target.value, 3) }
                    ref={ (ref) => nhiInputs[2] = ref }
                    value={ formData.nhi3 }
                />

                <a href={ "https://myhealthbank.nhi.gov.tw/IHKE0002/IHKE0002S01.aspx" } rel="noreferrer" target="_blank">
                    <button className="ghost-button">健康存摺</button>
                </a>
            </div>

            <div className={ "r1 " + (formData.is_chg ? "is-chg" : " ") }>
                <p className="sc1qr-label">是否轉介</p>
                <input
                    checked={ formData.is_chg === true }
                    onClick={ () => setFormData({ ...formData, is_chg: true }) }
                    type="checkbox"
                />
                <p>是</p>
                <input
                    checked={ formData.is_chg === false }
                    onClick={ () => setFormData({ ...formData, is_chg: false }) }
                    type="checkbox"
                />
                <p>否</p>
                {formData.is_chg ? <>
                    <p className="sc1qr-label">轉介日期</p>
                    <DatePicker
                        dateFormat="yyyy/MM/dd"
                        locale="zh-TW"
                        onChange={ (date: Date) => setFormData({ ...formData, chg_date: date }) }
                        selected={ formData.chg_date }
                    />

                    <p className="sc1qr-label">轉介單位</p>
                    <input
                        onChange={ (e) => setFormData({ ...formData, chg_unit: e.target.value }) }
                        value={ formData.chg_unit }
                    />
                </> : <div />}

                <p className="sc1qr-label">健康管理員</p>
                <select onChange={ (e) => setFormData({ ...formData, h_id: e.target.value }) } value={ formData.h_id }>
                    <option key="">請選擇</option>
                    {healthList.map((h) => <option key={ h.h_id } value={ h.h_id }>{h.h_name}</option>)}
                </select>
            </div>

        </div>

        <Row className="info-card sc-edit-profile">
            <Col className="sc-edit-left" xs={ 2 }>
                <img alt="" src={ formData.e_img ? formData.e_img : "/assets/man.svg" } />
                <div style={ { display: "flex" } }>
                    <button
                        className="ghost-button"
                        onClick={ () => setUploadingImage(true) }
                        style={ { margin: 8, padding: "4px 8px" } }
                    >上傳
                    </button>
                    <button
                        className="ghost-button"
                        onClick={ () => setFormData({ ...formData, e_img: null }) }
                        style={ { margin: 8, padding: "4px 8px" } }
                    >清除
                    </button>
                </div>
                {isUploadingImage ? <input
                    onChange={ uploadImage }
                    type="file"
                /> : <div />}
                <p className="sc1qr-label">APP 帳號</p>
                <input
                    onChange={ (e) => setFormData({ ...formData, e_id: e.target.value }) }
                    readOnly={ !window.location.pathname.includes("create") }
                    value={ formData.e_id }
                />
                <p className="sc1qr-label">APP 密碼</p>
                <input
                    onChange={ (e) => setFormData({ ...formData, e_pwd: e.target.value }) }
                    type="password"
                    value={ formData.e_pwd }
                />
            </Col>
            <Col className="elder-info sc-edit-right" xs={ 9 }>
                <Row className="info-row">
                    <Col xs={ 2 }><p className="sc1qr-label">姓名</p></Col>
                    <Col xs={ 4 }>
                        <input
                            onChange={ (e) => setFormData({ ...formData, e_name: e.target.value }) }
                            value={ formData.e_name }
                        /></Col>
                    <Col xs={ 2 }><p className="sc1qr-label">身分證字號</p></Col>
                    <Col xs={ 4 }>
                        <input
                            onChange={ (e) => setFormData({
                                ...formData,
                                e_iden: e.target.value,
                            }) }
                            value={ formData.e_iden }
                        />
                    </Col>
                </Row>

                <Row className="info-row">
                    <Col xs={ 2 }><p className="sc1qr-label">聯絡電話</p></Col>
                    <Col xs={ 4 }><input
                        onChange={ (e) => setFormData({ ...formData, e_tel: e.target.value }) }
                        value={ formData.e_tel }
                    /></Col>
                    <Col xs={ 2 }><p className="sc1qr-label">性別</p></Col>
                    <Col xs={ 4 }>
                        <select
                            onChange={ (e) => setFormData({ ...formData, e_sex: +e.target.value }) }
                            value={ formData.e_sex }
                        >
                            <option value="0">女</option>
                            <option value="1">男</option>
                        </select></Col>
                </Row>

                <Row className="info-row">
                    <Col xs={ 2 }><p className="sc1qr-label">手機號碼</p></Col>
                    <Col xs={ 4 }><input
                        onChange={ (e) => setFormData({ ...formData, e_mobile: e.target.value }) }
                        value={ formData.e_mobile }
                    /></Col>
                    <Col xs={ 2 }><p className="sc1qr-label">族別</p></Col>
                    <Col xs={ 4 }>
                        <select
                            onChange={ (e) => setFormData({ ...formData, clan: e.target.value }) }
                            value={ formData.clan }
                        >
                            {clanList === undefined ? <option>讀取中</option> : <>{
                                clanList.datas.map((cd) => <option
                                    key={ cd.code }
                                    value={ cd.code }
                                >{cd.name}</option>)
                            }</>}
                        </select>
                    </Col>
                </Row>

                <Row className="info-row">
                    <Col xs={ 2 }><p className="sc1qr-label">戶籍地址</p></Col>
                    <Col xs={ 3 }>
                        <select
                            className="sc1qr-input-s"
                            onChange={ (e) => {
                                setFormData({ ...formData, h_country: e.target.value });
                                setHAddrChanged(true);
                            } }
                            value={ formData.h_country }
                        >
                            {hCountryOptions.map((co) => <option key={ co.code } value={ co.code }>{co.name}</option>)}
                        </select>
                    </Col>
                    <Col xs={ 3 }>
                        <select
                            className="sc1qr-input-s"
                            onChange={ (e) => {
                                setFormData({ ...formData, h_city: e.target.value });
                                setHAddrChanged(true);
                            } }
                            value={ formData.h_city }
                        >
                            {hCityOptions.map((co) => <option key={ co.code } value={ co.code }>{co.name}</option>)}
                        </select>
                    </Col>
                    <Col xs={ 3 }>
                        <select
                            className="sc1qr-input-s"
                            onChange={ (e) => {
                                setFormData({ ...formData, h_vil: e.target.value });
                                setHAddrChanged(true);
                            } }
                            value={ formData.h_vil }
                        >
                            {hVilOptions.map((co) => <option key={ co.code } value={ co.code }>{co.name}</option>)}
                        </select>
                    </Col>
                </Row>
                <Row className="info-row">
                    <Col xs={ 2 } />
                    <Col xs={ 10 }>
                        <input
                            className="sc1qr-input-l"
                            onChange={ (e) => setFormData({ ...formData, h_addr: e.target.value }) }
                            value={ formData.h_addr }
                        />
                    </Col>
                </Row>

                <Row className="info-row">
                    <Col xs={ 2 }><p className="sc1qr-label">居住地址</p></Col>
                    <Col xs={ 3 }>
                        <select
                            className="sc1qr-input-s"
                            onChange={ (e) => {
                                setFormData({ ...formData, l_country: e.target.value });
                                setLAddrChanged(true);
                            } }
                            value={ formData.l_country }
                        >
                            {lCountryOptions.map((co) => <option key={ co.code } value={ co.code }>{co.name}</option>)}
                        </select>
                    </Col>
                    <Col xs={ 3 }>
                        <select
                            className="sc1qr-input-s"
                            onChange={ (e) => {
                                setFormData({ ...formData, l_city: e.target.value });
                                setLAddrChanged(true);
                            } }
                            value={ formData.l_city }
                        >
                            {lCityOptions.map((co) => <option key={ co.code } value={ co.code }>{co.name}</option>)}
                        </select>
                    </Col>
                    <Col xs={ 3 }>
                        <select
                            className="sc1qr-input-s"
                            onChange={ (e) => {
                                setFormData({ ...formData, l_vil: e.target.value });
                                setLAddrChanged(true);
                            } }
                            value={ formData.l_vil }
                        >
                            {lVilOptions.map((co) => <option key={ co.code } value={ co.code }>{co.name}</option>)}
                        </select>
                    </Col>
                </Row>
                <Row className="info-row">
                    <Col xs={ 2 } />
                    <Col xs={ 10 }>
                        <input
                            className="sc1qr-input-l"
                            onChange={ (e) => setFormData({ ...formData, l_addr: e.target.value }) }
                            value={ formData.l_addr }
                        />
                    </Col>
                </Row>
            </Col>
        </Row>


        <Row className="info-card sc-edit-contract" style={ { padding: "0 0 16px 0" } }>
            <Row className="people-select" style={ { margin: 0 } }>
                <Col
                    className={ "option with-border " + (watchingList === 0 ? "active" : "") }
                    onClick={ () => setWatchingList(0) }
                >
                    <div>
                        <p>日間聯絡人</p>
                        <p className="subtle">健康管理員</p>
                    </div>
                </Col>

                <Col
                    className={ "option with-border " + (watchingList === 1 ? "active" : "") }
                    onClick={ () => setWatchingList(1) }
                >
                    <div>
                        <p>夜間聯絡人</p>
                        <p className="subtle">村長/里長</p>
                    </div>
                </Col>

                <Col
                    className={ "option " + (watchingList === 2 ? "active" : "") }
                    onClick={ () => setWatchingList(2) }
                >
                    <div>
                        <p>全天聯絡人</p>
                        <p className="subtle">長者家屬</p>
                    </div>
                </Col>
            </Row>

            <div className="people-list">
                <Table>
                    <thead style={ { textAlign: "center" } }>
                        <tr>
                            <th><p>姓名</p></th>
                            <th><p>聯絡電話</p></th>
                            <th><p>手機號碼</p></th>
                            <th />
                        </tr>
                    </thead>
                    <tbody style={ { textAlign: "center" } }>
                        {watchingList === 2 ?
                            <>{
                                formData.f_datas.map((fd) => <tr key={ fd.f_id }>
                                    <th>{fd.f_name}</th>
                                    <th>{fd.f_tel}</th>
                                    <th>{fd.f_mobile}</th>
                                    <th>
                                        <button
                                            className="ghost-button"
                                            onClick={ () => {
                                                setAddContactFormData({
                                                    id: fd.f_id,
                                                    mobile: fd.f_mobile,
                                                    tel: fd.f_tel,
                                                    relat: fd.f_relat,
                                                    name: fd.f_name,
                                                    pwd: fd.f_pwd,
                                                    sms: fd.f_sms,
                                                    image: fd.f_img,
                                                });
                                                setCreatingType(watchingList);
                                            } }
                                        >編輯
                                        </button>
                                    </th>
                                </tr>)
                            }</> : <>{
                                formData.c_datas.filter((fd) => fd.c_type === watchingList).map((fd) => <tr key={ fd.c_id }>
                                    <th>{fd.c_name}</th>
                                    <th>{fd.c_tel}</th>
                                    <th>{fd.c_mobile}</th>
                                    <th>
                                        <button
                                            className="ghost-button"
                                            onClick={ () => {
                                                setAddContactFormData({
                                                    id: fd.c_id,
                                                    mobile: fd.c_mobile,
                                                    tel: fd.c_tel,
                                                    name: fd.c_name,
                                                });
                                                setCreatingType(watchingList);
                                            } }
                                        >編輯
                                        </button>
                                    </th>
                                </tr>)
                            }</>
                        }
                    </tbody>
                </Table>
                <img alt="" onClick={ () => setCreatingType(watchingList) } src={ "/assets/add.svg" } />
            </div>
        </Row>

        <AddContactModal
            formData={ addContactFormData }
            onCancel={ () => setCreatingType(undefined) }
            onDelete={ () => {
                handleRemoveContact();
                setCreatingType(undefined);
            } }
            onSave={ () => {
                handleAddContact();
                setCreatingType(undefined);
            } }
            setFormData={ setAddContactFormData }
            type={ creatingType }
        />
    </div>;
};

export default ElderForm;
