import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import TitleBar from "../../components/TitleBar";
import { defaultScreeningFormData, ScreeningFormData } from "../../models/form/ScreeningForm";
import useAPI from "../../hooks/useAPI";
import useErrorHandler from "../../hooks/useErrorHandler";
import DatePicker from "react-datepicker";
import { Col, Row } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import AddressOption from "../../models/AddressOption";
import { GetClanListResponse } from "../../models/Responses";

/** 長照需求問卷頁面 */
export default function ScreeningFormPage() {
    const API = useAPI();
    const errorHandler = useErrorHandler();
    const { id } = useParams<{ id: string }>();
    const isCreating = id === "create";
    const elderId = window.location.search.split("elderId=")[1];

    const signCanvasRef1 = useRef(null);
    const signCanvasRef2 = useRef(null);

    const [signCanvasWidth, setSignCanvasWidth] = useState(100);
    const [formData, setFormData] = useState<ScreeningFormData>(defaultScreeningFormData);

    const [countryOptions1, setCountryOptions1] = useState<{ code: string, name: string }[]>([]);
    const [cityOptions1, setCityOptions1] = useState<{ code: string, name: string }[]>([]);
    const [vilOptions1, setVilOptions1] = useState<{ code: string, name: string }[]>([]);
    const [isAddr1Changed, setAddrChanged1] = useState(false);

    const [countryOptions2, setCountryOptions2] = useState<{ code: string, name: string }[]>([]);
    const [cityOptions2, setCityOptions2] = useState<{ code: string, name: string }[]>([]);
    const [isAddr2Changed, setAddr2Changed] = useState(false);

    const [clanList, setClanList] = useState<GetClanListResponse | undefined>();

    const [relats, setRelats] = useState<{ code: string, name: string }[]>([]);

    function goBack() {
        window.location.href = "/elder/" + elderId;
    }

    useEffect(() => {
        API.getRelatList().then((res) => setRelats(res.datas));
    }, []);


    useEffect(() => {
        if (formData.e_sign !== "") {
            // @ts-ignore
            signCanvasRef1.current.fromDataURL("data:image/png;base64," + formData.e_sign);
            // @ts-ignore
            signCanvasRef1.current.off();
        }
    }, [formData.e_sign]);

    useEffect(() => {
        if (formData.h_sign !== "") {
            // @ts-ignore
            signCanvasRef2.current.fromDataURL("data:image/png;base64," + formData.h_sign);
            // @ts-ignore
            signCanvasRef2.current.off();
        }
    }, [formData.h_sign]);

    async function refreshClanOptions() {
        const res = await API.getClanList();
        setClanList(res);
        if (!res.datas.map((c) => c.code).includes(formData.q1_clan_2)) {
            setFormData({ ...formData, q1_clan_2: res.datas[0].code });
        }
    }

    async function submit() {
        try {
            if (isCreating) {
                await API.createScreeningReport({
                    ...formData,
                    // @ts-ignore
                    e_sign: signCanvasRef1.current.toDataURL().replace("data:image/png;base64,", ""),
                    // @ts-ignore
                    h_sign: signCanvasRef2.current.toDataURL().replace("data:image/png;base64,", ""),
                }, elderId);
                goBack();
            } else {
                await API.updateScreeningReport({
                    ...formData,
                    // @ts-ignore
                    e_sign: signCanvasRef1.current.toDataURL().replace("data:image/png;base64,", ""),
                    // @ts-ignore
                    h_sign: signCanvasRef2.current.toDataURL().replace("data:image/png;base64,", ""),
                }, id);
                goBack();
            }
        } catch (err) {
            errorHandler.handle(err);
        }
    }

    async function refreshCountry1Option(currCountryCode: string) {
        const countries = (await API.getCityList()).datas;
        setCountryOptions1(countries);
        const newCountryCode = countries.map((c: AddressOption) => c.code).includes(currCountryCode) ? currCountryCode : countries[0].code;
        if(isCreating) setFormData({ ...formData, q1_county: newCountryCode });
        return newCountryCode;
    }

    async function refreshCity1Option(countryCode: string, currCityCode: string) {
        const cities = (await API.getDistrictList(countryCode)).datas;
        setCityOptions1(cities);
        const newCityCode = cities.map((c: AddressOption) => c.code).includes(currCityCode) ? currCityCode : cities[0].code;
        if(isCreating)  setFormData({ ...formData, q1_city: newCityCode });
        return newCityCode;
    }

    async function refreshVil1Option(cityCode: string, currVilCode: string) {
        const vils = (await API.getVilList(cityCode)).datas;
        setVilOptions1(vils);
        const newVilCode = vils.map((c: AddressOption) => c.code).includes(currVilCode) ? currVilCode : vils[0].code;
        if(isCreating) setFormData({ ...formData, q1_vil_1: newVilCode });
        return newVilCode;
    }

    async function refreshAddressOptions1() {
        const newCountryCode = await refreshCountry1Option(formData.q1_county);
        const newCityCode = await refreshCity1Option(newCountryCode, formData.q1_city);
        await refreshVil1Option(newCityCode, formData.q1_vil_1);
        setAddrChanged1(false);
    }

    async function refreshCountry2Option(currCountryCode: string) {
        const countries = (await API.getCityList()).datas;
        setCountryOptions2(countries);
        const newCountryCode = countries.map((c: AddressOption) => c.code).includes(currCountryCode) ? currCountryCode : countries[0].code;
        if(isCreating)  setFormData({ ...formData, q2_county: newCountryCode });
        return newCountryCode;
    }

    async function refreshCity2Option(countryCode: string, currCityCode: string) {
        const cities = (await API.getDistrictList(countryCode)).datas;
        setCityOptions2(cities);
        const newCityCode = cities.map((c: AddressOption) => c.code).includes(currCityCode) ? currCityCode : cities[0].code;
        if(isCreating)  setFormData({ ...formData, q2_city: newCityCode });
        return newCityCode;
    }

    async function refreshAddressOptions2() {
        const newCountryCode = await refreshCountry2Option(formData.q2_county);
        await refreshCity2Option(newCountryCode, formData.q2_city);
        setAddr2Changed(false);
    }

    useEffect(() => {
        if (isAddr1Changed) refreshAddressOptions1();
    }, [isAddr1Changed]);

    useEffect(() => {
        if (isAddr2Changed) refreshAddressOptions2();
    }, [isAddr2Changed]);

    useEffect(() => {
        setAddrChanged1(true)
        setAddr2Changed(true)
        refreshClanOptions()
        try {
            if (!isCreating) {
                API.getScreeningData(id).then((res) => setFormData(res));
            } else API.getScreeningBasicData(elderId).then((res) => setFormData({ ...formData, ...res }));
        } catch (err) {
            errorHandler.handle(err);
        }
    }, [id]);

    return <div className="elder-detail elder-report-ct report-screening">
        <TitleBar title={ isCreating ? "新增長照需求初篩" : "長照需求初篩" }  isForm/>
        <table>
            <tbody>

                <tr>
                    <td className='header' colSpan={ 6 }>智慧健康管理-長照需求初篩表</td>
                </tr>
                <tr>
                    <td colSpan={ 6 }>
                        <Row>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q0_1 === true }
                                    onClick={ () => setFormData({ ...formData, q0_1: !formData.q0_1 }) }
                                    type="checkbox"
                                />
                                <p>65歲以上老人</p>
                            </div>

                            <div className="ckbox">
                                <input
                                    checked={ formData.q0_2 === true }
                                    onClick={ () => setFormData({ ...formData, q0_2: !formData.q0_2 }) }
                                    type="checkbox"
                                />
                                <p>領有身心障礙證明書</p>
                            </div>

                            <div className="ckbox">
                                <input
                                    checked={ formData.q0_3 === true }
                                    onClick={ () => setFormData({ ...formData, q0_3: !formData.q0_3 }) }
                                    type="checkbox"
                                />
                                <p>65歲以上原住民</p>
                            </div>

                            <div className="ckbox">
                                <input
                                    checked={ formData.q0_4 === true }
                                    onClick={ () => setFormData({ ...formData, q0_4: !formData.q0_4 }) }
                                    type="checkbox"
                                />
                                <p>獨居老人</p>
                            </div>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td colSpan={ 6 }>
                        記憶測試:說出三樣周遭物品或環境請個案記住(如:椅子、時鐘、白色)<br />
                        (資料收集最後，需要測試民眾是否能夠記得受測前之三項物品，故請訪員於受測前先行告知及提醒民眾。)
                    </td>
                </tr>
                <tr>
                    <td className='header_t' colSpan={ 6 }>一、基本資料</td>
                </tr>
                <tr>
                    <td colSpan={ 3 }>
                        <p className="sc1qr-label">姓名 :</p>
                        <input
                            onChange={ (e) => setFormData({ ...formData, q1_name: e.target.value }) }
                            value={ formData.q1_name }
                        />
                    </td>
                    <td colSpan={ 3 }>
                        <p className="sc1qr-label">生日 :</p>
                        <DatePicker
                            dateFormat="yyyy/MM/dd"
                            locale="zh-TW"
                            onChange={ (date: Date) => {
                                setFormData({ ...formData, q1_birthday: date });
                            } }
                            selected={ formData.q1_birthday }
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan={ 3 }>
                        <p className="sc1qr-label">身分證號</p>
                        <input
                            onChange={ (e) => setFormData({ ...formData, q1_iden: e.target.value }) }
                            value={ formData.q1_iden }
                        />
                    </td>
                    <td colSpan={ 3 }>
                        <p className="sc1qr-label">電話</p>
                        <input
                            onChange={ (e) => setFormData({ ...formData, q1_tel: e.target.value }) }
                            value={ formData.q1_tel }
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan={ 3 }>
                        <p className="sc1qr-label">族群</p>
                        <Row>
                            <input
                                checked={ formData.q1_clan_1 === true }
                                onClick={ () => setFormData({ ...formData, q1_clan_1: true }) }
                                type="checkbox"
                            />
                            <p>原住民</p>
                            <input
                                checked={ formData.q1_clan_1 === false}
                                onClick={ () => setFormData({ ...formData, q1_clan_1: false }) }
                                type="checkbox"
                            />
                            <p>非原住民</p>
                        </Row>
                        {formData.q1_clan_1 ?
                            <select
                                onChange={ (e) => setFormData({ ...formData, q1_clan_2: e.target.value }) }
                                value={ formData.q1_clan_2 }
                            >
                                {clanList === undefined ? <option>讀取中</option> : <>{
                                    clanList.datas.map((cd) => <option
                                        key={ cd.code }
                                        value={ cd.code }
                                    >{cd.name}</option>)
                                }</>}
                            </select> : <div />}
                    </td>
                    <td colSpan={ 3 }>
                        <p className="sc1qr-label">行動電話</p>
                        <input
                            onChange={ (e) => setFormData({ ...formData, q1_mobile: e.target.value }) }
                            value={ formData.q1_mobile }
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan={ 6 }>
                        <p className="sc1qr-label">通訊地址</p>
                        <select
                            className="sc1qr-input-s"
                            onChange={ (e) => {
                                setFormData({ ...formData, q1_county: e.target.value });
                                setAddrChanged1(true);
                            } }
                            value={ formData.q1_county }
                        >
                            {countryOptions1.map((co) => <option key={ co.code } value={ co.code }>{co.name}</option>)}
                        </select>
                        <select
                            className="sc1qr-input-s"
                            onChange={ (e) => {
                                setFormData({ ...formData, q1_city: e.target.value });
                                setAddrChanged1(true);
                            } }
                            value={ formData.q1_city }
                        >
                            {cityOptions1.map((co) => <option key={ co.code } value={ co.code }>{co.name}</option>)}
                        </select>
                        <select
                            className="sc1qr-input-s"
                            onChange={ (e) => {
                                setFormData({ ...formData, q1_vil_1: e.target.value });
                                setAddrChanged1(true);
                            } }
                            value={ formData.q1_vil_1 }
                        >
                            {vilOptions1.map((co) => <option key={ co.code } value={ co.code }>{co.name}</option>)}
                        </select>
                        <input
                            onChange={ (e) => setFormData({ ...formData, q1_addr: e.target.value }) }
                            value={ formData.q1_addr }
                        />
                    </td>
                </tr>
                <tr>
                    <td className='header_t' colSpan={ 6 }>二、主要聯絡人基本資料</td>
                </tr>
                <tr>
                    <td colSpan={ 3 }>
                        <p className="sc1qr-label">姓名</p>
                        <input
                            onChange={ (e) => setFormData({ ...formData, q2_name: e.target.value }) }
                            value={ formData.q2_name }
                        />
                    </td>
                    <td colSpan={ 3 }>
                        <p className="sc1qr-label">關係</p>
                        <select
                            onChange={ (e) => setFormData({ ...formData, q2_relat: e.target.value }) }
                            value={ formData.q2_relat }
                        >
                            {relats.map((r) => <option key={ r.code } value={ r.code }>{r.name}</option>)}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td colSpan={ 3 }>
                        <p className="sc1qr-label">身分證號</p>
                        <input
                            onChange={ (e) => setFormData({ ...formData, q2_iden: e.target.value }) }
                            value={ formData.q2_iden }
                        />
                    </td>
                    <td colSpan={ 3 }>
                        <p className="sc1qr-label">電話</p>
                        <input
                            onChange={ (e) => setFormData({ ...formData, q2_tel: e.target.value }) }
                            value={ formData.q2_tel }
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan={ 3 }>
                        <p className="sc1qr-label">生日</p>
                        <DatePicker
                            dateFormat="yyyy/MM/dd"
                            locale="zh-TW"
                            onChange={ (date: Date) => {
                                setFormData({ ...formData, q2_birthday: date });
                            } }
                            selected={ formData.q2_birthday }
                        />
                    </td>
                    <td colSpan={ 3 }>
                        <p className="sc1qr-label">居住縣市</p>
                        <select
                            onChange={ (e) => {
                                setFormData({ ...formData, q2_county: e.target.value })
                                setAddr2Changed(true)
                            } }
                            value={ formData.q2_county }
                        >
                            {countryOptions2.map((op) => <option key={ op.code } value={ op.code }>{op.name}</option>)}
                        </select>
                        <p className="sc1qr-label">居住鄉鎮</p>
                        <select
                            onChange={ (e) => setFormData({ ...formData, q2_city: e.target.value }) }
                            value={ formData.q2_city }
                        >
                            {cityOptions2.map((op) => <option key={ op.code } value={ op.code }>{op.name}</option>)}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td className='header_t' colSpan={ 6 }>三、日常生活功能</td>
                </tr>
                <tr>
                    <td className="sc1qr-label" colSpan={ 1 }>
                        進食
                    </td>
                    <td colSpan={ 5 }>
                        <Row>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q3_1 === 1 }
                                    onClick={ () => setFormData({ ...formData, q3_1: 1 }) }
                                    type="checkbox"
                                />
                                <p>自行在合理的時間內(約十秒一口)，可自行取食眼前食物</p>
                            </div>
                        </Row>
                        <Row>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q3_1 === 0 }
                                    onClick={ () => setFormData({ ...formData, q3_1: 0 }) }
                                    type="checkbox"
                                />
                                <p>無法自己進食，需要管灌或依賴他人協助</p>
                            </div>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td className="sc1qr-label" colSpan={ 1 }>
                        穿衣
                    </td>
                    <td colSpan={ 5 }>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_2 === 1 }
                                onClick={ () => setFormData({ ...formData, q3_2: 1 }) }
                                type="checkbox"
                            />
                            <p>可自行穿脫衣服、鞋子及輔具</p>
                        </Row>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_2 === 0 }
                                onClick={ () => setFormData({ ...formData, q3_2: 0 }) }
                                type="checkbox"
                            />
                            <p>需要他人幫忙</p>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td className="sc1qr-label" colSpan={ 1 }>
                        洗澡
                    </td>
                    <td colSpan={ 5 }>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_3 === 1 }
                                onClick={ () => setFormData({ ...formData, q3_3: 1 }) }
                                type="checkbox"
                            />
                            <p>可獨力完成，不論是坐浴或淋浴</p>
                        </Row>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_3 === 0 }
                                onClick={ () => setFormData({ ...formData, q3_3: 0 }) }
                                type="checkbox"
                            />
                            <p>需要他人幫忙</p>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td className="sc1qr-label" colSpan={ 1 }>
                        如廁
                    </td>
                    <td colSpan={ 5 }>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_4 === 1 }
                                onClick={ () => setFormData({ ...formData, q3_4: 1 }) }
                                type="checkbox"
                            />
                            <p>可自行進入廁所、不會弄髒衣物，並能穿衣褲；使用便盆者可自行清理；使用尿布者可自行更換。且身上無異味。</p>
                        </Row>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_4 === 0 }
                                onClick={ () => setFormData({ ...formData, q3_4: 0 }) }
                                type="checkbox"
                            />
                            <p>需要他人幫忙</p>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td className="sc1qr-label" colSpan={ 1 }>
                        移位
                    </td>
                    <td colSpan={ 5 }>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_5 === 1 }
                                onClick={ () => setFormData({ ...formData, q3_5: 1 }) }
                                type="checkbox"
                            />
                            <p>能自行從床上移動到椅子上，且無安全上的疑慮</p>
                        </Row>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_5 === 0 }
                                onClick={ () => setFormData({ ...formData, q3_5: 0 }) }
                                type="checkbox"
                            />
                            <p>需一人以上扶持才能移動身體</p>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td className="sc1qr-label" colSpan={ 1 }>
                        行走
                    </td>
                    <td colSpan={ 5 }>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_6 === 1 }
                                onClick={ () => setFormData({ ...formData, q3_6: 1 }) }
                                type="checkbox"
                            />
                            <p>不需要工具輔助或他人幫忙即可移動或步行</p>
                        </Row>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_6 === 0 }
                                onClick={ () => setFormData({ ...formData, q3_6: 0 }) }
                                type="checkbox"
                            />
                            <p>需要工具輔助，他人協助下才能移動或步行；或根本無法行動</p>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td className="sc1qr-label" colSpan={ 1 }>
                        個人衛生
                    </td>
                    <td colSpan={ 5 }>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_7 === 1 }
                                onClick={ () => setFormData({ ...formData, q3_7: 1 }) }
                                type="checkbox"
                            />
                            <p>可獨立完成洗臉、洗手、刷牙及梳頭</p>
                        </Row>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_7 === 0 }
                                onClick={ () => setFormData({ ...formData, q3_7: 0 }) }
                                type="checkbox"
                            />
                            <p>需要他人幫忙</p>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td className="sc1qr-label" colSpan={ 1 }>
                        上下樓梯
                    </td>
                    <td colSpan={ 5 }>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_8 === 1 }
                                onClick={ () => setFormData({ ...formData, q3_8: 1 }) }
                                type="checkbox"
                            />
                            <p>可自行上下樓梯(允許抓扶手、用拐杖)</p>
                        </Row>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_8 === 0 }
                                onClick={ () => setFormData({ ...formData, q3_8: 0 }) }
                                type="checkbox"
                            />
                            <p>需要他人幫忙</p>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td className="sc1qr-label" colSpan={ 1 }>
                        大便控制
                    </td>
                    <td colSpan={ 5 }>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_9 === 1 }
                                onClick={ () => setFormData({ ...formData, q3_9: 1 }) }
                                type="checkbox"
                            />
                            <p>不會失禁，並可自行使用塞劑</p>
                        </Row>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_9 === 0 }
                                onClick={ () => setFormData({ ...formData, q3_9: 0 }) }
                                type="checkbox"
                            />
                            <p>需別人協助或偶爾會需要他人幫忙，如使用塞劑</p>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td className="sc1qr-label" colSpan={ 1 }>
                        小便控制
                    </td>
                    <td colSpan={ 5 }>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_10 === 1 }
                                onClick={ () => setFormData({ ...formData, q3_10: 1 }) }
                                type="checkbox"
                            />
                            <p>日夜皆不會尿失禁，或可以自行使用並清理尿套</p>
                        </Row>
                        <Row className="ckbox">
                            <input
                                checked={ formData.q3_10 === 0 }
                                onClick={ () => setFormData({ ...formData, q3_10: 0 }) }
                                type="checkbox"
                            />
                            <p>需要他人協助或偶爾會失禁(每週不超過一次)或尿急(無法等待便盆或無法及時趕到廁所)或需要別人幫忙處理尿套</p>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td className='header_t' colSpan={ 6 }>四、長照服務高危險群資料收集</td>
                </tr>
                <tr>
                    <td colSpan={ 6 }>
                        <div>
                            <p className="sc1qr-label">(一)最近三個月內是否住院(含急診)</p>
                            <Row>
                                <div className="ckbox">
                                    <input
                                        checked={ formData.q4_1 === false }
                                        onClick={ () => setFormData({ ...formData, q4_1: false }) }
                                        type="checkbox"
                                    />
                                    <p>否</p>
                                </div>
                                <div className="ckbox">
                                    <input
                                        checked={ formData.q4_1 === true }
                                        onClick={ () => setFormData({ ...formData, q4_1: true }) }
                                        type="checkbox"
                                    />
                                    <p>是</p>
                                </div>
                            </Row>
                            {formData.q4_1 ? <>
                                <p>住院原因/日期</p>
                                <input
                                    onChange={ (e) => setFormData({ ...formData, q4_1_msg: e.target.value }) }
                                    value={ formData.q4_1_msg }
                                /> </> : <div />}
                        </div>
                        <div>
                            <p className="sc1qr-label">(二)最近三個月內是否有跌倒經驗</p>
                            <Row>
                                <div className="ckbox">
                                    <input
                                        checked={ formData.q4_2 }
                                        onClick={ () => setFormData({ ...formData, q4_2: true }) }
                                        type="checkbox"
                                    />
                                    <p>否</p>
                                </div>
                                <div className="ckbox">
                                    <input
                                        checked={ formData.q4_2 === false }
                                        onClick={ () => setFormData({ ...formData, q4_2: false }) }
                                        type="checkbox"
                                    />
                                    <p>是</p>
                                </div>
                            </Row>
                        </div>
                        <div>
                            <p className="sc1qr-label">(三)是否使用輔具</p>
                            <Row>
                                <div className="ckbox">
                                    <input
                                        checked={ formData.q4_3 === false }
                                        onClick={ () => setFormData({ ...formData, q4_3: false }) }
                                        type="checkbox"
                                    />
                                    <p>否</p>
                                </div>
                                <div className="ckbox">
                                    <input
                                        checked={ formData.q4_3 === true }
                                        onClick={ () => setFormData({ ...formData, q4_3: true }) }
                                        type="checkbox"
                                    />
                                    <p>是</p>
                                </div>
                                {formData.q4_3 ? <input
                                    onChange={ (e) => setFormData({
                                        ...formData,
                                        q4_3_msg: e.target.value,
                                    }) }
                                    value={ formData.q4_3_msg }
                                /> : <div />}
                            </Row>
                            {formData.q4_3 ? <Row>
                                <div className="ckbox">
                                    <input
                                        checked={ formData.q4_3_1 === true }
                                        onClick={ () => setFormData({ ...formData, q4_3_1: true }) }
                                        type="checkbox"
                                    />
                                    <p>購買</p>
                                </div>
                                <div className="ckbox">
                                    <input
                                        checked={ formData.q4_3_1 === false }
                                        onClick={ () => setFormData({ ...formData, q4_3_1: false }) }
                                        type="checkbox"
                                    />
                                    <p>租借</p>
                                </div>
                            </Row> : <div />}
                        </div>
                        <div>
                            <p className="sc1qr-label">(四)特殊照顧</p>
                            <Row>
                                <div className="ckbox">
                                    <input
                                        checked={ formData.q4_4_1 }
                                        onClick={ () => setFormData({ ...formData, q4_4_1: !formData.q4_4_1 }) }
                                        type="checkbox"
                                    />
                                    <p>鼻胃管</p>
                                </div>
                                <div className="ckbox">
                                    <input
                                        checked={ formData.q4_4_2 }
                                        onClick={ () => setFormData({ ...formData, q4_4_2: !formData.q4_4_2 }) }
                                        type="checkbox"
                                    />
                                    <p>尿管</p>
                                </div>
                                <div className="ckbox">
                                    <input
                                        checked={ formData.q4_4_3 }
                                        onClick={ () => setFormData({ ...formData, q4_4_3: !formData.q4_4_3 }) }
                                        type="checkbox"
                                    />
                                    <p>氣管切口</p>
                                </div>
                                <div className="ckbox">
                                    <input
                                        checked={ formData.q4_4_4 }
                                        onClick={ () => setFormData({ ...formData, q4_4_4: !formData.q4_4_4 }) }
                                        type="checkbox"
                                    />
                                    <p>身體傷口</p>
                                </div>
                                <div className="ckbox">
                                    <input
                                        checked={ formData.q4_4_5 }
                                        onClick={ () => setFormData({ ...formData, q4_4_5: !formData.q4_4_5 }) }
                                        type="checkbox"
                                    />
                                    <p>其他</p>
                                    <input
                                        onChange={ (e) => setFormData({ ...formData, q4_4_5_msg: e.target.value }) }
                                        value={ formData.q4_4_5_msg }
                                    />
                                </div>
                            </Row>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className='header_t' colSpan={ 6 }>五、記憶測試</td>
                </tr>
                <tr>
                    <td colSpan={ 6 }>
                        <p className="sc1qr-label">三題之中答對幾題</p>
                        <input onChange={ (e) => setFormData({ ...formData, q5_1: e.target.value }) } value={ formData.q5_1 } />
                    </td>
                </tr>
                <tr>
                    <td className='header_t' colSpan={ 6 }>六、是否同意照專訪視</td>
                </tr>
                <tr>
                    <td colSpan={ 6 }>
                        <Row>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q6_1 === 0 }
                                    onClick={ () => setFormData({ ...formData, q6_1: 0 }) }
                                    type="checkbox"
                                />
                                <p>是</p>
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q6_1 === 1 }
                                    onClick={ () => setFormData({ ...formData, q6_1: 1 }) }
                                    type="checkbox"
                                />
                                <p>否,原因:</p>
                                <input
                                    onChange={ (e) => setFormData({ ...formData, q6_1_msg: e.target.value }) }
                                    value={ formData.q6_1_msg }
                                />
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q6_1 === 2 }
                                    onClick={ () => setFormData({ ...formData, q6_1: 2 }) }
                                    type="checkbox"
                                />
                                <p>考慮中</p>
                            </div>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td className='header_t' colSpan={ 6 }>七、輔助基本資料</td>
                </tr>
                <tr>
                    <td colSpan={ 6 }>
                        <p className="sc1qr-label">目前是否領有身心障礙手冊</p>
                        <Row>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q7_1 }
                                    onClick={ () => setFormData({ ...formData, q7_1: true }) }
                                    type="checkbox"
                                />
                                <p>是,障別：</p>
                                <input
                                    onChange={ (e) => setFormData({ ...formData, q7_1_msg: e.target.value }) }
                                    value={ formData.q7_1_msg }
                                />
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q7_1 === false }
                                    onClick={ () => setFormData({ ...formData, q7_1: false }) }
                                    type="checkbox"
                                />
                                <p>否</p>
                            </div>
                        </Row>
                        <p className="sc1qr-label">障礙程度</p>
                        <Row>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q7_2_1 }
                                    onClick={ () => setFormData({
                                        ...formData,
                                        q7_2_1: true,
                                        q7_2_2: false,
                                        q7_2_3: false,
                                        q7_2_4: false,
                                    }) }
                                    type="checkbox"
                                />
                                <p>極重度</p>
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q7_2_2 }
                                    onClick={ () => setFormData({
                                        ...formData,
                                        q7_2_1: false,
                                        q7_2_2: true,
                                        q7_2_3: false,
                                        q7_2_4: false,
                                    }) }
                                    type="checkbox"
                                />
                                <p>重度</p>
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q7_2_3 }
                                    onClick={ () => setFormData({
                                        ...formData,
                                        q7_2_1: false,
                                        q7_2_2: false,
                                        q7_2_3: true,
                                        q7_2_4: false,
                                    }) }
                                    type="checkbox"
                                />
                                <p>中度</p>
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q7_2_4 }
                                    onClick={ () => setFormData({
                                        ...formData,
                                        q7_2_1: false,
                                        q7_2_2: false,
                                        q7_2_3: false,
                                        q7_2_4: true,
                                    }) }
                                    type="checkbox"
                                />
                                <p>輕度</p>
                            </div>
                        </Row>
                        <p className="sc1qr-label">社會福利身分別</p>
                        <Row>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q7_3 === 0 }
                                    onClick={ () => setFormData({ ...formData, q7_3: 0 }) }
                                    type="checkbox"
                                />
                                <p>一般戶</p>
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q7_3 === 1 }
                                    onClick={ () => setFormData({ ...formData, q7_3: 1 }) }
                                    type="checkbox"
                                />
                                <p>中低收</p>
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q7_3 === 2 }
                                    onClick={ () => setFormData({ ...formData, q7_3: 2 }) }
                                    type="checkbox"
                                />
                                <p>低收</p>
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q7_3 === 3 }
                                    onClick={ () => setFormData({ ...formData, q7_3: 3 }) }
                                    type="checkbox"
                                />
                                <p>中低身心障</p>
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q7_3 === 4 }
                                    onClick={ () => setFormData({ ...formData, q7_3: 4 }) }
                                    type="checkbox"
                                />
                                <p>低收身心障</p>
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q7_3 === 5 }
                                    onClick={ () => setFormData({ ...formData, q7_3: 5 }) }
                                    type="checkbox"
                                />
                                <p>其他</p>
                                <input
                                    onChange={ (e) => setFormData({ ...formData, q7_3_msg: e.target.value }) }
                                    value={ formData.q7_3_msg }
                                />
                            </div>
                        </Row>
                        <p className="sc1qr-label">目前是否領有政府照顧輔助費用</p>
                        <Row>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q7_4 }
                                    onClick={ () => setFormData({ ...formData, q7_4: true }) }
                                    type="checkbox"
                                />
                                <p>否</p>
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q7_4 === false }
                                    onClick={ () => setFormData({ ...formData, q7_4: false }) }
                                    type="checkbox"
                                />
                                <p>是</p>
                                <input
                                    onChange={ (e) => setFormData({ ...formData, q7_4_msg: e.target.value }) }
                                    value={ formData.q7_4_msg }
                                />
                            </div>
                        </Row>
                    </td>
                </tr>
                <tr>
                    <td className='header_t' colSpan={ 6 }>八、可能需要之服務種類</td>
                </tr>
                <tr>
                    <td colSpan={ 6 }>
                        <Row>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q8_1 }
                                    onClick={ () => setFormData({ ...formData, q8_1: !formData.q8_1 }) }
                                    type="checkbox"
                                />
                                <p>居家服務</p>
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q8_2 }
                                    onClick={ () => setFormData({ ...formData, q8_2: !formData.q8_2 }) }
                                    type="checkbox"
                                />
                                <p>居家護理</p>
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q8_3 }
                                    onClick={ () => setFormData({ ...formData, q8_3: !formData.q8_3 }) }
                                    type="checkbox"
                                />
                                <p>居家復健</p>
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q8_4 }
                                    onClick={ () => setFormData({ ...formData, q8_4: !formData.q8_4 }) }
                                    type="checkbox"
                                />
                                <p>老人營養送餐</p>
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q8_5 }
                                    onClick={ () => setFormData({ ...formData, q8_5: !formData.q8_5 }) }
                                    type="checkbox"
                                />
                                <p>交通接送</p>
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q8_6 }
                                    onClick={ () => setFormData({ ...formData, q8_6: !formData.q8_6 }) }
                                    type="checkbox"
                                />
                                <p>守護連線</p>
                            </div>
                            <div className="ckbox">
                                <input
                                    checked={ formData.q8_7 }
                                    onClick={ () => setFormData({ ...formData, q8_7: !formData.q8_7 }) }
                                    type="checkbox"
                                />
                                <p>喘息</p>
                            </div>
                        </Row>
                    </td>
                </tr>
            </tbody>
        </table>

        <div
            className="sign-area"
            ref={ (r) => {
                if (r !== null && r !== undefined) setSignCanvasWidth(r.clientWidth);
            } }
            style={ { width: "100%" } }
        >
            <p>受訪者簽名</p>
            <SignatureCanvas
                canvasProps={ { width: signCanvasWidth, height: 200, className: "sigCanvas" } }
                penColor='black'
                ref={ signCanvasRef1 }
            />

            <p>訪視者簽名</p>
            <SignatureCanvas
                canvasProps={ { width: signCanvasWidth, height: 200, className: "sigCanvas" } }
                penColor='black'
                ref={ signCanvasRef2 }
            />
        </div>

        <button className="ghost-button" onClick={ goBack }>取消</button>
        <button className="ghost-button" onClick={ submit }>送出</button>

    </div>;
}
