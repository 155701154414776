export interface ScreeningFormData {
    "e_sign": string,
    "h_sign": string,
    "q0_1": boolean | undefined,
    "q0_2": boolean | undefined,
    "q0_3": boolean | undefined,
    "q0_4": boolean | undefined,
    "q1_name": string,
    "q1_birthday": Date,
    "q1_iden": string,
    "q1_tel": string,
    "q1_clan_1": boolean | undefined,
    "q1_clan_2": string,
    "q1_mobile": string,
    "q1_vil_1": string,
    "q1_county": string,
    "q1_city": string,
    "q1_vil": string,
    "q1_addr": string,
    "q2_name": string,
    "q2_relat": string,
    "q2_iden": string,
    "q2_tel": string,
    "q2_birthday": Date,
    "q2_county": string,
    "q2_city": string,
    "q3_1": number | undefined,
    "q3_2": number | undefined,
    "q3_3": number | undefined,
    "q3_4": number | undefined,
    "q3_5": number | undefined,
    "q3_6": number | undefined,
    "q3_7": number | undefined,
    "q3_8": number | undefined,
    "q3_9": number | undefined,
    "q3_10": number | undefined,
    "q4_1": boolean | undefined,
    "q4_1_msg": string,
    "q4_2": boolean | undefined,
    "q4_3": boolean | undefined,
    "q4_3_msg": string,
    "q4_3_1": boolean | undefined,
    "q4_3_2": boolean | undefined,
    "q4_4_1": boolean | undefined,
    "q4_4_2": boolean | undefined,
    "q4_4_3": boolean | undefined,
    "q4_4_4": boolean | undefined,
    "q4_4_5": boolean | undefined,
    "q4_4_5_msg": string,
    "q5_1": string,
    "q6_1": number | undefined,
    "q6_1_msg": string,
    "q7_1": boolean | undefined,
    "q7_1_msg": string,
    "q7_2_1": boolean | undefined,
    "q7_2_2": boolean | undefined,
    "q7_2_3": boolean | undefined,
    "q7_2_4": boolean | undefined,
    "q7_3": number | undefined,
    "q7_3_msg": string,
    "q7_4": boolean | undefined,
    "q7_4_msg": string,
    "q8_1": boolean | undefined,
    "q8_2": boolean | undefined,
    "q8_3": boolean | undefined,
    "q8_4": boolean | undefined,
    "q8_5": boolean | undefined,
    "q8_6": boolean | undefined,
    "q8_7": boolean | undefined,
}

export interface GetScreeningDataListResponse {
    "datas": {
        "id": string,
        "ev_date": string | null,
        "c_date": string,
        "c_name": string
    }[],
    "is_ok": boolean | undefined,
    "err_title": string | null,
    "err_msg": string | null
}

export interface GetScreeningDataResponse {
    "ev_light": "紅燈" | "綠燈" | "黃燈",
    "q0_1": boolean | undefined,
    "q0_2": boolean | undefined,
    "q0_3": boolean | undefined,
    "q0_4": boolean | undefined,
    "q1_name": string,
    "q1_birthday": string,
    "q1_iden": string,
    "q1_tel": string,
    "q1_clan_1": 0,
    "q1_clan_2": string,
    "q1_mobile": string,
    "q1_vil_1": string,
    "q1_county": string,
    "q1_city": string,
    "q1_vil": string,
    "q1_addr": string,
    "q2_name": string,
    "q2_relat": string,
    "q2_iden": string,
    "q2_tel": string,
    "q2_birthday": string,
    "q2_county": string,
    "q2_city": string,
    "q3_1": number | undefined,
    "q3_2": number | undefined,
    "q3_3": number | undefined,
    "q3_4": number | undefined,
    "q3_5": number | undefined,
    "q3_6": number | undefined,
    "q3_7": number | undefined,
    "q3_8": number | undefined,
    "q3_9": number | undefined,
    "q3_10": number | undefined,
    "q4_1": boolean | undefined,
    "q4_1_msg": string | null,
    "q4_2": boolean | undefined,
    "q4_3": boolean | undefined,
    "q4_3_msg": string | null,
    "q4_3_1": boolean | undefined,
    "q4_3_2": boolean | undefined,
    "q4_4_1": boolean | undefined,
    "q4_4_2": boolean | undefined,
    "q4_4_3": boolean | undefined,
    "q4_4_4": boolean | undefined,
    "q4_4_5": boolean | undefined,
    "q4_4_5_msg": string | null,
    "q5_1": string,
    "q6_1": number | undefined,
    "q6_1_msg": number | undefined,
    "q7_1": boolean | undefined,
    "q7_1_msg": string | null,
    "q7_2_1": boolean | undefined,
    "q7_2_2": boolean | undefined,
    "q7_2_3": boolean | undefined,
    "q7_2_4": boolean | undefined,
    "q7_3": number | undefined,
    "q7_3_msg": string | null,
    "q7_4": boolean | undefined,
    "q7_4_msg": string | null,
    "q8_1": boolean | undefined,
    "q8_2": boolean | undefined,
    "q8_3": boolean | undefined,
    "q8_4": boolean | undefined,
    "q8_5": boolean | undefined,
    "q8_6": boolean | undefined,
    "q8_7": boolean | undefined,
    "is_ok": boolean | undefined,
    "err_title": string | null,
    "err_msg": string | null
}

export const defaultScreeningFormData: ScreeningFormData = {
    e_sign: "",
    h_sign: "",
    q0_1: false,
    q0_2: false,
    q0_3: false,
    q0_4: false,
    q1_addr: "",
    q1_birthday: new Date(),
    q1_clan_1: undefined,
    q1_clan_2: "",
    q1_county: "10015",
    q1_city: "1001506",
    q1_vil: "10015060007",
    q1_vil_1: "10015060007",
    q1_iden: "",
    q1_mobile: "",
    q1_name: "",
    q1_tel: "",
    q2_county: "10015",
    q2_city: "1001506",
    q2_birthday: new Date(),
    q2_iden: "",
    q2_name: "",
    q2_relat: "01",
    q2_tel: "",
    q3_1: undefined,
    q3_10: undefined,
    q3_2: undefined,
    q3_3: undefined,
    q3_4: undefined,
    q3_5: undefined,
    q3_6: undefined,
    q3_7: undefined,
    q3_8: undefined,
    q3_9: undefined,
    q4_1: undefined,
    q4_1_msg: "",
    q4_2: undefined,
    q4_3: undefined,
    q4_3_1: false,
    q4_3_2: false,
    q4_3_msg: "",
    q4_4_1: false,
    q4_4_2: false,
    q4_4_3: false,
    q4_4_4: false,
    q4_4_5: false,
    q4_4_5_msg: "",
    q5_1: "",
    q6_1: undefined,
    q6_1_msg: "",
    q7_1: undefined,
    q7_1_msg: "",
    q7_2_1: undefined,
    q7_2_2: undefined,
    q7_2_3: undefined,
    q7_2_4: undefined,
    q7_3: undefined,
    q7_3_msg: "",
    q7_4: undefined,
    q7_4_msg: "",
    q8_1: false,
    q8_2: false,
    q8_3: false,
    q8_4: false,
    q8_5: false,
    q8_6: false,
    q8_7: false
};
