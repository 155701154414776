export interface GDSFormData {
    "q1": boolean | undefined,
    "q2": boolean | undefined,
    "q3": boolean | undefined,
    "q4": boolean | undefined,
    "q5": boolean | undefined,
    "q6": boolean | undefined,
    "q7": boolean | undefined,
    "q8": boolean | undefined,
    "q9": boolean | undefined,
    "q10": boolean | undefined,
    "q11": boolean | undefined,
    "q12": boolean | undefined,
    "q13": boolean | undefined,
    "q14": boolean | undefined,
    "q15": boolean | undefined,
}

export interface GetGDSDataResponse {
    "ev_light": "紅燈" | "綠燈" | "黃燈",
    "ev_date": string,
    "c_name": string,
    "q1": boolean | undefined,
    "q2": boolean | undefined,
    "q3": boolean | undefined,
    "q4": boolean | undefined,
    "q5": boolean | undefined,
    "q6": boolean | undefined,
    "q7": boolean | undefined,
    "q8": boolean | undefined,
    "q9": boolean | undefined,
    "q10": boolean | undefined,
    "q11": boolean | undefined,
    "q12": boolean | undefined,
    "q13": boolean | undefined,
    "q14": boolean | undefined,
    "q15": boolean | undefined,
    "fraction": number,
    "description": string,
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}

export interface GetGDSDataListResponse {
    "datas": {
        "id": string,
        "ev_date": string,
        "ev_light": "紅燈" | "綠燈" | "黃燈",
        "c_date": string,
        "c_name": string,
        "ev_result": 10
    }[],
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}

export const defaultGDSFormData: GDSFormData = {
    q1: undefined,
    q10: undefined,
    q11: undefined,
    q12: undefined,
    q13: undefined,
    q14: undefined,
    q15: undefined,
    q2: undefined,
    q3: undefined,
    q4: undefined,
    q5: undefined,
    q6: undefined,
    q7: undefined,
    q8: undefined,
    q9: undefined,
};
