import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "../../styles/ElderEditPage.sass";
import useAPI from "../../hooks/useAPI";
import "../../styles/AddContactModal.sass";
import TitleBar from "../../components/TitleBar";
import ElderForm from "../../components/ElderForm";
import ElderFormContent, { emptyElderFormContent } from "../../models/form/ElderFormContent";

const ElderCreatePage = () => {
    const API = useAPI();
    const history = useHistory();

    const [formData, setFormData] = useState<ElderFormContent>(emptyElderFormContent);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    async function uploadData() {
        try {
            if (formData.is_chg === undefined) {
                alert("請選擇是否轉介");
                return;
            }

            const req = { ...formData, e_nhi_card: formData.nhi1 + formData.nhi2 + formData.nhi3 };
            await API.updateElderData(req);
            alert("新增成功！");
            history.push("/elder/" + formData.h_id);
        } catch (err) {
            alert(err);
        }
    }

    if (formData === undefined) return <div className="elder-edit"><h1>新增與編輯</h1><p>載入資料中 ...</p></div>;

    return <div className="elder-create elder-edit s-content">
        <TitleBar title="新增長者" />

        <ElderForm formData={ formData } isLoading={ isLoading } setFormData={ setFormData } />

        <div style={ { textAlign: "center" } }>
            <button className="ghost-button" onClick={ () => history.push("/elder") }>取消</button>
            <button className="ghost-button" onClick={ uploadData }>儲存</button>
        </div>

    </div>;
};

export default ElderCreatePage;
