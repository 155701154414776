import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import TitleBar from "../../components/TitleBar";
import { defaultGDSFormData, GDSFormData } from "../../models/form/GDSForm";
import useAPI from "../../hooks/useAPI";
import useErrorHandler from "../../hooks/useErrorHandler";

/** 憂鬱篩檢問卷頁面 */
export default function GDSFormPage() {
    const API = useAPI();
    const errorHandler = useErrorHandler();
    const { id } = useParams<{ id: string }>();
    const isCreating = id === "create";
    const elderId = window.location.search.split("elderId=")[1];

    const [formData, setFormData] = useState<GDSFormData>(defaultGDSFormData);

    function goBack() {
        window.location.href = "/elder/" + elderId;
    }

    async function submit() {
        try {
            if (isCreating) {
                const res = await API.createGDSReport(formData, elderId);
                alert("測定結果：" + res.fraction + "，" + res.description);
                goBack();
            } else {
                const res = await API.updateGDSReport(formData, id);
                alert("測定結果：" + res.fraction + "，" + res.description);
                goBack();
            }
        } catch (err) {
            errorHandler.handle(err);
        }
    }

    useEffect(() => {
        if (!isCreating) {
            try {
                API.getGDSData(id).then((res) => setFormData({ ...res }));
            } catch (err) {
                errorHandler.handle(err);
            }
        }
    }, [id]);

    return <div className="elder-detail elder-report-ct report-GDS">
        <TitleBar title={ isCreating ? "新增憂鬱篩檢" : "憂鬱篩檢" } isForm />
        <table>
            <tr>
                <td className='header' colSpan={ 4 }>花蓮縣 長者憂鬱症篩檢調查問卷 (GDS-15)</td>
            </tr>
            <tr>
                <td colSpan={ 2 }>題目</td>
                <td>是</td>
                <td>否</td>
            </tr>
            <tr>
                <td width="10%">1</td>
                <td className="sc1qr-label" width="70%">您對目前身活滿意嗎?</td>
                <td width="10%" className="ckbox">
                    <input checked={ formData.q1 === true } onClick={ () => setFormData({ ...formData, q1: true }) } type="checkbox" />
                </td>
                <td className="ckbox" width="10%">
                    <input
                        checked={ formData.q1 === false }
                        onClick={ () => setFormData({ ...formData, q1: false }) }
                        type="checkbox"
                    />
                </td>
            </tr>
            <tr>
                <td>2</td>
                <td className="sc1qr-label">您對日常生活或活動是否已不感興趣?</td>
                <td  className="ckbox" width="10%">
                    <input checked={ formData.q2 === true } onClick={ () => setFormData({ ...formData, q2: true }) } type="checkbox" />
                </td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q2 === false }
                        onClick={ () => setFormData({ ...formData, q2: false }) }
                        type="checkbox"
                    />
                </td>
            </tr>
            <tr>
                <td>3</td>
                <td className="sc1qr-label">您是否覺得生活空虛?</td>
                <td  className="ckbox" width="10%">
                    <input checked={ formData.q3 === true } onClick={ () => setFormData({ ...formData, q3: true }) } type="checkbox" />
                </td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q3 === false }
                        onClick={ () => setFormData({ ...formData, q3: false }) }
                        type="checkbox"
                    />
                </td>
            </tr>
            <tr>
                <td>4</td>
                <td className="sc1qr-label">您是否常感到煩悶?</td>
                <td  className="ckbox" width="10%">
                    <input checked={ formData.q4 === true } onClick={ () => setFormData({ ...formData, q4: true }) } type="checkbox" />
                </td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q4 === false }
                        onClick={ () => setFormData({ ...formData, q4: false }) }
                        type="checkbox"
                    />
                </td>
            </tr>
            <tr>
                <td>5</td>
                <td className="sc1qr-label">您大部分的時間都覺得精神很好?</td>
                <td  className="ckbox" width="10%">
                    <input checked={ formData.q5 === true } onClick={ () => setFormData({ ...formData, q5: true }) } type="checkbox" />
                </td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q5 === false }
                        onClick={ () => setFormData({ ...formData, q5: false }) }
                        type="checkbox"
                    />
                </td>
            </tr>
            <tr>
                <td>6</td>
                <td className="sc1qr-label">您是否會害怕不好的事情會發生在您身上?</td>
                <td  className="ckbox" width="10%">
                    <input checked={ formData.q6 === true } onClick={ () => setFormData({ ...formData, q6: true }) } type="checkbox" />
                </td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q6 === false }
                        onClick={ () => setFormData({ ...formData, q6: false }) }
                        type="checkbox"
                    />
                </td>
            </tr>
            <tr>
                <td>7</td>
                <td className="sc1qr-label">您大部分的時間都覺得很快樂?</td>
                <td  className="ckbox" width="10%">
                    <input checked={ formData.q7 === true } onClick={ () => setFormData({ ...formData, q7: true }) } type="checkbox" />
                </td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q7 === false }
                        onClick={ () => setFormData({ ...formData, q7: false }) }
                        type="checkbox"
                    />
                </td>
            </tr>
            <tr>
                <td>8</td>
                <td className="sc1qr-label">您是否經常會感覺到很無助?</td>
                <td  className="ckbox" width="10%">
                    <input checked={ formData.q8 === true } onClick={ () => setFormData({ ...formData, q8: true }) } type="checkbox" />
                </td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q8 === false }
                        onClick={ () => setFormData({ ...formData, q8: false }) }
                        type="checkbox"
                    />
                </td>
            </tr>
            <tr>
                <td>9</td>
                <td className="sc1qr-label">您是否比較喜歡待在家裏面而不願外出嘗試一些新的事物?</td>
                <td  className="ckbox" width="10%">
                    <input checked={ formData.q9 === true } onClick={ () => setFormData({ ...formData, q9: true }) } type="checkbox" />
                </td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q9 === false }
                        onClick={ () => setFormData({ ...formData, q9: false }) }
                        type="checkbox"
                    />
                </td>
            </tr>
            <tr>
                <td>10</td>
                <td className="sc1qr-label">您是否覺得記性比別人差?</td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q10 === true }
                        onClick={ () => setFormData({ ...formData, q10: true }) }
                        type="checkbox"
                    />
                </td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q10 === false }
                        onClick={ () => setFormData({ ...formData, q10: false }) }
                        type="checkbox"
                    />
                </td>
            </tr>
            <tr>
                <td>11</td>
                <td className="sc1qr-label">您是否認為活著是一件美好的事?</td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q11 === true }
                        onClick={ () => setFormData({ ...formData, q11: true }) }
                        type="checkbox"
                    />
                </td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q11 === false }
                        onClick={ () => setFormData({ ...formData, q11: false }) }
                        type="checkbox"
                    />
                </td>
            </tr>
            <tr>
                <td>12</td>
                <td className="sc1qr-label">您是否覺得自己很沒有價值?</td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q12 === true }
                        onClick={ () => setFormData({ ...formData, q12: true }) }
                        type="checkbox"
                    />
                </td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q12 === false }
                        onClick={ () => setFormData({ ...formData, q12: false }) }
                        type="checkbox"
                    />
                </td>
            </tr>
            <tr>
                <td>13</td>
                <td className="sc1qr-label">您是否覺得自己充滿活力?</td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q13 === true }
                        onClick={ () => setFormData({ ...formData, q13: true }) }
                        type="checkbox"
                    />
                </td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q13 === false }
                        onClick={ () => setFormData({ ...formData, q13: false }) }
                        type="checkbox"
                    />
                </td>
            </tr>
            <tr>
                <td>14</td>
                <td className="sc1qr-label">您是否覺得自己沒有希望?</td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q14 === true }
                        onClick={ () => setFormData({ ...formData, q14: true }) }
                        type="checkbox"
                    />
                </td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q14 === false }
                        onClick={ () => setFormData({ ...formData, q14: false }) }
                        type="checkbox"
                    />
                </td>
            </tr>
            <tr>
                <td>15</td>
                <td className="sc1qr-label">您是否覺得大部分的人都過得比你好?</td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q15 === true }
                        onClick={ () => setFormData({ ...formData, q15: true }) }
                        type="checkbox"
                    />
                </td>
                <td  className="ckbox" width="10%">
                    <input
                        checked={ formData.q15 === false }
                        onClick={ () => setFormData({ ...formData, q15: false }) }
                        type="checkbox"
                    />
                </td>
            </tr>
        </table>

        <div className="rp-faq-box">
            <div className="elder-sTitle">
                <b>計分&轉寄</b>
            </div>
            <p>計分方式 : 1至15題之總分</p>
            <ul>
                <li>◆得分0~4分 : 身心適應狀況良好</li>
                <li>◆得分5~9分 : 有輕微憂鬱傾向，建議尋求心理諮商或接受專業諮詢。</li>
                <li>◆得分10~15分 : 有較嚴重憂鬱傾向，建議轉介身心(精神)科治療或接受專業輔導。</li>
            </ul>
            <b>花蓮縣社區心理衛生中心 : (03)8351885。衛生福利部安心專線 : 1925(免費)</b>
        </div>

        <button className="ghost-button" onClick={ goBack }>取消</button>
        <button className="ghost-button" onClick={ submit }>送出</button>
    </div>;
}
