import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import "../../styles/ElderDetailPage.sass";
import {
    GetAppDatasResponse,
    GetCallVisitDatasResponse,
    GetEightFacetDataListResponse,
    GetElderResponse,
    GetHomeVisitDatasResponse,
} from "../../models/Responses";
import useAPI from "../../hooks/useAPI";
import useErrorHandler from "../../hooks/useErrorHandler";
import Bar from "../../components/Bar";
import { Col, Row, Table } from "react-bootstrap";
import GPModal from "../../components/modals/GPModal";
import ReportModal from "../../components/modals/ReportModal";
import GPDataType from "../../models/GPDataType";
import ReportDataType from "../../models/ReportDataType";
import EightModal from "../../components/modals/EightModal";
import { guardName } from "../../utils/GuardName";
import TitleBar from "../../components/TitleBar";
import { GetGDSDataListResponse } from "../../models/form/GDSForm";
import { GetSOFDataListResponse } from "../../models/form/SOFForm";
import { GetScreeningDataListResponse } from "../../models/form/ScreeningForm";

export default function ElderDetailPage() {
    const API = useAPI();
    const history = useHistory();
    const { elderID } = useParams<{ elderID: string }>();
    const errorHandler = useErrorHandler();

    const [elderData, setElderData] = useState<GetElderResponse | undefined>();

    const [GDSListData, setGDSListData] = useState<GetGDSDataListResponse | undefined>();
    const [SOFListData, setSOFListData] = useState<GetSOFDataListResponse | undefined>();
    const [screeningListData, setScreeningListData] = useState<GetScreeningDataListResponse | undefined>();

    const [GPData, setGPData] = useState<GetAppDatasResponse | undefined>();

    const [homeVisitData, setHomeVisitData] = useState<GetHomeVisitDatasResponse | undefined>();
    const [callVisitData, setCallVisitData] = useState<GetCallVisitDatasResponse | undefined>();

    const [watchingReportDataType, setWatchingReportDataType] = useState<ReportDataType | undefined>();
    const [watchingGPDataType, setWatchingGPDataType] = useState<GPDataType | undefined>();

    useEffect(() => {
        API.getElder(elderID).then((res) => setElderData(res)).catch(errorHandler.handle);

        API.getGDSDataList(elderID).then((res) => setGDSListData(res)).catch(errorHandler.handle);
        API.getSOFDataList(elderID).then((res) => setSOFListData(res)).catch(errorHandler.handle);
        API.getScreeningList(elderID).then((res) => setScreeningListData(res)).catch(errorHandler.handle);

        API.getAppDatas(elderID).then((res) => setGPData(res)).catch(errorHandler.handle);


        API.getHomeVisitList(elderID).then((res) => setHomeVisitData(res)).catch(errorHandler.handle);
        API.getCallVisitList(elderID).then((res) => setCallVisitData(res)).catch(errorHandler.handle);
    }, []);

    return <div className="elder-detail">
        <TitleBar title="長者管理" />
        {elderData === undefined ? <p>載入資料中</p> : <>

            <div className="sc-profile">

                <Row className="r1" style={ { marginBottom: 36 } }>
                    <Col xs={ 3 }><img alt="" src={ elderData.e_img ? elderData.e_img : "/assets/man.svg" } /></Col>
                    <Col className="elder-info" xs={ 9 }>
                        <Row className="elder-name" noGutters>
                            <img alt="" src={ elderData.e_sex === 0 ? "/assets/female.svg" : "/assets/male.svg" } />
                            <h2>{elderData.e_name}</h2>
                            <button
                                className="ghost-button"
                                onClick={ () => history.push("/elder/" + elderID + "/edit") }
                            >
                                進入管理
                            </button>
                        </Row >
                        <Row noGutters >
                            <Col className="sc1qr-label" xs={ 3 }><p>聯絡電話：</p></Col>
                            <Col className="sc1qr-ct" xs={ 3 }><p >{elderData.e_tel}</p></Col>
                            <Col className="sc1qr-label" xs={ 3 }><p>守護圈：</p></Col>
                            <Col className="sc1qr-ct" xs={ 3 }><p>{guardName(elderData.guard)}</p></Col>
                            <Col className="sc1qr-label" xs={ 3 }><p>手機號碼：</p></Col>
                            <Col className="sc1qr-ct" xs={ 3 }><p>{elderData.e_mobile}</p></Col>
                            <Col className="sc1qr-label" xs={ 3 }><p>身分證字號：</p></Col>
                            <Col className="sc1qr-ct" xs={ 3 }><p>{elderData.e_iden}</p></Col>
                            <Col className="sc1qr-label" xs={ 3 }><p>手環號碼：</p></Col>
                            <Col className="sc1qr-ct" xs={ 9 }><p>{elderData.imei}</p></Col>
                            <Col className="sc1qr-label" xs={ 3 }><p>居住地址：</p></Col>
                            <Col className="sc1qr-ct" xs={ 9 }>
                                <p>{elderData.h_country_name + " " +
                                elderData.h_city_name + " " + elderData.h_vil_name + " " + elderData.h_addr}</p>
                            </Col>
                            <Col className="sc1qr-label" xs={ 3 }><p>健保卡卡號：</p></Col>
                            <Col className="sc1qr-ct" xs={ 9 }><p>{elderData.e_nhi_card}</p></Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            <div className="row-card sc-box-report">
                <div className="title">
                    <p>量表記錄</p>
                </div>
                <Row className="card-container">
                    <Col className="sc-box-Report-col-GDS" xs={ 4 }>
                        <button
                            className="ghost-button"
                            onClick={ () => setWatchingReportDataType(ReportDataType.GDS) }
                        >憂鬱篩檢
                        </button>

                        <Row noGutters>
                            <Col xs={ 2 } />
                            <Col xs={ 3 }><p style={ { margin: 0, textAlign: "right", opacity: 0.6, fontSize: 14 } }>檢測日期</p>
                            </Col>
                            <Col xs={ 3 }><p style={ { margin: 0, textAlign: "right", opacity: 0.6, fontSize: 14 } }>建立者</p>
                            </Col>
                        </Row>

                        {GDSListData?.datas.slice(0, 3).map((g, i) =>
                            <Row key={ i } noGutters>
                                <Col xs={ 2 }>
                                    <Bar color={ g.ev_light } />
                                </Col>
                                <Col xs={ 3 }>
                                    <p style={ { margin: 0, textAlign: "right" } }>{g.ev_date}</p>
                                </Col>
                                <Col xs={ 3 }>
                                    <p style={ { margin: 0, textAlign: "right" } }>{g.c_name}</p>
                                </Col>
                            </Row>,
                        )}
                    </Col>

                    <Col className="sc-box-Report-col-SOF" xs={ 4 }>
                        <button
                            className="ghost-button"
                            onClick={ () => setWatchingReportDataType(ReportDataType.SOF) }
                        >衰弱評估
                        </button>
                        <Row noGutters>
                            <Col xs={ 2 }>
                            </Col>
                            <Col xs={ 3 }>
                                <p style={ { margin: 0, textAlign: "right", opacity: 0.6, fontSize: 14 } }>檢測日期</p>
                            </Col>
                            <Col xs={ 3 }><p style={ { margin: 0, textAlign: "right", opacity: 0.6, fontSize: 14 } }>建立者</p>
                            </Col>
                        </Row>
                        {SOFListData?.datas.slice(0, 3).map((g, i) =>
                            <Row key={ i } noGutters>
                                <Col xs={ 2 }>
                                    <Bar color={ g.ev_light } />
                                </Col>
                                <Col xs={ 3 }>
                                    <p style={ { margin: 0, textAlign: "right" } }>{g.ev_date}</p>
                                </Col>
                                <Col xs={ 3 }>
                                    <p style={ { margin: 0, textAlign: "right" } }>{g.c_name}</p>
                                </Col>
                            </Row>,
                        )}
                    </Col>

                    <Col className="sc-box-Report-SCR" xs={ 4 }>
                        <button
                            className="ghost-button"
                            onClick={ () => setWatchingReportDataType(ReportDataType.NEED) }
                        >長照需求初篩
                        </button>
                        <Row noGutters>
                            <Col xs={ 3 }>
                                <p style={ { margin: 0, textAlign: "right", opacity: 0.6, fontSize: 14 } }>檢測日期</p>
                            </Col>
                            <Col xs={ 3 }><p style={ { margin: 0, textAlign: "right", opacity: 0.6, fontSize: 14 } }>建立者</p>
                            </Col>
                        </Row>
                        {screeningListData?.datas.slice(0, 3).map((g, i) =>
                            <Row key={ i } noGutters>
                                <Col xs={ 3 }>
                                    <p style={ { margin: 0, textAlign: "right" } }>{g.c_date}</p>
                                </Col>
                                <Col xs={ 3 }>
                                    <p style={ { margin: 0, textAlign: "right" } }>{g.c_name}</p>
                                </Col>
                            </Row>,
                        )}
                    </Col>
                </Row>
            </div>

            <div className="row-card sc-box-gp">
                <div className="title">
                    <p>生理量測</p>
                </div>

                <Row className="card-container r2">
                    <Col className="sc-box-GP-col-SPDT">
                        <button className="ghost-button" onClick={ () => setWatchingGPDataType(GPDataType.SPDP) }>手部壓力
                        </button>
                        {GPData === undefined ? <p>資料讀取中</p> : <>{
                            GPData.datas.map((d, i) =>
                                <div key={ i }>
                                    <p className="date">{d.spdp_date}</p>
                                    <p>
                                        <span>高壓</span>{d.spdp_date.split("/")[0]} |
                                        <span>低壓</span>{d.spdp.split("/")[1]}
                                    </p>
                                </div>,
                            )}</>}
                    </Col>

                    <Col className="sc-box-GP-col-HEART">
                        <button className="ghost-button" onClick={ () => setWatchingGPDataType(GPDataType.HEART) }>心率
                        </button>
                        {GPData == undefined ? <p>資料讀取中</p> : <>{
                            GPData.datas.map((d, i) =>
                                <div key={ i }>
                                    <p className="date">{d.heart_date}</p>
                                    <p>{d.heart} 次/分</p>
                                </div>,
                            )}</>}
                    </Col>

                    <Col className="sc-box-GP-col-BODY">
                        <button className="ghost-button" onClick={ () => setWatchingGPDataType(GPDataType.BODY) }>體表溫度
                        </button>
                        {GPData === undefined ? <p>資料讀取中</p> : <>{
                            GPData.datas.map((d, i) =>
                                <div key={ i }>
                                    <p className="date">{d.body_date}</p>
                                    <p>{d.body} °C</p>
                                </div>,
                            )}</>}
                    </Col>

                    <Col className="sc-box-GP-col-STEP">
                        <button className="ghost-button" onClick={ () => setWatchingGPDataType(GPDataType.STEP) }>計步
                        </button>
                        {GPData === undefined ? <p>資料讀取中</p> : <>{
                            GPData.datas.map((d, i) =>
                                <div key={ i }>
                                    <p className="date">{d.step_date}</p>
                                    <p>{d.step} 總步數</p>
                                </div>,
                            )}</>}
                    </Col>

                    <Col className="sc-box-GP-col-SLEEP">
                        <button className="ghost-button" onClick={ () => setWatchingGPDataType(GPDataType.SLEEP) }>睡眠
                        </button>
                        {GPData === undefined ? <p>資料讀取中</p> : <>{
                            GPData.datas.map((d, i) =>
                                <div key={ i }>
                                    <p className="date">{d.sleep_date}</p>
                                    <p>{d.sleep}</p>
                                </div>,
                            )}</>}
                    </Col>
                </Row>
            </div>

            <div className="row-card eight sc-box-eight">
                <div className="title">
                    <p>八大面向</p>
                </div>
                <Row className="card-container">
                    <EightFacetList elderId={ elderID } />
                </Row>
            </div>

            <div className="row-card sc-box-call">
                <div className="title">
                    <p>家電訪記錄</p>
                </div>
                <Row className="card-container visit-data">
                    <Col className="sc-box-Call-col-RECORD" lg={ { span: 5 } } xs={ { span: 6 } }>
                        <Row>
                            <button
                                className="ghost-button"
                                onClick={ () => setWatchingReportDataType(ReportDataType.CALL) }
                            >電訪記錄
                            </button>
                            <img
                                alt=""
                                onClick={ () => {
                                    window.location.href = "/report/Call/create?elderId=" + elderID;
                                } }
                                src={ "/assets/add.svg" }
                            />
                        </Row>
                        {callVisitData === undefined ? <p>資料讀取中</p> : <>{
                            callVisitData.datas.slice(0, 3).map((d) =>
                                <Row key={ d.id }>
                                    <Col xs={ 4 }>{d.call_date}</Col>
                                    <Col xs={ 4 }>{d.c_date}</Col>
                                    <Col xs={ 4 }>{d.c_name}</Col>
                                </Row>,
                            )}</>}
                        <Row className="col-name">
                            <Col xs={ 4 }><p>電訪日期</p></Col>
                            <Col xs={ 4 }><p>建立日期</p></Col>
                            <Col xs={ 4 }><p>人員</p></Col>
                        </Row>
                    </Col>
                    <Col className="sc-box-Call-col-LIST" lg={ { offset: 1, span: 5 } } xs={ { span: 6 } }>
                        <Row>
                            <button
                                className="ghost-button"
                                onClick={ () => setWatchingReportDataType(ReportDataType.HOME) }
                            >家訪記錄
                            </button>
                            <img
                                alt=""
                                onClick={ () => {
                                    window.location.href = "/report/Home/create?elderId=" + elderID;
                                } }
                                src={ "/assets/add.svg" }
                            />
                        </Row>
                        {homeVisitData === undefined ? <p>資料讀取中</p> : <>{
                            homeVisitData.datas.slice(0, 3).map((d) =>
                                <Row key={ d.id }>
                                    <Col xs={ 4 }>{d.home_date}</Col>
                                    <Col xs={ 4 }>{d.c_date}</Col>
                                    <Col xs={ 4 }>{d.c_name}</Col>
                                </Row>,
                            )}</>}
                        <Row className="col-name">
                            <Col xs={ 4 }><p>家訪日期</p></Col>
                            <Col xs={ 4 }><p>建立日期</p></Col>
                            <Col xs={ 4 }><p>人員</p></Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>}

        <GPModal
            dataType={ watchingGPDataType }
            elderID={ elderID }
            onClose={ () => setWatchingGPDataType(undefined) }
        />

        <ReportModal
            dataType={ watchingReportDataType }
            elderID={ elderID }
            onClose={ () => setWatchingReportDataType(undefined) }
        />

    </div>;
}

const EightFacetList = (props: { elderId: string }) => {
    const API = useAPI();
    const errorHandler = useErrorHandler();

    const { elderId } = props;
    const [limit, setLimit] = useState(3);
    const [eightFacetList, setEightFacetList] = useState<GetEightFacetDataListResponse | undefined>();

    const [watchingEightId, setWatchingEightId] = useState<string | undefined>();

    useEffect(() => {
        API.getEightFacetList(elderId).then((res) => setEightFacetList(res)).catch(errorHandler.handle);
    }, []);

    function handleIncreaseLimit() {
        if (!eightFacetList) return;

        if (limit === eightFacetList.datas.length) {
            alert("已顯示全部資料");
            return;
        }

        if (limit + 3 <= eightFacetList.datas.length) setLimit(limit + 3);

        setLimit(eightFacetList.datas.length);
    }

    return <>
        {eightFacetList === undefined ? <p>載入資料中</p> : <>
            <Row className="table-container" style={ { width: "100%" } }>
                <Table>
                    <thead style={ { textAlign: "center" } }>
                        <tr>
                            <th><p>檢測日期</p></th>
                            <th><p>建立日期</p></th>
                            <th><p>建立人</p></th>
                            <th />
                        </tr>
                    </thead>
                    <tbody style={ { textAlign: "center" } }>
                        {eightFacetList.datas.slice(0, limit).map((d) =>
                            <tr key={ d.id }>
                                <th>{d.ev_date}</th>
                                <th>{d.c_date}</th>
                                <th>{d.c_name}</th>
                                <th>
                                    <button className="ghost-button" onClick={ () => setWatchingEightId(d.id) }>檢視</button>
                                </th>
                            </tr>)
                        }
                    </tbody>
                </Table>
                <div style={ { display: "flex", justifyContent: "center", width: "100%" } }>
                    <button className="ghost-button" onClick={ handleIncreaseLimit }>查看更多
                    </button>
                </div>
            </Row></>}

        <EightModal
            eightId={ watchingEightId }
            onClose={ () => setWatchingEightId(undefined) }
        />
    </>;
};
