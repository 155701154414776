export interface SOFFormData {
    "q1": boolean | undefined,
    "q2": boolean | undefined,
    "q3": boolean | undefined,
}

export const defaultSOFFormData: SOFFormData = { q1: undefined, q2: undefined, q3: undefined };

export interface GetSOFDataListResponse {
    "datas": {
        "id": string,
        "ev_light": "紅燈" | "綠燈" | "黃燈",
        "ev_date": string,
        "c_date": string,
        "c_name": string,
        "ev_result": number
    }[],
    "is_ok": boolean,
    "err_title": string | null,
    "err_msg": string | null
}
