import useAPI from "../hooks/useAPI";
import useErrorHandler from "../hooks/useErrorHandler";
import React, { useEffect, useState } from "react";
import TitleBar from "../components/TitleBar";
import { Col, Row } from "react-bootstrap";
import Bar from "../components/Bar";
import "../styles/TrackList.sass";

export default function TrackListPage() {
    const API = useAPI();
    const errorHandler = useErrorHandler();

    // eslint-disable-next-line max-len
    const [data, setData] = useState<{ e_id: string, e_img: string, e_name: string, e_sex: 0 | 1, e_tel: string, e_mobile: string, home: boolean, change: boolean, change_unit: string }[]>([]);
    const [keyword, setKeyword] = useState("");
    const [sort, setSort] = useState("none");

    const displayElders = () => {
        const filletedData = data.filter((elder) => elder.e_name.includes(keyword));
        switch (sort) {
        case "name":
            return filletedData.sort((a, b) => a.e_name.localeCompare(b.e_name));
        case "gender":
            return filletedData.sort((a, b) => a.e_sex - b.e_sex);
        case "tel":
            return filletedData.sort((a, b) => a.e_tel.localeCompare(b.e_tel));
        case "call":
            return filletedData.sort((a, b) => (a.home ? 1:0) - (b.home ? 1:0));
        case "home":
            return filletedData.sort((a, b) => (a.home ? 1:0) - (b.home ? 1:0));
        case "trans":
            return filletedData.sort((a, b) => (a.change ? 1:0) - (b.change ? 1:0));
        default:
            return filletedData;
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0 });
        API.getTrackList().then((res) => setData((res))).catch(errorHandler.handle);
    }, []);

    return <div className="track-list sc1qr-list-ct">
        <TitleBar title="追蹤清單" />

        <div className="row-search">
            <input onChange={ (e) => setKeyword(e.target.value) } placeholder="查詢姓名" value={ keyword } />
            <select onChange={ (e) => setSort(e.target.value) } value={ sort }>
                <option value="none">無排序</option>
                <option value="name">長者姓名</option>
                <option value="gender">長者性別</option>
                <option value="tel">聯絡電話</option>
                <option value="call">當月電訪</option>
                <option value="home">當月家訪</option>
                <option value="trans">轉介與否</option>
            </select>
        </div>

        <Row style={ { margin: 0 } }>
            {data === undefined ? <p className="info">載入資料中 ...</p> :
                <>{displayElders().length === 0 ? <p className="info">找不到姓名包含 {keyword} 的長者</p> : <>{displayElders().map((elder) =>
                    <Col
                        key={ elder.e_id }
                        md={ 6 }
                        xl={ 4 }
                    >
                        <div className="elder-card sc-Pcard">
                            <Row className="r1" noGutters>
                                <Col className="c1" xs={ 7 }>
                                    <img
                                        alt=""
                                        src={ elder.e_sex === 0 ? "/assets/female.svg" : "/assets/male.svg" }
                                    />
                                    <p>{elder.e_name}</p></Col>
                                <Col className="c2" xs={ 5 }>
                                    <p>{elder.e_tel}</p>
                                </Col>
                            </Row>
                            <Row className="r2" noGutters>
                                <Col className="c1">
                                    <p>當月家訪</p>
                                    <p><Bar color={ elder.home ? "green" : "red" } /></p>
                                </Col>
                                <Col className="c2">
                                    <p>當月電訪</p>
                                    < p><Bar color={ elder.home ? "green" : "red" } /></p>
                                </Col>
                                <Col className="c3">
                                    <p>轉介與否/單位</p>
                                    <Row noGutters>
                                        <Col xs={ 6 }><Bar color={ elder.change ? "green" : "red" } /></Col>
                                        <Col xs={ 6 }>
                                            <p style={ { fontSize: 12 } }>
                                                {elder.change ? elder.change_unit : ""}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>)}</>}</>}
        </Row>
    </div>;
}
