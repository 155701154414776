/* eslint-disable max-len */
import React from "react";

const WeightIcon = (props: { color?: string }) => {
    let styleColor: any = props.color;

    switch (styleColor) {
    case "紅燈":
        styleColor = "#FF0000";
        break;

    case "黃燈":
        styleColor = "#FBB03B";
        break;

    case "綠燈":
        styleColor = "#39B54A";
        break;

    case "red":
        styleColor = "#FF0000";
        break;

    case "yellow":
        styleColor = "#FBB03B";
        break;

    case "green":
        styleColor = "#39B54A";
        break;

    default:
        styleColor = "#888888";
        break;
    }

    return <svg className="icon" fill={ styleColor } xmlns="http://www.w3.org/2000/svg">
        <g>
            <path
                className="cls-1"
                d="M10.9,0h3.48L17,0,33.42,0c3.3,0,6.59,0,9.89,0a7.72,7.72,0,0,1,5.62,2.37A7,7,0,0,1,51,6.75a18.9,18.9,0,0,1,0,2.38c-.07,1.75-.17,3.49-.26,5.23-.07,1.42-.15,2.83-.23,4.25-.09,1.73-.17,3.46-.26,5.2-.11,2.22-.24,4.43-.35,6.65l-.27,5.18-.33,6.27c-.07,1.18-.12,2.36-.2,3.54A8.17,8.17,0,0,1,48,49a8,8,0,0,1-2.49,2.76A7.35,7.35,0,0,1,41.39,53H37.5L34,53,18.22,53c-2.83,0-5.66,0-8.49,0a7.42,7.42,0,0,1-6.55-3.75A8.69,8.69,0,0,1,1.92,44.9c-.09-2-.22-4.06-.33-6.1-.08-1.56-.14-3.11-.22-4.67s-.17-3.07-.25-4.6S1,26.78.91,25.41q-.16-3.06-.32-6.1c-.06-1.13-.1-2.25-.16-3.37C.36,14.57.27,13.21.2,11.84.14,10.69.11,9.54,0,8.39A7.93,7.93,0,0,1,.16,6.06a7.54,7.54,0,0,1,3-4.53A7.65,7.65,0,0,1,7.83,0ZM25.48,2.74H16c-2.68,0-5.36,0-8,0a5.06,5.06,0,0,0-3,.85,4.67,4.67,0,0,0-2.2,3.71,18.29,18.29,0,0,0,.06,2.22c.06,1.3.14,2.6.21,3.9.08,1.51.15,3,.23,4.53.09,1.7.17,3.4.26,5.1.06,1,.11,2.08.16,3.11.11,2.06.22,4.11.32,6.17.08,1.45.15,2.89.22,4.34.09,1.6.18,3.21.26,4.82.06,1.11.11,2.23.16,3.35a6.13,6.13,0,0,0,.81,2.89,4.76,4.76,0,0,0,4.28,2.54h9.65q11.1,0,22.19,0A4.2,4.2,0,0,0,43.09,50a5.26,5.26,0,0,0,3.29-4.59c.16-1.5.17-3,.25-4.53s.17-3,.24-4.53c.11-2,.21-4.08.32-6.12l.27-5c.07-1.41.13-2.83.21-4.24q.21-3.91.41-7.83c.09-1.69.17-3.38.26-5.06a4.73,4.73,0,0,0-1.27-3.65A5,5,0,0,0,43.2,2.74H25.48Z"
            />
            <path
                className="cls-1"
                d="M33.33,35a12.13,12.13,0,0,0-1-4.24c-.47-1.2-1-2.38-1.39-3.59a5.93,5.93,0,0,1-.38-2.89,3.17,3.17,0,0,1,2.67-2.67,5.72,5.72,0,0,1,3.11.42,9.81,9.81,0,0,1,3.77,2.61,7.29,7.29,0,0,1,2,5.13,7,7,0,0,1-.67,2.7c-.55,1.29-1.16,2.56-1.74,3.84a10.07,10.07,0,0,0-.92,3.63c-.09,1.76-.27,3.51-.47,5.26a8.54,8.54,0,0,1-.67,2.58,3.34,3.34,0,0,1-2.61,2,5.53,5.53,0,0,1-4-.93,4.1,4.1,0,0,1-2-3.35,5.37,5.37,0,0,1,.48-2.36,16.13,16.13,0,0,1,1.88-2.94,21.6,21.6,0,0,0,1.35-2.07A5.94,5.94,0,0,0,33.33,35Z"
            />
            <path
                className="cls-1"
                d="M17.05,21.53a3.29,3.29,0,0,1,3.05,1.62,3.59,3.59,0,0,1,.38,2.32,11,11,0,0,1-.82,2.84,38.64,38.64,0,0,0-1.59,4.33,11.57,11.57,0,0,0-.34,3.15A6.93,6.93,0,0,0,19.3,39.7a22.7,22.7,0,0,1,2.05,3A6.27,6.27,0,0,1,22,44.76a4,4,0,0,1-1.45,3.69,5.57,5.57,0,0,1-3.79,1.39A3.57,3.57,0,0,1,13.13,47a24.83,24.83,0,0,1-.68-4.37c-.09-1-.15-2.1-.2-3.16a7.53,7.53,0,0,0-.73-2.75c-.58-1.27-1.18-2.53-1.74-3.81A9.34,9.34,0,0,1,9,30a6.64,6.64,0,0,1,.48-3,8.61,8.61,0,0,1,3.75-4.26,8.25,8.25,0,0,1,3.29-1.22h.58Z"
            />
            <path
                className="cls-1"
                d="M25.52,5.27h4.9a3.5,3.5,0,0,1,2,.47,1.56,1.56,0,0,1,.76,1.6c-.17,1.63-.33,3.26-.48,4.9a2.05,2.05,0,0,1-.79,1.67,2.75,2.75,0,0,1-1.48.51c-.71,0-1.42,0-2.13,0-2.51,0-5,0-7.55,0a2.47,2.47,0,0,1-2-.95,2.27,2.27,0,0,1-.34-1.24c-.17-1.71-.32-3.42-.48-5.14a1.38,1.38,0,0,1,.69-1.31,3.35,3.35,0,0,1,1.93-.51ZM25.44,9.1c-.06-.13-.11-.21-.14-.3-.24-.6-.49-1.19-.72-1.79,0-.16-.13-.2-.29-.2H20.84c-.29,0-.58,0-.86.05-.45,0-.52.16-.47.6l.33,3.44c0,.46.09.93.14,1.39s.21.55.61.59h9.87c.42,0,.58-.19.63-.61l.27-2.64.21-2.28c0-.34-.07-.43-.4-.47a2.58,2.58,0,0,0-.39,0h-4a.26.26,0,0,0-.27.18C26.14,7.7,25.8,8.37,25.44,9.1Z"
            />
            <path
                className="cls-1"
                d="M20.09,18.21A2.61,2.61,0,0,1,19,20.42a1.64,1.64,0,0,1-2-.1,2.72,2.72,0,0,1-.94-1.74,2.89,2.89,0,0,1,.52-2.3,1.75,1.75,0,0,1,3,.13A3,3,0,0,1,20.09,18.21Z"
            />
            <path
                className="cls-1"
                d="M31,18A2.69,2.69,0,0,1,32,16a1.67,1.67,0,0,1,2.51.32,3.05,3.05,0,0,1,.27,3.15,2,2,0,0,1-1,1.11,1.73,1.73,0,0,1-2.22-.71A3.2,3.2,0,0,1,31,18Z"
            />
            <path
                className="cls-1"
                d="M12.85,19.51a2.31,2.31,0,0,1,.39-1.2,1.15,1.15,0,0,1,1.88-.1,2.2,2.2,0,0,1,.12,2.67,1.14,1.14,0,0,1-1.94,0A2.21,2.21,0,0,1,12.85,19.51Z"
            />
            <path
                className="cls-1"
                d="M38.22,19.46a2.24,2.24,0,0,1-.6,1.61,1.13,1.13,0,0,1-1.76-.13,2.23,2.23,0,0,1,.19-2.79,1.11,1.11,0,0,1,1.77.17A2.19,2.19,0,0,1,38.22,19.46Z"
            />
            <path
                className="cls-1"
                d="M40.46,21.38A1.77,1.77,0,0,1,40,22.6a.9.9,0,0,1-1.47-.11,1.76,1.76,0,0,1,0-1.91.92.92,0,0,1,1.65.13A4,4,0,0,1,40.46,21.38Z"
            />
            <path
                className="cls-1"
                d="M10.63,21.64A1.88,1.88,0,0,1,11,20.47a.85.85,0,0,1,1.33-.06,1.72,1.72,0,0,1,.21,2,1,1,0,0,1-.87.51,1,1,0,0,1-.84-.63A3,3,0,0,1,10.63,21.64Z"
            />
            <path
                className="cls-1"
                d="M9,23.43a1.3,1.3,0,0,1,.33-.88.68.68,0,0,1,1,0,1.39,1.39,0,0,1,.09,1.84.66.66,0,0,1-1,.05A1.4,1.4,0,0,1,9,23.43Z"
            />
            <path
                className="cls-1"
                d="M42.11,23.35a1.64,1.64,0,0,1-.37,1,.69.69,0,0,1-1.12-.09,1.53,1.53,0,0,1,0-1.61.72.72,0,0,1,1.31.15A3,3,0,0,1,42.11,23.35Z"
            />
            <path
                className="cls-1"
                d="M8,25.22a.68.68,0,0,1,.46-.73c.27,0,.52.13.7.52a1.16,1.16,0,0,1,0,1,.49.49,0,0,1-.82.15A1.14,1.14,0,0,1,8,25.22Z"
            />
            <path
                className="cls-1"
                d="M41.8,25.41a5,5,0,0,1,.35-.69.48.48,0,0,1,.87.08,1.16,1.16,0,0,1-.35,1.4A.45.45,0,0,1,42,26,4,4,0,0,1,41.8,25.41Z"
            />
            <path
                className="cls-1"
                d="M27.23,12.19l.88-1.85,0,0c.08-.16.15-.42.25-.44a2.69,2.69,0,0,1,.75.13l-.67,1.41c-.13.25-.24.52-.38.76a.3.3,0,0,1-.23.13C27.65,12.27,27.45,12.22,27.23,12.19Z"
            />
            <path
                className="cls-1"
                d="M20.16,10.59c.22-.08.42-.17.63-.23a.21.21,0,0,1,.17.06c.55.65,1.08,1.3,1.65,2-.28.06-.51.13-.76.17a.23.23,0,0,1-.16-.07c-.5-.6-1-1.21-1.49-1.82C20.18,10.67,20.18,10.64,20.16,10.59Z"
            />
            <path
                className="cls-1"
                d="M24.11,12.16a6.77,6.77,0,0,1-.73.08c-.05,0-.13-.06-.15-.12-.37-.71-.73-1.42-1.11-2.18.26,0,.49-.1.72-.13,0,0,.11.08.14.13Z"
            />
            <path className="cls-1" d="M25.43,12.08l-.77.05-.4-2.54L25,9.54Z" />
            <path className="cls-1" d="M26.69,12.13l-.77,0,.42-2.56.78.06Z" />
            <path
                className="cls-1"
                d="M28.7,12.43c.24-.29.44-.56.65-.82l.88-1.08a.21.21,0,0,1,.29-.08c.18.08.37.14.59.22L30,12c-.14.18-.28.36-.43.53a.23.23,0,0,1-.16.08C29.19,12.59,29,12.51,28.7,12.43Z"
            />
        </g>
    </svg>;
};

export default WeightIcon;
